import React, { useState } from 'react';

import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Swal from 'sweetalert2';

import { areYouSure } from 'lib/utils/toast';
import Container from 'components/shared/Container';
import GlobalProductDetailsSection from 'components/product_anomalies/GlobalProductDetailsSection';
import ProductOutliersFeedbackTable from 'components/product_anomalies/QA/ProductOutliersFeedbackTable';
import {
  PRODUCT_OUTLIERS_FEEDBACK,
  SKU_MAPPINGS_DELETE_PRODUCTS,
  SKU_MAPPINGS_UNLINK_PRODUCT_GROUP,
  SKU_MAPPINGS_UNVERIFY_LINKS,
  SKU_MAPPINGS_VERIFY_LINKS,
} from 'lib/networking/endpoints';
import ProductUnlinkButton from 'components/global_products/detail_view/ProductUnlinkButton';
import { ProductVerificationStatus } from 'lib/enums/ProductVerificationStatus';
import { ProductTypeDisplay } from 'lib/enums/ProductType';
import UnlinkProductGroupModal from 'components/product_anomalies/UnlinkProductGroupModal';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function ProductOutliersFeedbackView() {
  const [selectedList, setSelectedList] = useState([]);
  const [showUnlinkProductGroupModal, setShowUnlinkProductGroupModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1;

  const {
    data: { products = [], global_product = {}, count } = {},
    loading,
    refetch,
  } = useGet(PRODUCT_OUTLIERS_FEEDBACK, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
  });

  const { postData: verifyLinks, loading: linksVerifying } = usePost(
    SKU_MAPPINGS_VERIFY_LINKS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully verified',
        title: 'Success',
      });
      setSelectedList([]);
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: unverifyLinks, loading: linksUnverifying } = usePost(
    SKU_MAPPINGS_UNVERIFY_LINKS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully unverified',
        title: 'Success',
      });
      setSelectedList([]);
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: unlinkProductGroup, loading: unlinkingProductGroup } = usePost(
    SKU_MAPPINGS_UNLINK_PRODUCT_GROUP,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully unlinked',
        title: 'Success',
      });
      setSelectedList([]);
      setShowUnlinkProductGroupModal(false);
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: deleteProducts, loading: deletingProducts } = usePost(
    SKU_MAPPINGS_DELETE_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully deleted products',
        title: 'Success',
      });
      setSelectedList([]);
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">Global Product Linkages FeedBack</h3>
      </div>
      <Row className="my-4 d-flex align-items-center">
        <Col className="d-flex justify-content-end mr-3">
          <Button
            className="mr-3"
            variant="danger"
            disabled={!selectedList.length || deletingProducts}
            onClick={() =>
              areYouSure(
                () =>
                  deleteProducts({
                    global_product_id: global_product.id,
                    manufacturer_product_ids: selectedList
                      .filter(product => product.entity_type === ProductTypeDisplay.MANUFACTURER)
                      .map(product => product.id),
                    vendor_product_ids: selectedList
                      .filter(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
                      .map(product => product.id),
                  }),
                'Are you sure you want to delete the selected products?',
              )
            }
          >
            Delete Products
          </Button>
          <Button
            className="mr-3 px-4"
            disabled={!selectedList.length || unlinkingProductGroup}
            variant="outline-danger"
            onClick={() => setShowUnlinkProductGroupModal(true)}
          >
            Create New Group
          </Button>
          <span>
            <ProductUnlinkButton
              globalProductId={global_product.id}
              manufacturerProductIds={selectedList
                .filter(product => product.entity_type === ProductTypeDisplay.MANUFACTURER)
                .map(product => product.id)}
              vendorProductIds={selectedList
                .filter(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
                .map(product => product.id)}
              verificationStatus={ProductVerificationStatus.UNVERIFIED}
              buttonText={'Unlink'}
              refetch={() => {
                refetch();
                setSelectedList([]);
              }}
              disabled={!selectedList.length}
              showProductUnlinkModal={selectedList.length === 1}
              setShowProductUnlinkModal={setShowUnlinkProductGroupModal}
            />
          </span>
          <Button
            className="ml-2 mr-3"
            disabled={!selectedList.length || linksVerifying}
            onClick={() =>
              verifyLinks({
                global_product_id: global_product.id,
                manufacturer_product_ids: selectedList
                  .filter(product => product.entity_type === ProductTypeDisplay.MANUFACTURER)
                  .map(product => product.id),
                vendor_product_ids: selectedList
                  .filter(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
                  .map(product => product.id),
              })
            }
          >
            Verify Links
          </Button>
          <Button
            className="px-3"
            disabled={!selectedList.length || linksUnverifying}
            onClick={() =>
              unverifyLinks({
                global_product_id: global_product.id,
                manufacturer_product_ids: selectedList
                  .filter(product => product.entity_type === ProductTypeDisplay.MANUFACTURER)
                  .map(product => product.id),
                vendor_product_ids: selectedList
                  .filter(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
                  .map(product => product.id),
              })
            }
          >
            Unverify Links
          </Button>
        </Col>
      </Row>
      <GlobalProductDetailsSection globalProduct={global_product} refetch={refetch} />
      <Row className="mt-4">
        <Col>
          <Button
            className={css(styles.button)}
            variant="link"
            onClick={() => setCurrentPage(currentPage - 1)}
            size="lg"
            disabled={loading || currentPage === 1 || count === 0}
          >
            &lt; Previous
          </Button>
          <Button
            className={css(styles.button)}
            variant="link"
            onClick={() => setCurrentPage(currentPage + 1)}
            size="lg"
            disabled={loading || currentPage === count || count === 0}
          >
            Next &gt;
          </Button>
        </Col>
        <Col className="d-flex align-items-center justify-content-end mr-3">
          {loading ? (
            <Spinner animation="border" role="status" size="sm" className="mr-4" />
          ) : (
            <>
              {count ? (
                <>
                  {currentPage}/{count} Products found
                </>
              ) : (
                'No products found'
              )}
            </>
          )}
        </Col>
      </Row>
      <div className={css(styles.scrollableContainer)}>
        <ProductOutliersFeedbackTable
          products={products}
          globalProductId={global_product?.id}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
        />
      </div>
      {showUnlinkProductGroupModal ? (
        <UnlinkProductGroupModal
          globalProductId={global_product.id}
          selectedList={selectedList}
          unlinkProductGroup={unlinkProductGroup}
          unlinkingProductGroup={unlinkingProductGroup}
          onHide={() => {
            setShowUnlinkProductGroupModal(false);
            setSelectedList([]);
          }}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '60vh',
    overflowY: 'scroll',
  },
  button: {
    fontSize: '1em',
    padding: '0.5em 1em',
    fontWeight: 'bold',
  },
  select: {
    zIndex: 2,
  },
});

export default ProductOutliersFeedbackView;
