import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import UPBCategoryCard from 'components/upb_viewer/UPBCategoryCard';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

function UPBCategoryView({ categories, categoryCount, refetch }) {
  const hasUserPermissions = useUserPermissions();

  return (
    <div className={classNames(css(styles.container), 'd-flex flex-wrap justify-content-start')}>
      {categoryCount ? (
        categories.map(cat => (
          <UPBCategoryCard
            key={cat.id}
            category={cat}
            refetch={refetch}
            showEditIcon={hasUserPermissions([UserPermission.UPB_VIEW_ADMIN])}
          />
        ))
      ) : (
        <div className="p-5 text-muted h5">No result</div>
      )}
    </div>
  );
}
const styles = StyleSheet.create({
  container: {
    minHeight: 100,
    maxHeight: 500,
  },
});

UPBCategoryView.propTypes = {
  categories: PropTypes.array,
  categoryCount: PropTypes.number,
  refetch: PropTypes.func,
};

export default UPBCategoryView;
