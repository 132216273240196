import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';

import { BRANDS_ACTIVITY_LOG, GLOBAL_MANUFACTURERS_ACTIVITY_LOG } from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import useGet from 'lib/hooks/useGet';

function ActivityView({ brandId, manufacturerId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);

  const {
    data: { data: brand_changes = [], brand_changes_count } = {},
    loading: brandActivityLoading,
  } = useGet(
    BRANDS_ACTIVITY_LOG,
    {
      brand_id: brandId,
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
    },
    !brandId,
  );

  const {
    data: { data: manufacturer_changes = [], manufacturer_changes_count } = {},
    loading: manufacturerActivityLoading,
  } = useGet(
    GLOBAL_MANUFACTURERS_ACTIVITY_LOG,
    {
      manufacturer_id: manufacturerId,
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
    },
    !manufacturerId,
  );

  const activityLogs = useMemo(() => {
    if (brandId && brand_changes.length) {
      return brand_changes;
    } else if (manufacturerId && manufacturer_changes.length) {
      return manufacturer_changes;
    }
    return [];
  }, [brandId, manufacturerId, brand_changes, manufacturer_changes]);

  const count = useMemo(() => {
    if (brandId && brand_changes_count) {
      return brand_changes_count;
    } else if (manufacturerId && manufacturer_changes_count) {
      return manufacturer_changes_count;
    }
    return 0;
  }, [brandId, manufacturerId, brand_changes_count, manufacturer_changes_count]);

  if (brandActivityLoading || manufacturerActivityLoading) {
    return <LoadingSpinner />;
  }

  if (!activityLogs || activityLogs.length === 0) {
    return <div className="d-flex justify-content-center m-5 text-muted">No activities found</div>;
  }

  const formatMetaData = metaData => {
    return (
      <ul>
        {Object.entries(metaData).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong>
            {typeof value === 'object' &&
            value !== null &&
            value !== undefined &&
            !Array.isArray(value) ? (
              <ul>
                {Object.entries(value).map(([key1, value1]) => (
                  <li key={key1}>
                    <strong>{key1}:</strong>
                    {typeof value1 !== 'object' ? value1 : ` ${JSON.stringify(value1, null, 2)}`}
                  </li>
                ))}
              </ul>
            ) : typeof value === 'boolean' ? (
              value.toString()
            ) : Array.isArray(value) ? (
              value.join(', ')
            ) : (
              value
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Container>
      <div className="d-flex justify-content-end my-3">
        <PaginationComponent
          totalItems={count}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Table bordered striped hover>
        <thead>
          <tr>
            <th>User</th>
            <th>Date</th>
            <th>Change Type</th>
            <th>Meta Data</th>
          </tr>
        </thead>
        <tbody>
          {activityLogs.map(log => {
            return (
              <tr key={log.id}>
                <td className="py-2">{log.user?.name}</td>
                <td className="py-2">{dayjs(log.date_created).format('YYYY-MM-DD HH:mm:ss')}</td>
                <td className="py-2">{log.activity_log_type}</td>
                <td className="py-2">{log.meta_data ? formatMetaData(log.meta_data) : ''}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}

ActivityView.propTypes = {
  brandId: PropTypes.number,
  manufacturerId: PropTypes.number,
};

export default ActivityView;
