import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Swal from 'sweetalert2';

import { backgroundGrey } from 'lib/css/colors';
import Container from 'components/shared/Container';
import { grape } from 'lib/css/colors';
import {
  PRODUCT_OUTLIERS_APPROVE_CHANGES,
  PRODUCT_OUTLIERS_REJECT_CHANGES,
} from 'lib/networking/endpoints';
import ProductOutliersQATableRow from 'components/product_anomalies/QA/ProductOutliersQATableRow';
import ProductOutliersQAFeedbackModal from './ProductOutliersQAFeedbackModal';
import usePost from 'lib/hooks/usePost';

function ProductOutliersQATableView({ global_product, products, refetch, currentPage, count }) {
  const [showFeedBackModalSettings, setShowFeedBackModalSettings] = useState({ show: false });
  const { postData: approveChanges, loading: approvingChanges } = usePost(
    PRODUCT_OUTLIERS_APPROVE_CHANGES,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully approved',
        title: 'Success',
      });
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: rejectChanges, loading: rejectingChanges } = usePost(
    PRODUCT_OUTLIERS_REJECT_CHANGES,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully rejected',
        title: 'Success',
      });
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  return (
    <Container>
      <Row>
        <Col className="d-flex align-items-center justify-content-start mr-3 mb-3">
          {currentPage}/ {count} Products found
        </Col>
        <Col className="d-flex align-items-center justify-content-end mr-3 mb-3">
          <Button
            variant="primary"
            disabled={approvingChanges}
            onClick={() => {
              approveChanges({
                global_product_id: global_product.id,
              });
            }}
          >
            Approve Changes
          </Button>
        </Col>
      </Row>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            <th>Change Type</th>
            <th>Entity Type</th>
            <th>Entity Name</th>
            <th>SKU</th>
            <th>Product Name</th>
            <th>Core 11</th>
            <th>Pack Size</th>
            <th>Brand</th>
            <th>Change Done By</th>
            <th>QA Done By</th>
            <th />
            <th />
            <th>Resolve Changes</th>
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {products?.length ? (
            products?.map((product, index) => (
              <ProductOutliersQATableRow
                product={product}
                key={index}
                rejectingChanges={rejectingChanges}
                setShowFeedBackModalSettings={setShowFeedBackModalSettings}
              />
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No products found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {showFeedBackModalSettings.show && (
        <ProductOutliersQAFeedbackModal
          show={showFeedBackModalSettings.show}
          onHide={() => setShowFeedBackModalSettings({ show: false })}
          product={showFeedBackModalSettings.product}
          refetch={refetch}
        />
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky',
    top: 0,
    backgroundColor: backgroundGrey,
    zIndex: 1,
  },
  tbody: {
    position: 'relative',
  },
  button: {
    color: grape,
  },
});

ProductOutliersQATableView.propTypes = {
  global_product: PropTypes.object,
  products: PropTypes.array,
  refetch: PropTypes.func,
  currentPage: PropTypes.number,
  count: PropTypes.number,
};

export default ProductOutliersQATableView;
