import React from 'react';
import propTypes from 'prop-types';

import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';

import LoadingSpinner from 'components/shared/LoadingSpinner';

function ActivityLogsTableView({ activityLogs, loading }) {
  if (loading) {
    return <LoadingSpinner />;
  }

  if (!activityLogs || activityLogs.length === 0) {
    return <div className="d-flex justify-content-center m-5 text-muted">No activities found</div>;
  }

  const formatMetaData = metaData => {
    return (
      <ul>
        {Object.entries(metaData).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong>{' '}
            {typeof value === 'object' &&
            value !== null &&
            value !== undefined &&
            !Array.isArray(value) ? (
              <ul>
                {Object.entries(value).map(([key1, value1]) => (
                  <li key={key1}>
                    <strong>{key1}:</strong>
                    {typeof value1 !== 'object' ? value1 : ` ${JSON.stringify(value1, null, 2)}`}
                  </li>
                ))}
              </ul>
            ) : typeof value === 'boolean' ? (
              value.toString()
            ) : Array.isArray(value) ? (
              value.join(', ')
            ) : (
              value
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Table bordered striped hover>
      <thead>
        <tr>
          <th>Activity Type</th>
          <th>User</th>
          <th>Date</th>
          <th>Meta Data</th>
        </tr>
      </thead>
      <tbody>
        {activityLogs.map(log => {
          return (
            <tr key={log.id}>
              <td className="py-2">{log.activity_log_type}</td>
              <td className="py-2">{log.user?.name}</td>
              <td className="py-2">{dayjs(log.date_created).format('YYYY-MM-DD HH:mm:ss')}</td>
              <td className="py-2">{log.meta_data ? formatMetaData(log.meta_data) : ''}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

ActivityLogsTableView.propTypes = {
  activityLogs: propTypes.array,
  loading: propTypes.bool,
};

export default ActivityLogsTableView;
