import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { useDebounce } from 'use-debounce';

import Container from 'components/shared/Container';
import GlobalProductDetailsSection from 'components/product_anomalies/GlobalProductDetailsSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PartnerProductListFilter from 'components/shared/PartnerProductListFilter';
import { PRODUCT_OUTLIERS_QA_CHANGES } from 'lib/networking/endpoints';
import ProductOutliersQATableView from 'components/product_anomalies/QA/ProductOutliersQATableView';
import useGet from 'lib/hooks/useGet';

function ProductOutliersQAView() {
  const [searchGlobalProductID, setSearchGlobalProductID] = useState('');
  const [searchGlobalProductIDQuery] = useDebounce(searchGlobalProductID, 800);
  const [productList, setProductList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1;

  const {
    data: { global_product = {}, products_with_changes = [], count = 0 } = {},
    loading,
    refetch,
  } = useGet(PRODUCT_OUTLIERS_QA_CHANGES, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    global_product_id_search_query: searchGlobalProductIDQuery,
    ...(productList?.distributor_id && {
      distributor_id: productList?.distributor_id,
    }),
    ...(productList?.manufacturer_id && {
      manufacturer_id: productList?.manufacturer_id,
    }),
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [productList, searchGlobalProductIDQuery]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <h3 className="mb-3 font-weight-bold">Global Product Changes QA View</h3>
      <Row className="d-flex align-items-center">
        <Col md={3}>
          <PartnerProductListFilter productList={productList} setProductList={setProductList} />
        </Col>
        <Col md={3}>
          <Form.Control
            type="text"
            onChange={event => {
              setSearchGlobalProductID(event.target.value);
              setCurrentPage(1);
            }}
            value={searchGlobalProductID}
            placeholder="Search by global product id"
          />
        </Col>
      </Row>
      <GlobalProductDetailsSection globalProduct={global_product} refetch={refetch} />
      <Row className="mt-4">
        <Col>
          <Button
            className={css(styles.button)}
            variant="link"
            onClick={() => setCurrentPage(currentPage - 1)}
            size="lg"
            disabled={loading || currentPage === 1}
          >
            &lt; Previous
          </Button>
          <Button
            className={css(styles.button)}
            variant="link"
            onClick={() => setCurrentPage(currentPage + 1)}
            size="lg"
            disabled={loading || currentPage === count}
          >
            Next &gt;
          </Button>
        </Col>
      </Row>
      <div className={css(styles.scrollableContainer)}>
        <ProductOutliersQATableView
          global_product={global_product}
          products={products_with_changes}
          refetch={refetch}
          currentPage={currentPage}
          count={count}
        />
      </div>
    </Container>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '60vh',
    overflowY: 'scroll',
  },
  button: {
    fontSize: '1em',
    padding: '0.5em 1em',
    fontWeight: 'bold',
  },
  select: {
    zIndex: 2,
  },
});

export default ProductOutliersQAView;
