import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getURL, BRANDS_GET_BY_ID, BRANDS_FOR_DROPDOWN } from 'lib/networking/endpoints';
import { httpGet } from 'lib/networking/http';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';

function BrandFilter({ brandID, setAttributeValue, isGlobalProductAttribute, autoFocus }) {
  const [initOption, setInitOption] = useState({});
  // initial load
  useEffect(() => {
    if (brandID) {
      const url = getURL(BRANDS_GET_BY_ID, { brand_id: brandID });
      httpGet(url).then(res => {
        if (res.status === 200) {
          const brand = res.data?.brand;
          if (brand) {
            setInitOption({ label: brand.name, value: brand.id });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchableScrollableSelect
      disabled={isGlobalProductAttribute}
      initOption={initOption}
      onChange={option => setAttributeValue(option)}
      fetchUrl={BRANDS_FOR_DROPDOWN}
      optionMapper={brand => ({
        label: brand.global_manufacturer_name
          ? `${brand.name} by ${brand.global_manufacturer_name}`
          : brand.name,
        value: brand.id,
      })}
      autoFocus={autoFocus}
    />
  );
}

BrandFilter.propTypes = {
  brandID: PropTypes.number,
  setAttributeValue: PropTypes.func,
  isGlobalProductAttribute: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default BrandFilter;
