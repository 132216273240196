import React from 'react';
import PropTypes from 'prop-types';

import { Col, Form, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faParking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select/creatable';

import AttributeInputType from 'lib/enums/AttributeInputType';
import BrandFilter from 'components/global_products/detail_view/BrandFilter';
import DocumentsInputTypeView from 'components/global_products/detail_view/DocumentsInputTypeView';
import ManufacturerFilter from 'components/global_products/detail_view/ManufacturerFilter';

const CUSTOM_FIELDS = ['brand_name', 'manufacturer_name'];

function AttributeValueInputField({
  attribute,
  setIsEditing,
  setAttributeValue,
  isGlobalProductAttribute = false,
}) {
  function custom(attribute) {
    if (attribute.attribute_name === 'brand_name') {
      return (
        <BrandFilter
          brandID={attribute.brand_id}
          setAttributeValue={e => setAttributeValue(attribute, { id: e?.value, name: e?.label })}
          isGlobalProductAttribute={isGlobalProductAttribute}
          autoFocus={false}
        />
      );
    }
    if (attribute.attribute_name === 'manufacturer_name') {
      return (
        <ManufacturerFilter
          manufacturerName={attribute.attribute_value}
          setAttributeValue={e => setAttributeValue(attribute, e)}
          isGlobalProductAttribute={isGlobalProductAttribute}
          autoFocus={false}
        />
      );
    }
  }

  return (
    <Row className="px-4 py-1">
      <Col>
        {`${attribute.attribute_name} 
           ${
             attribute.data_source_type
               ? `| Source - ${attribute.data_source_type}${
                   attribute.data_source_name ? `/${attribute.data_source_name}` : ''
                 }`
               : ''
           } `}
        {attribute.is_propagated && (
          <>
            <FontAwesomeIcon className="ml-2" icon={faParking} />
            <span className="pl-1">{attribute.entity_owner}</span>
          </>
        )}
      </Col>
      <Col>
        {CUSTOM_FIELDS.includes(attribute.attribute_name) ? (
          custom(attribute)
        ) : attribute.input_type === AttributeInputType.DOCUMENT ? (
          <DocumentsInputTypeView
            strJsonObject={attribute.attribute_value}
            setAttributeValue={e => setAttributeValue(attribute, e)}
            isEditView
          />
        ) : attribute.input_type === AttributeInputType.BOOLEAN ? (
          <Select
            isDisabled={isGlobalProductAttribute}
            className={css(styles.select)}
            defaultValue={{
              label:
                attribute.attribute_value !== null
                  ? attribute.attribute_value === '1'
                    ? 'true'
                    : 'false'
                  : null,
              value:
                attribute.attribute_value !== null
                  ? attribute.attribute_value === '1'
                    ? 'true'
                    : 'false'
                  : null,
            }}
            options={[
              { label: 'true', value: true },
              { label: 'false', value: false },
            ]}
            onChange={e => setAttributeValue(attribute, e.value)}
          />
        ) : attribute.input_type === AttributeInputType.LIST ? (
          <Select
            isDisabled={isGlobalProductAttribute}
            className={css(styles.select)}
            defaultValue={{
              label: attribute.attribute_value,
              value: attribute.attribute_value,
            }}
            options={attribute.attribute_options.map(option => {
              return { label: option, value: option };
            })}
            onChange={e => setAttributeValue(attribute, e?.value)}
            isClearable
          />
        ) : (
          <Form.Control
            type="text"
            disabled={isGlobalProductAttribute}
            defaultValue={attribute.attribute_value}
            onChange={e => setAttributeValue(attribute, e.target.value)}
          />
        )}
      </Col>
    </Row>
  );
}

const styles = StyleSheet.create({
  select: {
    flexGrow: 1,
  },
});

AttributeValueInputField.propTypes = {
  attribute: PropTypes.object,
  setIsEditing: PropTypes.func,
  setAttributeValue: PropTypes.func,
  isGlobalProductAttribute: PropTypes.bool,
};

export default AttributeValueInputField;
