import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AttributePriorityOptions } from 'lib/enums/AttributePriorityLevel';
import {
  ATTRIBUTE_DELETE,
  ATTRIBUTE_MIGRATE_TO_GLOBAL_ATTRIBUTE,
  ATTRIBUTES_SECTIONS,
  ATTRIBUTES_SUB_SECTIONS_FOR_SECTION,
  getURL,
  GLOBAL_ATTRIBUTE_DELETE,
  GLOBAL_ATTRIBUTE_MIGRATE_TO_ATTRIBUTE,
} from 'lib/networking/endpoints';
import { Button, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select/creatable';
import ToggleSwitch from 'react-switch';
import { useParams } from 'react-router-dom';

import AttributeInputType, {
  ATTRIBUTE_INPUT_TYPE_LIST,
  ATTRIBUTE_STATUS_LIST,
} from 'lib/enums/AttributeInputType';
import { backgroundGrey2, grape } from 'lib/css/colors';
import AttributeIconSelect from 'components/manage_attribute_value/shared/AttributeIconSelect.jsx';
import EditableFieldV2 from 'components/shared/EditableFieldV2';
import HelperTooltip from 'components/shared/HelperTooltip';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SwalDialog from 'lib/utils/toast';
import useDelete from 'lib/hooks/useDelete';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function DetailsSection({
  attributeDetails,
  updateAttribute,
  showToggleButtons = true,
  showVisibilityStatusOnly = true,
}) {
  const { attribute_type } = useParams();
  const attributeName = attributeDetails.attributeName || '';
  const attributeDisplayName = attributeDetails.attributeDisplayName || '';
  const universalSpecificField = attributeDetails.universalSpecificField;
  const fsaAttributeId = attributeDetails.fsaAttributeId || '';
  const [section, setSection] = useState(
    {
      label: attributeDetails.section.attribute_section_name,
      value: attributeDetails.section.id,
    } || {},
  );
  const [subSection, setSubSection] = useState(
    {
      label: attributeDetails.subSection.attribute_sub_section_name,
      value: attributeDetails.subSection.id,
    } || {},
  );
  const [attributeStatus, setAttributeStatus] = useState(null);
  const { data: { data: sections = [] } = [], sectionsLoading } = useGet(ATTRIBUTES_SECTIONS, {});

  const { data: { data: subSections = [] } = [] } = useGet(
    getURL(ATTRIBUTES_SUB_SECTIONS_FOR_SECTION, {
      attribute_section_id: section?.value,
    }),
    {},
    !section,
  );

  useEffect(() => {
    if (!attributeDetails) {
      return;
    }
    /*
     * Mandatory status could be null, true or false
     */
    if (attributeDetails.mandatory === null || attributeDetails.mandatory === undefined) {
      setAttributeStatus(null);
    } else {
      setAttributeStatus(attributeDetails.mandatory);
    }
  }, [attributeDetails]);

  const { postData: migrateAttribute } = usePost(
    ATTRIBUTE_MIGRATE_TO_GLOBAL_ATTRIBUTE,
    res => {
      SwalDialog('success', 'Attributes successfully migrated ', 'Success', 'center');
    },
    error => {
      const res = error?.response.data.message;
      if (res) {
        SwalDialog('error', res, 'Error', 'center');
      } else {
        SwalDialog('error', 'An error occurred', 'Error', 'center');
      }
    },
  );

  const { postData: migrateGlobalAttribute } = usePost(
    GLOBAL_ATTRIBUTE_MIGRATE_TO_ATTRIBUTE,
    res => {
      SwalDialog('success', 'Attributes successfully migrated ', 'Success', 'center');
    },
    error => {
      const res = error?.response.data.message;
      if (res) {
        SwalDialog('error', res, 'Error', 'center');
      } else {
        SwalDialog('error', 'An error occurred', 'Error', 'center');
      }
    },
  );

  const { deleteData: deleteAttribute } = useDelete(
    ATTRIBUTE_DELETE,
    false,
    () => {
      SwalDialog('success', 'Started deleting', 'Success', 'center', () => {});
    },
    error => {
      SwalDialog(
        'error',
        error.response?.data?.message,
        'An error occurred while removing mapping',
        'center',
      );
    },
  );

  const { deleteData: deleteGlobalAttribute } = useDelete(
    GLOBAL_ATTRIBUTE_DELETE,
    false,
    () => {
      SwalDialog('success', 'Started deleting', 'Success', 'center', () => {});
    },
    error => {
      SwalDialog(
        'error',
        error.response?.data?.message,
        'An error occurred while removing mapping',
        'center',
      );
    },
  );

  if (sectionsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="mt-4">
      {attributeDetails ? (
        <>
          <Row className={classNames(css(styles.header), 'py-1 font-weight-bold pt-1')}>
            <Col sm={9}>Details</Col>
            <Col sm={1} className="pr-0">
              <Button
                variant="outline-primary"
                block
                onClick={() => {
                  if (attribute_type === 'global-attribute') {
                    deleteGlobalAttribute({ global_attribute_id: attributeDetails.id });
                  } else {
                    deleteAttribute({ attribute_id: attributeDetails.id });
                  }
                }}
              >
                Delete
              </Button>
            </Col>
            <Col sm={2}>
              <Button
                block
                onClick={() => {
                  if (attribute_type === 'global-attribute') {
                    migrateGlobalAttribute({ global_attribute_id: attributeDetails.id });
                  } else {
                    migrateAttribute({ attribute_id: attributeDetails.id });
                  }
                }}
              >
                {`Migrate to ${
                  attribute_type === 'global-attribute' ? 'an Attribute' : 'Global Attribute'
                }`}
              </Button>
            </Col>
          </Row>
          <Row className="mt-3 font-weight-bold py-2">
            <Col sm={3}>Name</Col>
            <Col sm={3}>Type</Col>
            <Col sm={3}>Category</Col>
            <Col sm={3}>SubCategory</Col>
          </Row>
          <Row>
            <Col sm={3}>
              <EditableFieldV2
                value={attributeName}
                setValue={e => updateAttribute({ ...attributeDetails, attributeName: e })}
              />
            </Col>
            <Col sm={3}>
              <Select
                value={attributeDetails.inputType || {}}
                options={ATTRIBUTE_INPUT_TYPE_LIST}
                onChange={e => updateAttribute({ ...attributeDetails, inputType: e })}
              />
            </Col>
            <Col sm={3}>
              <Select
                value={section}
                options={sections.map(section => ({
                  label: section.attribute_section_name,
                  value: section.id,
                }))}
                onChange={e => {
                  setSection(e);
                  setSubSection({});
                }}
                isSearchable
                isClearable
              />
            </Col>
            <Col sm={3}>
              <Select
                value={subSection}
                options={subSections.map(subSection => ({
                  label: subSection.attribute_sub_section_name,
                  value: subSection.id,
                }))}
                onChange={e => {
                  setSubSection(e);
                  updateAttribute({
                    ...attributeDetails,
                    section: { attribute_section_name: section?.label, id: section?.value },
                    subSection: { attribute_sub_section_name: e?.label, id: e?.value },
                  });
                }}
                isSearchable
                isClearable
              />
            </Col>
          </Row>
          <Row className="mt-3 font-weight-bold py-2">
            <Col sm={3}>Attribute Status</Col>
            <Col sm={3}>Universal Specific Field</Col>
            <Col sm={3}>Attribute ID</Col>
            <Col sm={3}>FSA ID of the Attribute</Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Select
                value={ATTRIBUTE_STATUS_LIST.find(op => op.value === attributeStatus)}
                options={ATTRIBUTE_STATUS_LIST}
                onChange={e => {
                  setAttributeStatus(e.value);
                  updateAttribute({ ...attributeDetails, mandatory: e.value });
                }}
              />
            </Col>
            <Col sm={3}>
              <EditableFieldV2
                value={universalSpecificField}
                setValue={e => updateAttribute({ ...attributeDetails, universalSpecificField: e })}
              />
            </Col>
            <Col sm={3}>
              <EditableFieldV2
                value={fsaAttributeId}
                setValue={e => updateAttribute({ ...attributeDetails, fsaAttributeId: e })}
              />
            </Col>
            <Col sm={3} className="font-weight-bold py-2">
              <p>
                {attributeDetails.section.section_id}.{attributeDetails.subSection.sub_section_id}.
                {fsaAttributeId}
              </p>
            </Col>
          </Row>
          <Row className="mt-1 font-weight-bold py-2">
            <Col sm={3}>Display Name</Col>
            <Col sm={3}>USDA Code</Col>
            <Col sm={3}>Icon</Col>
          </Row>
          <Row>
            <Col sm={3}>
              <EditableFieldV2
                value={attributeDisplayName}
                setValue={e => updateAttribute({ ...attributeDetails, attributeDisplayName: e })}
              />
            </Col>
            <Col sm={3}>
              <EditableFieldV2
                value={attributeDetails.USDACode}
                setValue={e => updateAttribute({ ...attributeDetails, USDACode: e })}
              />
            </Col>
            <AttributeIconSelect
              attributeDetails={attributeDetails}
              updateAttribute={updateAttribute}
            />
          </Row>
          <Row className="mt-3 ml-1 font-weight-bold py-2">Description</Row>
          <Row>
            <Col>
              <EditableFieldV2
                value={attributeDetails.description}
                setValue={e => updateAttribute({ ...attributeDetails, description: e })}
                view_as_plain_text
                isTextArea={true}
              />
            </Col>
          </Row>
          {showToggleButtons ? (
            <>
              {!showVisibilityStatusOnly ? (
                <>
                  <Row className="mt-3 font-weight-bold py-2">
                    <Col sm={3}>Priority Level</Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Select
                        value={AttributePriorityOptions.find(
                          op => op.value === attributeDetails.priorityLevel,
                        )}
                        options={AttributePriorityOptions}
                        onChange={e =>
                          updateAttribute({
                            ...attributeDetails,
                            priorityLevel: e.value,
                          })
                        }
                        isSearchable={false}
                        isClearable={false}
                      />
                    </Col>
                  </Row>
                </>
              ) : null}
              <Row className="mt-3">
                <Col sm={3}>
                  <Form.Label className="float-left mr-3">Visibility</Form.Label>
                  <ToggleSwitch
                    onColor={grape}
                    checked={attributeDetails.visibility}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() =>
                      updateAttribute({
                        ...attributeDetails,
                        visibility: !attributeDetails.visibility,
                      })
                    }
                  />
                </Col>
                <Col sm={3} className="d-flex align-items-center">
                  <Form.Label className="float-left mr-3">
                    Is Filterable
                    <HelperTooltip
                      text="If enabled, this attribute will be
                    available for filtering in the product catalog pages
                    also know as tag family data"
                    />
                  </Form.Label>
                  <ToggleSwitch
                    onColor={grape}
                    checked={attributeDetails.isFilterable}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() =>
                      updateAttribute({
                        ...attributeDetails,
                        isFilterable: !attributeDetails.isFilterable,
                      })
                    }
                  />
                </Col>
                <Col sm={3} className="d-flex align-items-center">
                  <Form.Label className="float-left mr-3">
                    Is Indexed
                    <HelperTooltip
                      text="If enabled, the attribute value will be
                    be a keyword in search in C+D"
                    />
                  </Form.Label>
                  <ToggleSwitch
                    onColor={grape}
                    checked={attributeDetails.isIndexed}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() =>
                      updateAttribute({
                        ...attributeDetails,
                        isIndexed: !attributeDetails.isIndexed,
                      })
                    }
                  />
                </Col>
                <Col sm={3} className="d-flex align-items-center">
                  <Form.Label className="float-left mr-3">Verified</Form.Label>
                  <ToggleSwitch
                    onColor={grape}
                    checked={attributeDetails.verified}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() =>
                      updateAttribute({
                        ...attributeDetails,
                        verified: !attributeDetails.verified,
                      })
                    }
                  />
                </Col>
                {!showVisibilityStatusOnly ? (
                  <Col sm={3}>
                    <Form.Label className="float-left mr-3">Key Attribute</Form.Label>
                    <ToggleSwitch
                      onColor={grape}
                      checked={attributeDetails.keyAttribute}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={() =>
                        updateAttribute({
                          ...attributeDetails,
                          keyAttribute: !attributeDetails.keyAttribute,
                        })
                      }
                    />
                  </Col>
                ) : null}
              </Row>
            </>
          ) : null}
          {attributeDetails.inputType?.value === AttributeInputType.LIST ? (
            <Row className="mt-3 font-weight-bold py-2">
              <Col sm={3}>
                <Form.Label className="float-left mr-3">Use Display Value</Form.Label>
                <ToggleSwitch
                  onColor={grape}
                  checked={attributeDetails.useDisplayValue}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() =>
                    updateAttribute({
                      ...attributeDetails,
                      useDisplayValue: !attributeDetails.useDisplayValue,
                    })
                  }
                />
              </Col>
            </Row>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: backgroundGrey2,
  },
});

DetailsSection.propTypes = {
  attributeDetails: PropTypes.object,
  updateAttribute: PropTypes.func,
  showToggleButtons: PropTypes.bool,
  showVisibilityStatusOnly: PropTypes.bool,
};

export default DetailsSection;
