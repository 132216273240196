import { amber, blue, green } from 'lib/css/colors';

//Update along with app/lib/enums/similar_brand_names_status.py
const SimilarBrandNamesStatus = {
  NOT_REVIEWED: 'NOT_REVIEWED',
  FIRST_YES: 'FIRST_YES',
  SECOND_YES: 'SECOND_YES',
  THIRD_YES: 'THIRD_YES',
  FIRST_NO: 'FIRST_NO',
  SECOND_NO: 'SECOND_NO',
  THIRD_NO: 'THIRD_NO',
  TWO_YES_ONE_NO: 'TWO_YES_ONE_NO',
  TWO_NO_ONE_YES: 'TWO_NO_ONE_YES',
  TIED_1: 'TIED_1',
  TIED: 'TIED',
};

const StatusColors = {
  [SimilarBrandNamesStatus.FIRST_YES]: amber,
  [SimilarBrandNamesStatus.SECOND_YES]: amber,
  [SimilarBrandNamesStatus.THIRD_YES]: green,
  [SimilarBrandNamesStatus.FIRST_NO]: amber,
  [SimilarBrandNamesStatus.SECOND_NO]: amber,
  [SimilarBrandNamesStatus.TIED_1]: amber,
  [SimilarBrandNamesStatus.TWO_YES_ONE_NO]: amber,
  [SimilarBrandNamesStatus.TWO_NO_ONE_YES]: amber,
  [SimilarBrandNamesStatus.TIED]: blue,
};

export { SimilarBrandNamesStatus, StatusColors };
