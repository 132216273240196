import React from 'react';
import PropTypes from 'prop-types';

import { Form, Image, OverlayTrigger, Popover } from 'react-bootstrap';
import classNames from 'classnames';

import pointerOnHover from 'lib/css/pointerOnHover';
import { ProductType } from 'lib/enums/ProductType';

function ProductLinksSuggestionsTableRow({
  suggestion,
  selectedSuggestionsIds,
  setSelectedSuggestionsIds,
  catalog_type,
}) {
  const handleSuggestionsCheck = e => {
    const suggestionId = suggestion.id;
    if (e.target.checked) {
      if (catalog_type === ProductType.DISTRIBUTOR) {
        setSelectedSuggestionsIds([suggestionId]);
      } else {
        setSelectedSuggestionsIds(prev => [...prev, suggestionId]);
      }
    } else {
      setSelectedSuggestionsIds(prev => prev.filter(id => id !== suggestionId));
    }
  };
  return (
    <>
      {catalog_type === ProductType.DISTRIBUTOR ? (
        <tr className={classNames(pointerOnHover)}>
          <td>
            <Form.Check
              type="checkbox"
              checked={selectedSuggestionsIds.includes(suggestion.id)}
              onChange={handleSuggestionsCheck}
            />
          </td>
          <td>{suggestion.suggested_manufacturer_product.name}</td>
          <td>
            <>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover id="popover-basic" style={{ maxWidth: 400 }}>
                    <Popover.Content>
                      <Image
                        width={400}
                        fluid
                        src={
                          suggestion.suggested_manufacturer_product.propagated_thumbnail_image_url
                        }
                      />
                    </Popover.Content>
                  </Popover>
                }
              >
                <Image
                  width={50}
                  fluid
                  src={suggestion.suggested_manufacturer_product.propagated_thumbnail_image_url}
                  thumbnail
                />
              </OverlayTrigger>
            </>
          </td>
          <td>{suggestion.suggested_manufacturer_product.global_manufacturer.name}</td>
          <td>{suggestion.suggested_manufacturer_product.manufacturer_sku}</td>
          <td>{suggestion.suggested_manufacturer_product.brand.name}</td>
          <td>{suggestion.suggested_manufacturer_product.pack_size}</td>
          <td>{suggestion.suggested_manufacturer_product.gtin}</td>
          <td>
            <strong>{Math.round(suggestion.similarity_score * 100)}%</strong>
          </td>
        </tr>
      ) : (
        <tr className={classNames(pointerOnHover)}>
          <td>
            <Form.Check
              type="checkbox"
              checked={selectedSuggestionsIds.includes(suggestion.id)}
              onChange={handleSuggestionsCheck}
            />
          </td>
          <td>{suggestion.suggested_vendor_product.name}</td>
          <td>
            <>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover id="popover-basic" style={{ maxWidth: 400 }}>
                    <Popover.Content>
                      <Image
                        width={400}
                        fluid
                        src={suggestion.suggested_vendor_product.propagated_thumbnail_image_url}
                      />
                    </Popover.Content>
                  </Popover>
                }
              >
                <Image
                  width={50}
                  fluid
                  src={suggestion.suggested_vendor_product.propagated_thumbnail_image_url}
                  thumbnail
                />
              </OverlayTrigger>
            </>
          </td>
          <td>{suggestion.suggested_vendor_product.manufacturer_name}</td>
          <td>{suggestion.suggested_vendor_product.manufacturer_sku}</td>
          <td>{suggestion.suggested_vendor_product.brand.name}</td>
          <td>{suggestion.suggested_vendor_product.pack_size}</td>
          <td>{suggestion.suggested_vendor_product.gtin}</td>
          <td>
            <strong>{Math.round(suggestion.similarity_score * 100)}%</strong>
          </td>
        </tr>
      )}
    </>
  );
}

ProductLinksSuggestionsTableRow.propTypes = {
  suggestion: PropTypes.object,
  selectedSuggestionsIds: PropTypes.array,
  setSelectedSuggestionsIds: PropTypes.func,
  catalog_type: PropTypes.string,
};

export default ProductLinksSuggestionsTableRow;
