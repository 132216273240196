import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';

import ActionDropDown from 'components/shared/ActionDropDown';
import { areYouSure } from 'lib/utils/toast';
import {
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_DROPDOWN_DATA,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_LINK_PRODUCTS,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_REJECT,
  GLOBAL_MANUFACTURER_PRODUCT_CREATION,
  SKU_MAPPINGS_VERIFY_LINKS,
} from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import CreateMfrProductsFromDistributorProductsModal from 'components/global_products/detail_view/CreateMfrProductsFromDistributorProductsModal';
import DistributorFilter from 'components/shared/DistributorFilter';
import DuplicateProductTableRow from 'components/duplicate_global_products/DuplicateProductTableRow';
import { grape } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { ProductType } from 'lib/enums/ProductType';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

const ALL_OPTION = { label: 'All', value: '' };

function ManageDuplicateGlobalProductsView() {
  const itemsPerPage = 1;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showCreateManufacturerModal, setShowCreateManufacturerModal] = useState(false);
  const [showClusteredProductsOnly, setShowClusteredProductsOnly] = useState(true);
  const [selectedVendorId, setSelectedVendorId] = useState('');

  const {
    data: { count } = {},
    loading: loadingCount,
    refetch: refetchCount,
  } = useGet(DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS, {
    brand_id: selectedBrand,
    manufacturer_id: selectedManufacturer,
    count_only: true,
    show_clustered_products_only: showClusteredProductsOnly,
    distributor_id: selectedVendorId,
  });
  const { data: { manufactures, brands } = {}, loading: loadingDropdowns } = useGet(
    DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_DROPDOWN_DATA,
    {
      manufacturer_id: selectedManufacturer,
    },
  );

  const brandOptions = [
    ALL_OPTION,
    ...(brands?.map(brand => ({ label: brand.name, value: brand.id })) || []),
  ];
  const manufacturerOptions = [
    ALL_OPTION,
    ...(manufactures?.map(manufacturer => ({
      label: manufacturer.name,
      value: manufacturer.id,
    })) || []),
  ];

  const {
    data: { 0: product_suggestion } = [],
    loading: loadingData,
    refetch: refetchSuggestions,
  } = useGet(DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS, {
    brand_id: selectedBrand,
    manufacturer_id: selectedManufacturer,
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    show_clustered_products_only: showClusteredProductsOnly,
    distributor_id: selectedVendorId,
  });

  const { postData: linkProducts, loading: linkingProducts } = usePost(
    DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_LINK_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'The selected products have been linked successfully',
        timer: 600,
      }).then(() => {
        refetchCount();
        refetchSuggestions();
        setSelectedProducts([]);
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred : ${error?.response?.data?.message}`,
      }),
  );

  const { postData: reject, loading: rejecting } = usePost(
    DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_REJECT,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'The suggestion has been rejected successfully',
        timer: 600,
      }).then(() => {
        refetchCount();
        refetchSuggestions();
        setSelectedProducts([]);
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred : ${error?.response?.data?.message}`,
      }),
  );

  const { postData: createManufacturerProducts, loading: creatingManufacturerProducts } = usePost(
    GLOBAL_MANUFACTURER_PRODUCT_CREATION,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Started creating manufacturer products',
        title: 'Success',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: duplicateGlobalProductSuggetions } = usePost(
    DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Started sending data to find duplicate global product suggestions.',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while sending data: ${error?.response?.data?.message}`,
      }),
  );

  const { postData: verifyLinks, loading: linksVerifying } = usePost(
    SKU_MAPPINGS_VERIFY_LINKS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully verified',
        title: 'Success',
      });
      refetchSuggestions();
      refetchCount();
      setSelectedProducts([]);
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const handleLinkProducts = () => {
    areYouSure(
      () =>
        linkProducts({
          product_uuids: selectedProducts,
          duplicate_global_product_suggestion_id: product_suggestion?.id,
        }),
      'Are you sure you want to link the selected products?',
      'Link Products',
    );
  };

  const handleReject = () => {
    areYouSure(
      () =>
        reject({
          duplicate_global_product_suggestion_id: product_suggestion?.id,
        }),
      'Are you sure you want to reject the suggestion?',
      'Reject Suggestion',
    );
  };

  const loading = loadingCount || loadingData;

  const handleSelectAll = checked => {
    if (checked) {
      const allProductUUIDs = product_suggestion.similar_products.map(
        product => `${product.product_type}:${product.id}`,
      );
      setSelectedProducts(allProductUUIDs);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleManufacturerChange = option => {
    setSelectedManufacturer(option?.value);
    setCurrentPage(1);
    setShowClusteredProductsOnly(true);
    setSelectedBrand('');
  };

  const handleBrandChange = option => {
    setSelectedBrand(option?.value);
    setCurrentPage(1);
  };

  const handleDistributorChange = option => {
    setSelectedVendorId(option?.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    setSelectedProducts([]);
  }, [currentPage]);

  const isMultiSelected = selectedProducts.length > 0;
  const hasManufacturerProducts = selectedProducts.some(product =>
    product.startsWith(ProductType.MANUFACTURER),
  );

  const selectedDistributorProductIDs = selectedProducts
    .filter(item => item.startsWith(ProductType.DISTRIBUTOR))
    .map(item => parseInt(item.split(':')[1]));

  const selectedManufacturerProductIDs = selectedProducts
    .filter(item => item.startsWith(ProductType.MANUFACTURER))
    .map(item => parseInt(item.split(':')[1]));

  const selectedDistributorProducts = product_suggestion?.similar_products.filter(product =>
    selectedDistributorProductIDs.includes(product.id),
  );

  return (
    <Container>
      <div className=" mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">Link Duplicate Products</h3>
        {selectedManufacturer && !showClusteredProductsOnly && (
          <ActionDropDown
            submenuItems={[
              {
                title: 'Send data to ML server to find similar products to link',
                action: () =>
                  duplicateGlobalProductSuggetions({ manufacturer_id: selectedManufacturer }),
              },
              {
                title: 'Manufacturer portal creation for manufacturer',
                action: () =>
                  createManufacturerProducts({
                    manufacturer_id: selectedManufacturer,
                    distributor_id: selectedVendorId,
                  }),
                disabled: creatingManufacturerProducts,
              },
            ]}
          />
        )}
      </div>
      <Row className="d-flex align-items-center ml-3">
        <Form.Group as={Col} md={2}>
          <Form.Label>Filter by manufacturer</Form.Label>
          <Select
            value={manufacturerOptions?.find(option => option.value === selectedManufacturer)}
            options={manufacturerOptions}
            onChange={handleManufacturerChange}
            isLoading={loadingDropdowns}
            className={css(styles.selectMenu)}
            menuPortalTarget={document.body}
          />
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Form.Label>Filter by brand</Form.Label>
          <Select
            value={brandOptions?.find(option => option.value === selectedBrand)}
            options={brandOptions}
            onChange={handleBrandChange}
            isLoading={loadingDropdowns}
            className={css(styles.selectMenu)}
            menuPortalTarget={document.body}
          />
        </Form.Group>
        {!showClusteredProductsOnly && (
          <Col md={2}>
            <DistributorFilter
              updateSelectedVendorId={option => handleDistributorChange(option)}
              label="Filter by distributor"
              loading={loading}
            />
          </Col>
        )}
        {selectedManufacturer && (
          <Col md={2} className="d-flex align-items-center">
            Show Clustered Products Only
            <ToggleSwitch
              checked={showClusteredProductsOnly}
              onColor={grape}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                setShowClusteredProductsOnly(!showClusteredProductsOnly);
                setCurrentPage(1);
                setSelectedVendorId();
              }}
            />
          </Col>
        )}
        <Col className="d-flex justify-content-end mr-3">
          {!showClusteredProductsOnly && (
            <Button
              className="mr-3"
              disabled={!isMultiSelected || linksVerifying}
              onClick={() =>
                verifyLinks({
                  global_product_id:
                    product_suggestion.similar_products.length > 0
                      ? product_suggestion.similar_products[0].global_product.id
                      : null,
                  manufacturer_product_ids: selectedManufacturerProductIDs,
                  vendor_product_ids: selectedDistributorProductIDs,
                })
              }
            >
              Verify Links
            </Button>
          )}
          {showClusteredProductsOnly && (
            <div>
              <Button
                disabled={rejecting}
                variant="primary"
                onClick={handleReject}
                className="mr-2"
              >
                Reject Suggestion
              </Button>
            </div>
          )}
          {showClusteredProductsOnly && (
            <Button
              disabled={
                linkingProducts || !selectedProducts?.length || selectedProducts?.length < 2
              }
              variant="primary"
              onClick={handleLinkProducts}
            >
              Link Selected Products
            </Button>
          )}
          <Button
            className="ml-2"
            disabled={!isMultiSelected || hasManufacturerProducts}
            onClick={() => setShowCreateManufacturerModal(true)}
          >
            Create Manufacturer Products
          </Button>
        </Col>
      </Row>

      {loading && <LoadingSpinner />}

      {product_suggestion && count > 0 ? (
        <div>
          <Row className="mb-2">
            <Col>
              <Button
                className={css(styles.button)}
                variant="link"
                onClick={() => setCurrentPage(currentPage - 1)}
                size="lg"
                disabled={loading || currentPage === 1}
              >
                &lt; Previous
              </Button>
              <Button
                className={css(styles.button)}
                variant="link"
                onClick={() => setCurrentPage(currentPage + 1)}
                size="lg"
                disabled={loading || currentPage === count}
              >
                Next &gt;
              </Button>
            </Col>
            <Col className="d-flex align-items-center justify-content-end mr-3">
              <h6>
                {currentPage}/{count} total suggestions
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check
                        type="checkbox"
                        checked={
                          product_suggestion?.similar_products?.length > 0 &&
                          selectedProducts.length === product_suggestion?.similar_products?.length
                        }
                        onChange={e => handleSelectAll(e.target.checked)}
                      />
                    </th>
                    <th></th> {/* Image */}
                    <th>Global Product</th>
                    <th>Product</th>
                    <th>Entity Type</th>
                    <th>Entity Name</th>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>Brand</th>
                    <th>Pack Size</th>
                    <th>CD Product Score</th>
                    <th>Verification Status</th>
                  </tr>
                </thead>
                <tbody>
                  {product_suggestion.similar_products?.map(similar_product => (
                    <DuplicateProductTableRow
                      key={similar_product.id}
                      product={similar_product}
                      selectedProducts={selectedProducts}
                      setSelectedProducts={setSelectedProducts}
                    />
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="d-flex justify-content-center m-5 text-dark font-weight-bold">
          No duplicate products to show
        </div>
      )}
      {showCreateManufacturerModal && (
        <CreateMfrProductsFromDistributorProductsModal
          selectedProductsList={selectedDistributorProducts}
          onHide={() => {
            setShowCreateManufacturerModal(false);
            setSelectedProducts([]);
          }}
          refetch={() => {
            refetchSuggestions();
            refetchCount();
          }}
          clusterId={showClusteredProductsOnly && product_suggestion.id}
        />
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  button: {
    fontSize: '1.2em',
    padding: '0.5em',
    fontWeight: 'bold',
  },
  selectMenu: {
    zIndex: 9999,
  },
});

export default ManageDuplicateGlobalProductsView;
