import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import Container from 'components/shared/Container';
import { DATA_SOURCE_CONNECTED_PRODUCTS } from 'lib/networking/endpoints';
import { Image } from 'react-bootstrap';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';

function DataSourceProductsTab({ dataSource, manufacturerId }) {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const { data: { data: products = [], count = 0 } = {}, loading } = useGet(
    DATA_SOURCE_CONNECTED_PRODUCTS,
    {
      data_source_id: dataSource.id,
      ...(manufacturerId && { manufacturer_id: manufacturerId }),
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
    },
    !(dataSource && Object.keys(dataSource).length > 0),
  );

  if (loading && !products.length) {
    return <LoadingSpinner />;
  }

  if (!products.length) {
    return <Container>No Products, have you run Process on the Data Source?</Container>;
  }

  return (
    <Container>
      <PaginationComponent
        totalItems={count}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <table className="table table-striped">
        <thead>
          <tr>
            {dataSource.ignore_entity_type && <th>Entity Type</th>}
            {dataSource.ignore_entity_type && <th>Entity Name</th>}
            <th>SKU</th>
            <th>Thumbnail</th>
            <th>Name</th>
            <th>GTIN</th>
            <th>Last Modified</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <tr
              key={product.id}
              onClick={() =>
                history.push(
                  `/global-product-detail/${product.global_product.id}/` +
                    `${product.id}/${product.global_manufacturer ? 'manufacturer' : 'distributor'}`,
                )
              }
              className={pointerOnHover}
            >
              {dataSource.ignore_entity_type && (
                <td>{product.global_manufacturer ? 'Manufacturer' : 'Distributor'}</td>
              )}
              {dataSource.ignore_entity_type && (
                <td>{product.global_manufacturer?.name || product.global_vendor?.name}</td>
              )}
              <td>{product.manufacturer_sku || product.vendor_sku}</td>
              <td>
                <Image
                  src={product.propagated_thumbnail_image_url || placeholderProductImage}
                  className="d-block"
                  height={40}
                />
              </td>
              <td>{product.name}</td>
              <td>{product.global_product?.gtin}</td>
              <td>{product.date_modified}</td>
              <td>{product.date_created}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

DataSourceProductsTab.propTypes = {
  dataSource: PropTypes.object,
  manufacturerId: PropTypes.number,
};

export default DataSourceProductsTab;
