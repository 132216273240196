import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { PRODUCT_OUTLIERS_REJECT_CHANGES } from 'lib/networking/endpoints';
import { ProductTypeDisplay } from 'lib/enums/ProductType';
import usePost from 'lib/hooks/usePost';

function ProductOutliersQAFeedbackModal({ show, onHide, product, refetch }) {
  const [feedback, setFeedback] = useState('');

  const { postData: rejectChanges, loading: rejectingChanges } = usePost(
    PRODUCT_OUTLIERS_REJECT_CHANGES,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully rejected',
        title: 'Success',
      });
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  return (
    <Modal animation={false} centered size="md" show={show} onHide={onHide}>
      <Modal.Header className="border-0" closeButton>
        <h4 className="font-weight-bold">Add Feedback</h4>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h4>{product.name}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Feedback</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={feedback}
                onChange={event => setFeedback(event.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="primary"
              className="float-right"
              onClick={() => {
                rejectChanges({
                  activity_log_id: product.activity_log_id,
                  manufacturer_product_ids:
                    product.entity_type === ProductTypeDisplay.MANUFACTURER ? [product.id] : [],
                  vendor_product_ids:
                    product.entity_type === ProductTypeDisplay.DISTRIBUTOR ? [product.id] : [],
                  feedback: feedback,
                });
                onHide();
              }}
              disabled={rejectingChanges}
            >
              Reject Changes
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

ProductOutliersQAFeedbackModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  showFeedback: PropTypes.bool,
  product: PropTypes.object,
  refetch: PropTypes.func,
};

export default ProductOutliersQAFeedbackModal;
