import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { backgroundGrey4 } from 'lib/css/colors';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select/creatable';

import { areYouSure } from 'lib/utils/toast';
import ImagesDisplayModal from 'components/settings/support_tickets/ImagesDisplayModal';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { SUPPORT_TICKETS_UPDATE } from 'lib/networking/endpoints';
import { SupportTicketStatusOptions } from 'lib/enums/SupportTicketEnums';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function SupportTicketsModal({ ticket, onHide, refetch }) {
  const images =
    ticket.details?.attachments.length > 0
      ? ticket.details?.attachments.map(imageUrl => ({
          url: imageUrl,
        }))
      : [];

  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(ticket.status);
  const [comments, setComment] = useState(ticket.comments || null);

  const { postData: updateStatus, loading: updatingStatus } = usePost(
    SUPPORT_TICKETS_UPDATE,
    () => {
      SwalDialog('success', `Successfully updated the status`, 'Success', 'center', () => {});
      refetch();
      onHide();
      window.location.reload();
    },
    () => {
      SwalDialog('error', 'Error occurred while Updating Products', 'Error', 'center');
    },
  );

  const { postData: sendComment, loading: sendingComment } = usePost(
    SUPPORT_TICKETS_UPDATE,
    () => {
      SwalDialog('success', `Successfully sent the comments`, 'Success', 'center', () => {});
      refetch();
      onHide();
      window.location.reload();
    },
    () => {
      SwalDialog('error', 'Error occurred while sending the comments', 'Error', 'center');
    },
  );

  const handleStatusChange = option => {
    setSelectedStatus(option.value);
    updateStatus({
      ticket_id: ticket.id,
      status: option.value,
    });
  };
  const navigateToPDP = () => {
    if (ticket.vendor_product) {
      window.open(
        `/global-product-detail/${ticket.vendor_product.global_product.id}/` +
          `${ticket.vendor_product.id}/distributor`,
        '_blank',
      );
    } else {
      window.open(
        `/global-product-detail/${ticket.manufacturer_product.global_product.id}/` +
          `${ticket.manufacturer_product.id}/manufacturer`,
        '_blank',
      );
    }
  };
  return (
    <>
      <Modal show={true} onHide={onHide} size="xl" className="lato" centered>
        {(sendingComment || updatingStatus) && (
          <div className={classNames(css(styles.overlay))}>
            <LoadingSpinner />
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title className={classNames(css(styles.heading))}>
            {<strong>FSA - {ticket.id}</strong>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={classNames(css(styles.leftCol))}>
              <Row className={classNames(css(styles.task))}>
                <strong>{ticket.task}</strong>
              </Row>
              <Row className={classNames('ml-2', css(styles.descriptionRow))}>
                <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                  Description
                </strong>
                <div className={classNames(css(styles.subSectionDetails))}>
                  {ticket.details.description.split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </div>
              </Row>
              {images.length > 0 ? (
                <Row className="ml-2">
                  <Button variant="outline-primary" onClick={() => setShowAttachmentsModal(true)}>
                    View {images.length} Attachments
                  </Button>
                </Row>
              ) : null}
            </Col>
            <Col>
              <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                {ticket.global_vendor ? 'Distributor' : 'Manufacter'}
              </strong>
              <div className={classNames(css(styles.subSectionDetails))}>
                {ticket.global_vendor ? ticket.global_vendor.name : ticket.global_manufacturer.name}
              </div>
              <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                SKU
              </strong>
              <div
                className={classNames(css(styles.clickableText))}
                onClick={() => navigateToPDP()}
              >
                {ticket.vendor_product
                  ? ticket.vendor_product.vendor_sku
                  : ticket.manufacturer_product.manufacturer_sku}
              </div>
              <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                Item Name
              </strong>
              <div className={classNames(css(styles.subSectionDetails))}>
                {ticket.vendor_product
                  ? ticket.vendor_product.name
                  : ticket.manufacturer_product.name}
              </div>
              <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                Reported By
              </strong>
              <div className={classNames(css(styles.subSectionDetails))}>{ticket.reported_by}</div>
              <Form.Group>
                <Form.Label className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                  Status
                </Form.Label>
                <Select
                  className={classNames(css(styles.subSectionDetails))}
                  value={
                    SupportTicketStatusOptions.find(option => option.value === selectedStatus) ||
                    null
                  }
                  options={SupportTicketStatusOptions}
                  onChange={option => {
                    areYouSure(() => {
                      handleStatusChange(option);
                    }, 'Are you sure you want to change the status of the ticket');
                  }}
                />
              </Form.Group>
              {ticket.callback_url ? (
                <>
                  <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                    Comments
                  </strong>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      value={comments}
                      placeholder="Add a comments"
                      onChange={e => setComment(e.target.value)}
                      className="mb-3"
                      style={{ overflowY: 'auto' }}
                    />
                  </Form.Group>
                  {comments !== ticket.comments && comments && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        areYouSure(() => {
                          sendComment({ ticket_id: ticket.id, comments: comments });
                        }, 'Are you sure you want to send the comments for this ticket');
                      }}
                    >
                      Save comments
                    </Button>
                  )}
                </>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {showAttachmentsModal && (
        <ImagesDisplayModal images={images} onHide={() => setShowAttachmentsModal(false)} />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  task: {
    fontSize: '1.8rem',
    marginBottom: '1.2rem',
    borderBottom: '3px solid ' + backgroundGrey4,
  },
  subSectionsTitle: {
    fontSize: '1.2rem',
    marginBottom: '1.2rem',
  },
  subSectionDetails: {
    marginBottom: '1rem',
  },
  leftCol: {
    paddingLeft: '1.9rem',
    paddingRight: '1rem',
    borderRight: '3px solid ' + backgroundGrey4,
  },
  descriptionRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  clickableText: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: '1rem',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1050,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

SupportTicketsModal.propTypes = {
  ticket: PropTypes.object,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default SupportTicketsModal;
