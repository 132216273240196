import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import {
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  SKU_MAPPINGS_CREATE_MANUFACTURER_PRODUCTS,
} from 'lib/networking/endpoints';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import usePost from 'lib/hooks/usePost';

function CreateMfrProductsFromDistributorProductsModal({
  selectedProductsList,
  onHide,
  refetch,
  clusterId,
}) {
  const [productSelections, setProductSelections] = useState(
    selectedProductsList.map(product => ({
      vendorProductId: product.id,
      vendorProductName: product.name,
      manufacturerId: product.brand?.global_manufacturer?.id || '',
      manufacturerSku: '',
    })),
  );

  const hasProductsWithoutManufacturer = productSelections.some(product => !product.manufacturerId);

  const [initOptions, setInitOptions] = useState([]);

  useEffect(() => {
    const options = selectedProductsList.map(vendorproduct => {
      const brandManufacturer = vendorproduct.brand?.global_manufacturer;
      return brandManufacturer
        ? { label: brandManufacturer.name, value: brandManufacturer.id }
        : null;
    }); // Filter out null values

    setInitOptions(options);
  }, [selectedProductsList]);

  const { postData: createProducts, loading: creatingProducts } = usePost(
    SKU_MAPPINGS_CREATE_MANUFACTURER_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully created',
        title: 'Success',
      });
      onHide();
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const handleManufacturerChange = (vendorProductId, manufacturerId) => {
    setProductSelections(prevSelections =>
      prevSelections.map(product =>
        product.vendorProductId === vendorProductId ? { ...product, manufacturerId } : product,
      ),
    );
  };

  const handleSkuChange = (vendorProductId, manufacturerSku) => {
    setProductSelections(prevSelections =>
      prevSelections.map(product =>
        product.vendorProductId === vendorProductId ? { ...product, manufacturerSku } : product,
      ),
    );
  };

  const handleSubmit = () => {
    createProducts({
      product_details_array: productSelections,
      cluster_id: clusterId,
    });
  };
  return (
    <Modal animation={false} centered show={true} onHide={onHide} size="xl">
      <Modal.Header className="py-2 my-2" closeButton>
        <h5 className="font-weight-bold">Create Manufacturer Products</h5>
      </Modal.Header>
      <Modal.Body>
        {selectedProductsList.map((vendorproduct, index) => (
          <div key={vendorproduct.id} className="d-flex product-selection">
            <div className="col-4">
              <h6 className="mb-0 font-weight-bold">Product Name:</h6>
              {vendorproduct.name}
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="font-weight-bold">Manufacturer</label>
                <SearchableScrollableSelect
                  autoFocus={false}
                  initOption={initOptions[index]}
                  disabled={creatingProducts}
                  onChange={option => handleManufacturerChange(vendorproduct.id, option?.value)}
                  fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
                  optionMapper={manufacturer => ({
                    label: manufacturer.name,
                    value: manufacturer.id,
                  })}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="font-weight-bold">Manufacturer SKU</label>
                <input
                  type="text"
                  value={
                    productSelections.find(product => product.vendorProductId === vendorproduct.id)
                      .manufacturerSku
                  }
                  onChange={e => handleSkuChange(vendorproduct.id, e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={hasProductsWithoutManufacturer || creatingProducts}
        >
          Create Manufacturer Products
        </Button>
        <Button variant="primary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CreateMfrProductsFromDistributorProductsModal.propTypes = {
  selectedProductsList: PropTypes.array,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
  clusterId: PropTypes.number,
};

export default CreateMfrProductsFromDistributorProductsModal;
