import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Tab, Tabs } from 'react-bootstrap';
import ToggleSwitch from 'react-switch';

import CategoryFilters from 'components/l4_default_images/filters/CategoryFilters';
import { FILTERS_TAB } from 'components/l4_default_images/L4DefaultImageView';
import { grape } from 'lib/css/colors';
import ProductListFilters from 'components/l4_default_images/filters/ProductListFilters';
import RejectedImagesFilter from 'components/l4_default_images/filters/RejectedImagesFilter';

function FiltersTabView({
  l3Count,
  l4Count,
  l3WithImagesCount,
  l4WithImagesCount,
  l3CountForProductList,
  l4CountForProductList,
  l3WithImagesCountForProductList,
  l4WithImagesCountForProductList,
  l1Search,
  l2Search,
  l3Search,
  setL1Search,
  setL2Search,
  setL3Search,
  selectedL3CategoryName,
  setSelectedL3CategoryName,
  selectedL4CategoryName,
  setSelectedL4CategoryName,
  productList,
  setProductList,
  user,
  setUser,
  selectedL3CategoryId,
  setSelectedL3CategoryId,
  selectedL4CategoryId,
  setSelectedL4CategoryId,
  l1Options,
  l2Options,
  l3Options,
  l4Options,
  categoriesLoading,
  showUnassignedOnly,
  setShowUnassignedOnly,
  l3OptionsForProductList,
  loadingl3OptionsWithProductList,
  l4OptionsForProductList,
  loadingl4OptionsWithProductList,
  l4OptionsForUser,
  loadingl4OptionsForUser,
  activeTabKey,
  setActiveTabKey,
  optionsForL3,
  categoriesForL3Loading,
}) {
  return (
    <div>
      <div className="d-flex flex-column">
        <strong className={css(styles.countText)}>
          {l3WithImagesCount}/{l3Count} - L3 Assigned
        </strong>
        <strong className={css(styles.countText)}>
          {l4WithImagesCount}/{l4Count} - L4 Assigned
        </strong>
      </div>
      <hr className="mt-0" />
      <div className="my-4 d-flex align-items-center">
        Show Unassigned Categories Only
        <ToggleSwitch
          className="ml-4"
          onColor={grape}
          checked={showUnassignedOnly}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={e => setShowUnassignedOnly(e)}
          disabled={activeTabKey === FILTERS_TAB.REJECTED}
        />
      </div>
      <div>
        <Tabs activeKey={activeTabKey} onSelect={selectedKey => setActiveTabKey(selectedKey)}>
          <Tab eventKey={FILTERS_TAB.PRODUCT_LIST} title={FILTERS_TAB.PRODUCT_LIST}>
            <ProductListFilters
              l3CountForProductList={l3CountForProductList}
              l4CountForProductList={l4CountForProductList}
              l3WithImagesCountForProductList={l3WithImagesCountForProductList}
              l4WithImagesCountForProductList={l4WithImagesCountForProductList}
              productList={productList}
              setProductList={setProductList}
              selectedL3CategoryId={selectedL3CategoryId}
              setSelectedL3CategoryId={setSelectedL3CategoryId}
              setSelectedL3CategoryName={setSelectedL3CategoryName}
              l3OptionsForProductList={l3OptionsForProductList}
              loadingl3OptionsWithProductList={loadingl3OptionsWithProductList}
              selectedL4CategoryId={selectedL4CategoryId}
              setSelectedL4CategoryId={setSelectedL4CategoryId}
              setSelectedL4CategoryName={setSelectedL4CategoryName}
              l4OptionsForProductList={l4OptionsForProductList}
              loadingl4OptionsWithProductList={loadingl4OptionsWithProductList}
            />
          </Tab>
          <Tab eventKey={FILTERS_TAB.CATEGORIES} title={FILTERS_TAB.CATEGORIES}>
            <CategoryFilters
              l1Search={l1Search}
              l2Search={l2Search}
              l3Search={l3Search}
              setL1Search={setL1Search}
              setL2Search={setL2Search}
              setL3Search={setL3Search}
              selectedL3CategoryName={selectedL3CategoryName}
              setSelectedL3CategoryName={setSelectedL3CategoryName}
              setSelectedL3CategoryId={setSelectedL3CategoryId}
              selectedL4CategoryName={selectedL4CategoryName}
              setSelectedL4CategoryName={setSelectedL4CategoryName}
              setSelectedL4CategoryId={setSelectedL4CategoryId}
              l1Options={l1Options}
              l2Options={l2Options}
              l3Options={selectedL4CategoryId ? l3Options : optionsForL3.l3Options}
              l4Options={l4Options}
              loading={selectedL4CategoryId ? categoriesLoading : categoriesForL3Loading}
            />
          </Tab>
          <Tab eventKey={FILTERS_TAB.REJECTED} title={FILTERS_TAB.REJECTED}>
            <RejectedImagesFilter
              user={user}
              setUser={setUser}
              selectedL4CategoryId={selectedL4CategoryId}
              setSelectedL4CategoryId={setSelectedL4CategoryId}
              setSelectedL4CategoryName={setSelectedL4CategoryName}
              l4OptionsForUser={l4OptionsForUser}
              loadingl4OptionsForUser={loadingl4OptionsForUser}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '85vh',
  },
  countText: {
    fontSize: '0.9rem',
  },
  multilineTitle: {
    whiteSpace: 'pre-line',
  },
});

FiltersTabView.propTypes = {
  l3Count: PropTypes.number,
  l4Count: PropTypes.number,
  l3WithImagesCount: PropTypes.number,
  l4WithImagesCount: PropTypes.number,
  l3CountForProductList: PropTypes.number,
  l4CountForProductList: PropTypes.number,
  l3WithImagesCountForProductList: PropTypes.number,
  l4WithImagesCountForProductList: PropTypes.number,
  l1Search: PropTypes.string,
  l2Search: PropTypes.string,
  l3Search: PropTypes.string,
  setL1Search: PropTypes.func,
  setL2Search: PropTypes.func,
  setL3Search: PropTypes.func,
  selectedL3CategoryName: PropTypes.string,
  selectedL4CategoryName: PropTypes.string,
  setSelectedL4CategoryName: PropTypes.func,
  setSelectedL3CategoryName: PropTypes.func,
  selectedL3CategoryId: PropTypes.number,
  setSelectedL3CategoryId: PropTypes.func,
  selectedL4CategoryId: PropTypes.number,
  setSelectedL4CategoryId: PropTypes.func,
  l1Options: PropTypes.array,
  l2Options: PropTypes.array,
  l3Options: PropTypes.array,
  l4Options: PropTypes.array,
  categoriesLoading: PropTypes.bool,
  showUnassignedOnly: PropTypes.bool,
  setShowUnassignedOnly: PropTypes.func,
  productList: PropTypes.object,
  setProductList: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func,
  l4OptionsForProductList: PropTypes.array,
  loadingl4OptionsWithProductList: PropTypes.bool,
  l3OptionsForProductList: PropTypes.array,
  loadingl3OptionsWithProductList: PropTypes.bool,
  l4OptionsForUser: PropTypes.array,
  loadingl4OptionsForUser: PropTypes.bool,
  activeTabKey: PropTypes.string,
  setActiveTabKey: PropTypes.func,
  optionsForL3: PropTypes.object,
  categoriesForL3Loading: PropTypes.bool,
};

export default FiltersTabView;
