import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';

import { displayJsonSafeText } from 'lib/utils/displayJsonSafeText';
import {
  sectionTextBlackClass,
  sectionTextClass,
  tagContainerClass,
} from 'components/upb_viewer/css/sharedStyles';

function AttributesGroupedTagView({ attributes }) {
  if (!attributes.length) {
    return null;
  }

  const sortedAttributes = attributes.map(attribute => ({
    ...attribute,
    values: [...attribute.values].sort((a, b) => a.length - b.length),
  }));

  const style = sectionTextClass;

  return (
    <div>
      {sortedAttributes.map((attribute, idx) => (
        <Row key={idx} className="my-2">
          <Col sm={3} className={classNames(style, 'd-flex justify-content-start pl-3')}>
            {attribute.displayName}
          </Col>
          <Col className="d-flex flex-wrap">
            {attribute.values.map((tag, tagIdx) => (
              <div
                key={tagIdx}
                className={classNames(
                  sectionTextBlackClass,
                  tagContainerClass,
                  'd-flex py-1 px-2 mx-1 mb-1',
                )}
              >
                {displayJsonSafeText(tag)}
              </div>
            ))}
          </Col>
        </Row>
      ))}
    </div>
  );
}

AttributesGroupedTagView.propTypes = {
  attributes: PropTypes.array,
};

export default AttributesGroupedTagView;
