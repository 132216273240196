import { useMemo } from 'react';
import { TAXONOMY_CATEGORY_OPTIONS } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function useTaxonomyOptions(
  l0Search,
  l1Search,
  l2Search,
  l3Search,
  l4Search,
  forSearch = false,
  ignoreL4Search = true,
  showUnassignedOnly = false,
  ignoreL0Categories = null,
) {
  const {
    data: { categories } = {},
    loading,
    refetch,
  } = useGet(TAXONOMY_CATEGORY_OPTIONS, {
    l0_search: l0Search || '',
    l1_search: l1Search || '',
    l2_search: l2Search || '',
    l3_search: l3Search || '',
    l4_search: l4Search || '',
    ignore_l4_search: ignoreL4Search,
    show_unassigned_only: showUnassignedOnly,
    ignore_l0_categories: ignoreL0Categories,
  });
  const options = useMemo(() => {
    if (!categories) {
      return {
        l0Options: [],
        l1Options: [],
        l2Options: [],
        l3Options: [],
        l4Options: [],
      };
    }
    return {
      l0Options: categories.l0_categories.map(category => ({
        label: category.category_name,
        value: forSearch ? category.category_name : category.id,
        id: category.id,
        rosetta_id: category.category_id,
      })),
      l1Options: categories.l1_categories.map(category => ({
        label: category.category_name,
        value: forSearch ? category.category_name : category.id,
        id: category.id,
        rosetta_id: category.category_id,
      })),
      l2Options: categories.l2_categories.map(category => ({
        label: category.category_name,
        value: forSearch ? category.category_name : category.id,
        id: category.id,
        rosetta_id: category.category_id,
      })),
      l3Options: categories.l3_categories.map(category => ({
        label: category.category_name,
        value: forSearch ? category.category_name : category.id,
        id: category.id,
        rosetta_id: category.category_id,
      })),
      l4Options: categories.l4_categories.map(category => ({
        label: category.category_name,
        value: forSearch ? category.category_name : category.id,
        id: category.id,
        rosetta_id: category.category_id,
      })),
    };
  }, [categories, forSearch]);

  return { options, loading, refetch };
}

export default useTaxonomyOptions;
