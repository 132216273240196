import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Image, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { anchovy, lightPurple } from 'lib/css/colors';
import { IMAGE_RECOMMENDATIONS } from 'lib/networking/endpoints';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';

function SuggestionsL4View({ l4CategoryId, onImageClick, isImageSelected }) {
  const { data: { data: images = [] } = {}, loading } = useGet(IMAGE_RECOMMENDATIONS, {
    l4_category_id: l4CategoryId,
    limit: 24,
  });

  return (
    <div>
      <Row className="ml-2 font-weight-bold">L4 Suggestions</Row>
      {!loading && (
        <Row noGutters className="mr-4">
          {images.map((image, index) => (
            <Col md={2} key={index} className="px-1 my-1">
              <div
                className={classNames(
                  'h-100 mt-1',
                  pointerOnHover,
                  css(
                    styles.imageDiv,
                    isImageSelected(image?.url)
                      ? styles.selectedImageDiv
                      : styles.unselectedImageDiv,
                  ),
                )}
                onClick={() => onImageClick(image)}
              >
                <Image fluid src={image.url} className="h-100" />
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  imageDiv: {
    ':hover': {
      opacity: 0.6,
    },
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    height: 120,
    display: 'flex', // Add this
    alignItems: 'center', // Add this
    justifyContent: 'center',
  },
  unselectedImageDiv: {
    border: `1px solid ${anchovy}`,
    ':hover': {
      border: `3px solid ${anchovy}`,
    },
  },
  selectedImageDiv: {
    border: `4px solid ${lightPurple}`,
  },
});

SuggestionsL4View.propTypes = {
  l4CategoryId: PropTypes.number.isRequired,
  onImageClick: PropTypes.func,
  isImageSelected: PropTypes.func,
};

export default SuggestionsL4View;
