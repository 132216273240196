import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';

function FloatingButton({ text = 'Report', onClick }) {
  return (
    <Button onClick={onClick} className={classNames(css(styles.floatingButton))}>
      {text}
    </Button>
  );
}

const styles = StyleSheet.create({
  floatingButton: {
    position: 'fixed',
    top: '50%',
    right: '0',
    transform: 'translateY(-50%) rotate(-180deg)',
    writingMode: 'vertical-rl',
    direction: 'ltr',
    zIndex: 1050,
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    padding: '10px',
  },
});

FloatingButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default FloatingButton;
