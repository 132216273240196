import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav, Spinner } from 'react-bootstrap';

import { AuthContext } from 'components/providers/AuthContextProvider';
import { NAVIGATION_SIDE_BAR_WIDTH } from 'lib/constants';
import { SUPPORT_TICKET_OPEN_COUNT } from 'lib/networking/endpoints';
import { backgroundGrey3, deeplavender, greyscale } from 'lib/css/colors';
import useGet from 'lib/hooks/useGet';
function SideNavItem({ url, title, icon }) {
  const { logout } = useContext(AuthContext);

  const { data: { count } = {}, loading: countLoading } = useGet(
    SUPPORT_TICKET_OPEN_COUNT,
    {},
    title !== 'Support',
  );

  const handleClick = () => {
    if (title === 'Sign Out') {
      logout();
    }
  };

  return (
    <LinkContainer to={url} className={css(styles.link)} activeClassName={css(styles.activeLink)}>
      <Nav.Link onClick={handleClick}>
        <div className={css(styles.iconContainer)}>
          <FontAwesomeIcon size="lg" icon={icon} />
          {title === 'Support' && count > 0 ? (
            countLoading ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              <span className={css(styles.badge)}>{count}</span>
            )
          ) : null}
          <div className={css(styles.textWrapper)}>{title}</div>
        </div>
      </Nav.Link>
    </LinkContainer>
  );
}

const styles = StyleSheet.create({
  activeLink: {
    backgroundColor: backgroundGrey3,
    color: deeplavender,
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.4rem',
    color: greyscale,
    maxWidth: NAVIGATION_SIDE_BAR_WIDTH - 4,
  },
  textWrapper: {
    fontSize: '10px',
  },
  badge: {
    position: 'absolute',
    top: '-5px',
    right: '-10px',
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '50%',
    padding: '0.2rem 0.5rem',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '1.2rem',
    height: '1.2rem',
  },
});

SideNavItem.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.object,
  count: PropTypes.bool,
};

export default SideNavItem;
