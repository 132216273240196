import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { PRODUCT_LIST_FOR_DROPDOWNS } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';
// This is filter of all the associated distributor and manufacturer partners of cut+dry.
// This was required as normal product lists filters have other filters and are dynamic in their
// product count.
function PartnerProductListFilter({ productList, setProductList, titleRequired = false }) {
  const { data: { product_lists = [] } = [], loading: productListsLoading } = useGet(
    PRODUCT_LIST_FOR_DROPDOWNS,
  );

  const productListOptions = useMemo(() => {
    return product_lists.map((list, index) => ({ label: list.name, value: index }));
  }, [product_lists]);

  return (
    <div className="my-1">
      {titleRequired ? <Form.Label>Product List</Form.Label> : null}
      <Select
        className={css(styles.select)}
        isClearable
        options={productListOptions}
        value={productListOptions.find(option => option?.label === productList?.name)}
        onChange={option => {
          setProductList(product_lists[option?.value]);
        }}
        isDisabled={productListsLoading}
        placeholder={productListsLoading ? 'Loading' : 'Select a product list'}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  select: {
    zIndex: 2,
  },
});

PartnerProductListFilter.propTypes = {
  productList: PropTypes.object,
  setProductList: PropTypes.func,
  titleRequired: PropTypes.bool,
};

export default PartnerProductListFilter;
