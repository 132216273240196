import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Button, Col, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { anchovy, filterContainerBoarder } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import PrimaryBrandSection from 'components/manage_brands/PrimaryBrandSection';
import PrimaryBrandEditModal from './PrimaryBrandEditModal';
import UploadBrandLogoFromQAModal from 'components/manage_brands/UploadBrandLogoFromQAModal';
import pointerOnHover from 'lib/css/pointerOnHover';

function PrimaryBrandView({ brand, loading }) {
  const [brandData, setBrandData] = useState({
    brandID: null,
    brandName: null,
    logoUrl: null,
    manufacturerName: null,
    keywords: null,
    brandURL: null,
    description: null,
  });

  const [showPrimaryBrandEditModal, setShowPrimaryBrandEditModal] = useState(false);
  const [showLogoUploadModal, setShowLogoUploadModal] = useState(false);

  useEffect(() => {
    if (brand) {
      setBrandData({
        brandID: brand.id,
        brandName: brand.name,
        logoUrl: brand.logo_url,
        manufacturerName: brand.global_manufacturer?.name,
        manufacturerID: brand.global_manufacturer?.id,
        keywords: brand.keywords || [],
        brandURL: brand.brand_url,
        description: brand.description,
        lockedAttributes: brand.locked_attributes,
      });
    }
  }, [brand]);

  const primaryBrandSections = {
    Name: {
      value: brandData.brandName,
      label: 'Name',
    },
    Manufacturer: {
      value: brandData.manufacturerName,
      label: 'Manufacturer',
    },
    Keywords: {
      value: brandData.keywords,
      label: 'Keywords',
    },
    'Brand URL': {
      value: brandData.brandURL,
      label: 'Brand URL',
    },
    Description: {
      value: brandData.description,
      label: 'Description',
    },
  };
  return (
    <Col className={classNames(css(styles.filterContainer), 'my-2 p-3')}>
      {loading && (
        <div className={css(styles.spinnerOverlay)}>
          <LoadingSpinner />
        </div>
      )}
      <Row>
        <Col md={2}>
          <OverlayTrigger overlay={<Tooltip>Edit brand logo</Tooltip>}>
            <Image
              className={classNames(css(styles.square), pointerOnHover)}
              src={brandData.logoUrl || placeholderProductImage}
              alt="Brand Logo"
              onClick={() => setShowLogoUploadModal(true)}
            />
          </OverlayTrigger>
        </Col>
        <Col md={4}>
          {Object.keys(primaryBrandSections)
            .slice(0, 4)
            .map(key => (
              <PrimaryBrandSection
                key={key}
                label={primaryBrandSections[key].label}
                value={primaryBrandSections[key].value}
                onClick={primaryBrandSections[key].onClick}
                brandData={brandData}
              />
            ))}
          <Row className="d-flex justify-content-start ml-1 mt-4">
            <Button
              className={classNames(css(styles.highlightButton))}
              onClick={() => setShowPrimaryBrandEditModal(true)}
            >
              Edit
            </Button>
          </Row>
        </Col>
        <Col>
          {Object.keys(primaryBrandSections)
            .slice(-1)
            .map(key => (
              <PrimaryBrandSection
                key={key}
                label={primaryBrandSections[key].label}
                value={primaryBrandSections[key].value}
                onClick={primaryBrandSections[key].onClick}
                icon={primaryBrandSections[key].icon}
                brandData={brandData}
              />
            ))}
        </Col>
      </Row>
      {showLogoUploadModal && (
        <UploadBrandLogoFromQAModal
          brand={brand}
          onHide={() => setShowLogoUploadModal(false)}
          setBrandData={setBrandData}
        />
      )}
      {showPrimaryBrandEditModal && (
        <PrimaryBrandEditModal
          onHide={() => setShowPrimaryBrandEditModal(false)}
          brandData={brandData}
          setBrandData={setBrandData}
        />
      )}
    </Col>
  );
}

const styles = StyleSheet.create({
  square: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
    border: '2px solid ' + anchovy,
    marginBottom: '1rem',
    padding: '0.5rem',
  },
  filterContainer: {
    border: `3px solid ${filterContainerBoarder}`,
    marginLeft: '10px',
    marginRight: '10px',
    width: 'auto',
    minWidth: '300px',
    height: 'auto',
    minHeight: '240px',
    overflow: 'visible',
    position: 'relative',
  },
  spinnerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1,
  },
  highlightButton: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    border: '2px solid ' + anchovy,
    padding: '0 1.5rem',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    ':hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
    },
  },
});

PrimaryBrandView.propTypes = {
  brand: PropTypes.object,
  loading: PropTypes.bool,
};

export default PrimaryBrandView;
