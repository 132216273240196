import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Image, Media, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { areYouSure } from 'lib/utils/toast';
import { blackberry, white } from 'lib/css/colors';
import { BRAND_ATTRIBUTE_MAP } from 'lib/constants';
import {
  BRANDS_UPDATE_FROM_FSA,
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
} from 'lib/networking/endpoints';
import BrandContentQAIcon from 'components/manage_brands/BrandContentQAIcon';
import BrandLogoUploadModal from 'components/manage_brands/brand_details/BrandLogoUploadModal';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function BrandContentQAModal({ brand, onHide, refetch }) {
  const [brandData, setBrandData] = useState({
    name: brand.name,
    manufacturerID: brand.global_manufacturer?.id,
    manufacturerName: brand.global_manufacturer?.name,
    logoURL: brand.logo_url,
    description: brand.description,
    brandURL: brand.brand_url,
    keywords: brand.keywords,
  });
  const [initialBrandData, setInitialBrandData] = useState({});
  const [keywordsString, setKeywordsString] = useState((brandData.keywords || []).join(','));
  const [showBrandLogoModal, setShowBrandLogoModal] = useState(false);
  const [lockedAttributes, setLockedAttributes] = useState(brand.locked_attributes || []);

  useEffect(() => {
    setInitialBrandData({
      name: brand.name,
      manufacturerID: brand.global_manufacturer?.id,
      manufacturerName: brand.global_manufacturer?.name,
      logoURL: brand.logo_url,
      description: brand.description,
      brandURL: brand.brand_url,
      keywords: brand.keywords,
    });
  }, [brand]);

  const { postData: updateBrand } = usePost(
    BRANDS_UPDATE_FROM_FSA,
    () => {
      SwalDialog('success', 'Successfully Updated the Brand', 'Success', 'center');
      onHide();
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while Updating the Brand: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const handleLockAttributes = attribute => {
    if (lockedAttributes.includes(attribute)) {
      setLockedAttributes(prev => prev.filter(lockedAttribute => lockedAttribute !== attribute));
    } else {
      setLockedAttributes(prev => [...prev, attribute]);
    }
  };

  const handleUpdateBrand = () => {
    const changedAttributes = {};
    Object.keys(brandData).forEach(key => {
      if (brandData[key] !== initialBrandData[key]) {
        changedAttributes[BRAND_ATTRIBUTE_MAP[key]] = brandData[key];
      }
    });

    updateBrand({
      id: brand.id,
      locked_attributes: lockedAttributes,
      ...changedAttributes,
    });
  };
  return (
    <Modal show={true} onHide={onHide} size="xl" className="lato" centered>
      <Modal.Header closeButton>
        <Modal.Title className={classNames(css(styles.heading))}>Brand Content QA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mb-3">
          <Media
            className={!brand.locked_attributes.includes('logo_url') ? pointerOnHover : null}
            onClick={
              brand.locked_attributes.includes('logo_url')
                ? null
                : () => {
                    setShowBrandLogoModal(true);
                  }
            }
          >
            <Image
              className={css(styles.square)}
              src={brandData.logoURL || placeholderProductImage}
              alt="Brand Logo"
              width={200}
            />
          </Media>
          <div className="d-flex flex-column justify-content-end">
            <BrandContentQAIcon
              brand={brand}
              attribute="logo_url"
              lockedAttributes={lockedAttributes}
              handleLockAttributes={handleLockAttributes}
              fromContentQA={true}
            />
          </div>
        </div>
        {!brand.locked_attributes.includes('logo_url') && (
          <p className="small text-muted">* Click on image to change logo</p>
        )}
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">
              {'Brand name '}
              <BrandContentQAIcon
                brand={brand}
                attribute="name"
                lockedAttributes={lockedAttributes}
                handleLockAttributes={handleLockAttributes}
                fromContentQA={true}
              />
            </Form.Label>
            <Form.Control
              type="text"
              value={brandData.name}
              onChange={e => setBrandData(prev => ({ ...prev, name: e.target.value }))}
              className="mb-3"
              disabled={brand.locked_attributes.includes('name')}
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">
              {'Brand URL '}
              <BrandContentQAIcon
                brand={brand}
                attribute="brand_url"
                lockedAttributes={lockedAttributes}
                handleLockAttributes={handleLockAttributes}
                fromContentQA={true}
              />
            </Form.Label>
            <Form.Control
              type="text"
              value={brandData.brandURL}
              onChange={e => setBrandData(prev => ({ ...prev, brandURL: e.target.value }))}
              className="mb-3"
              disabled={brand.locked_attributes.includes('brand_url')}
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">
              {'Manufacturer '}
              <BrandContentQAIcon
                brand={brand}
                attribute="manufacturer_id"
                lockedAttributes={lockedAttributes}
                handleLockAttributes={handleLockAttributes}
                fromContentQA={true}
              />
            </Form.Label>
            <SearchableScrollableSelect
              initOption={{
                label: brandData.manufacturerName,
                value: brandData.manufacturerID,
              }}
              onChange={option =>
                setBrandData(Prev => ({
                  ...Prev,
                  manufacturerID: option?.value || null,
                  manufacturerName: option?.label || null,
                }))
              }
              fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
              optionMapper={manufacturer => ({
                label: manufacturer.name,
                value: manufacturer.id,
              })}
              className="mb-3"
              disabled={brand.locked_attributes.includes('manufacturer_id')}
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">
              {'Keywords '}
              <BrandContentQAIcon
                brand={brand}
                attribute="keywords"
                lockedAttributes={lockedAttributes}
                handleLockAttributes={handleLockAttributes}
                fromContentQA={true}
              />
            </Form.Label>
            <Form.Control
              type="text"
              value={keywordsString}
              onChange={e => {
                const inputValue = e.target.value;
                setKeywordsString(inputValue);
                setBrandData(prev => ({
                  ...prev,
                  keywords: inputValue
                    .split(',')
                    .map(word => word.trim())
                    .filter(Boolean),
                }));
              }}
              className="mb-3"
              disabled={brand.locked_attributes.includes('keywords')}
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">
              {'Description '}
              <BrandContentQAIcon
                brand={brand}
                attribute="description"
                lockedAttributes={lockedAttributes}
                handleLockAttributes={handleLockAttributes}
                fromContentQA={true}
              />
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={brandData.description}
              placeholder="Enter the new description"
              onChange={e => setBrandData(prev => ({ ...prev, description: e.target.value }))}
              className="mb-3"
              style={{ overflowY: 'auto' }}
              disabled={brand.locked_attributes.includes('description')}
            />
          </Form.Group>
        </div>
        {showBrandLogoModal && (
          <BrandLogoUploadModal
            brand={brand}
            updateBrand={newLogoURL => setBrandData(prev => ({ ...prev, logoURL: newLogoURL }))}
            onHide={() => {
              setShowBrandLogoModal(false);
            }}
            contentQA={true}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-start">
        <div>
          <Button
            variant="primary"
            onClick={() => {
              areYouSure(handleUpdateBrand, 'Are you sure want to save the changes?');
            }}
          >
            Update and lock attributes
          </Button>
          <Button variant="outline-primary" onClick={onHide} className="ml-2">
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    fontFamily: 'Mont, sans-serif',
    fontSize: '1.5rem',
  },
  updateButton: {
    backgroundColor: blackberry,
    borderColor: blackberry,
    color: white,
    fontWeight: 'bold',
  },
  section: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '8px',
    marginBottom: '10px',
  },
});

BrandContentQAModal.propTypes = {
  brand: PropTypes.object,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default BrandContentQAModal;
