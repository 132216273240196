import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { areYouSure } from 'lib/utils/toast';
import { blackberry, white } from 'lib/css/colors';
import BrandContentQAIcon from 'components/manage_brands/BrandContentQAIcon';
import {
  BRANDS_UPDATE_FROM_FSA,
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
} from 'lib/networking/endpoints';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import { SwalToast } from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function PrimaryBrandEditModal({ onHide, brandData, setBrandData }) {
  const [brandChanged, setBrandChanged] = useState(false);
  const [updatedBrandData, setUpdatedBrandData] = useState({
    manufacturerName: brandData.manufacturerName,
    manufacturerID: brandData.manufacturerID,
    keywords: brandData.keywords,
    brandURL: brandData.brandURL,
    description: brandData.description,
  });
  const [keywordsString, setKeywordsString] = useState(updatedBrandData.keywords.join(','));

  const { postData: updateBrand } = usePost(
    BRANDS_UPDATE_FROM_FSA,
    () => {
      SwalToast('success', `Brand updated successfully`);
    },
    () => SwalToast('error', `Failed to update the brand`),
  );

  const getChangedData = (updatedData, originalData) => {
    const changedData = {};
    const addChangeIfUpdated = (fieldKey, updatedValue, originalValue) => {
      if (updatedValue !== originalValue) {
        changedData[fieldKey] = updatedValue || null;
      }
    };

    addChangeIfUpdated('manufacturer_id', updatedData.manufacturerID, originalData.manufacturerID);
    addChangeIfUpdated('brand_url', updatedData.brandURL, originalData.brandURL);
    addChangeIfUpdated('description', updatedData.description, originalData.description);

    if (
      JSON.stringify(updatedData.keywords.sort()) !== JSON.stringify(originalData.keywords.sort())
    ) {
      changedData.keywords = updatedData.keywords;
    }

    return changedData;
  };

  useEffect(() => {
    const hasChanges = Object.keys(getChangedData(updatedBrandData, brandData)).length > 0;
    setBrandChanged(hasChanges);
  }, [updatedBrandData, brandData]);

  const handleSaveBrandData = () => {
    const changedBrandData = {
      id: brandData.brandID,
      from_qa: true,
      ...getChangedData(updatedBrandData, brandData),
    };

    setBrandData(prev => ({
      ...prev,
      manufacturerID: updatedBrandData.manufacturerID,
      manufacturerName: updatedBrandData.manufacturerName,
      brandURL: updatedBrandData.brandURL,
      description: updatedBrandData.description,
      keywords: [...updatedBrandData.keywords],
    }));
    updateBrand(changedBrandData);
    onHide();
  };

  return (
    <Modal show={true} onHide={onHide} size="xl" className="lato" centered>
      <Modal.Header closeButton>
        <Modal.Title className={classNames(css(styles.heading))}>Edit Brand Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 className="font-weight-bold mb-4">{brandData.brandName}</h2>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">
              {'Manufacturer '}
              {brandData.lockedAttributes.includes('manufacturer_id') && (
                <BrandContentQAIcon
                  brand={{ locked_attributes: brandData.lockedAttributes }}
                  attribute={'manufacturer_id'}
                />
              )}
            </Form.Label>
            <SearchableScrollableSelect
              initOption={{
                label: updatedBrandData.manufacturerName,
                value: updatedBrandData.manufacturerID,
              }}
              onChange={option =>
                setUpdatedBrandData(Prev => ({
                  ...Prev,
                  manufacturerID: option?.value,
                  manufacturerName: option?.label,
                }))
              }
              fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
              optionMapper={manufacturer => ({
                label: manufacturer.name,
                value: manufacturer.id,
              })}
              className="mb-3"
              disabled={brandData.lockedAttributes.includes('manufacturer_id')}
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">
              {'Keywords: '} {brandData.keywords.join(', ')}
              {brandData.lockedAttributes.includes('keywords') && (
                <BrandContentQAIcon
                  brand={{ locked_attributes: brandData.lockedAttributes }}
                  attribute={'keywords'}
                />
              )}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new keywords as comma-separated values"
              value={keywordsString}
              onChange={e => {
                const inputValue = e.target.value;
                setKeywordsString(inputValue);
                setUpdatedBrandData(prev => ({
                  ...prev,
                  keywords: inputValue
                    .split(',')
                    .map(word => word.trim())
                    .filter(Boolean),
                }));
              }}
              className="mb-3"
              disabled={brandData.lockedAttributes.includes('keywords')}
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">
              {'Brand URL '}
              {brandData.lockedAttributes.includes('brand_url') && (
                <BrandContentQAIcon
                  brand={{ locked_attributes: brandData.lockedAttributes }}
                  attribute={'brand_url'}
                />
              )}
            </Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                type="text"
                value={updatedBrandData.brandURL}
                placeholder="Enter the new URL"
                onChange={e => setUpdatedBrandData(prev => ({ ...prev, brandURL: e.target.value }))}
                className="mr-2"
                style={{ flex: 1 }}
              />
            </div>
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">
              {'Description '}
              {brandData.lockedAttributes.includes('description') && (
                <BrandContentQAIcon
                  brand={{ locked_attributes: brandData.lockedAttributes }}
                  attribute={'description'}
                />
              )}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={updatedBrandData.description}
              placeholder="Enter the new description"
              onChange={e =>
                setUpdatedBrandData(prev => ({ ...prev, description: e.target.value }))
              }
              className="mb-3"
              style={{ overflowY: 'auto' }}
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-start">
        <div>
          <Button
            variant="primary"
            onClick={() => {
              areYouSure(() => {
                handleSaveBrandData();
              }, 'Are you sure want to save the changes?');
            }}
            disabled={!brandChanged}
          >
            Save
          </Button>
          <Button variant="outline-primary" onClick={onHide} className="ml-2">
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    fontFamily: 'Mont, sans-serif',
    fontSize: '1.5rem',
  },
  updateButton: {
    backgroundColor: blackberry,
    borderColor: blackberry,
    color: white,
    fontWeight: 'bold',
  },
  section: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '8px',
    marginBottom: '10px',
    backgroundColor: '#f9f9f9',
  },
});

PrimaryBrandEditModal.propTypes = {
  onHide: PropTypes.func,
  brandData: PropTypes.object,
  setBrandData: PropTypes.func,
};

export default PrimaryBrandEditModal;
