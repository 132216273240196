import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleSwitch from 'react-switch';
import { useHistory } from 'react-router-dom';

import Container from 'components/shared/Container';
import {
  GET_SIMILAR_BRAND_NAME_INFO,
  UPDATE_SIMILAR_BRAND_DISPLAY_STATUS,
} from 'lib/networking/endpoints';
import { grape } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';
import PrimaryBrandView from 'components/manage_brands/PrimaryBrandView';
import SimilarBrandDetailsView from 'components/manage_brands/SimilarBrandDetailsView';
import useGet from 'lib/hooks/useGet';
import UserPermission from 'lib/enums/UserPermission';
import usePost from 'lib/hooks/usePost';
import useUserPermissions from 'lib/hooks/useUserPermissions';

function BrandQAV2() {
  const hasUserPermissions = useUserPermissions();
  const [showAISuggestions, setShowAISuggestions] = useState(false);
  const history = useHistory();
  const {
    data: { similar_brand = {}, products = [] } = {},
    refetch,
    loading,
  } = useGet(GET_SIMILAR_BRAND_NAME_INFO, { show_ai_suggestions: showAISuggestions });

  const { postData: updateDisplayStatus } = usePost(UPDATE_SIMILAR_BRAND_DISPLAY_STATUS, () => {
    history.push('/enrich-products/manage-brands');
  });
  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold mr-2">
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'mr-2')}
            icon={faArrowCircleLeft}
            onClick={() => {
              updateDisplayStatus({
                similar_brand_id: similar_brand.id,
                ai_suggestion: similar_brand.ai_suggestion,
              });
            }}
          />
          Verified Brands
        </h3>
        {hasUserPermissions([UserPermission.VIEW_BRAND_QA_AI_SUGGESTIONS]) && (
          <Col className={css(styles.toggleSwitch)}>
            Show AI Suggestions
            <ToggleSwitch
              className="ml-2"
              onColor={grape}
              checked={showAISuggestions}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={setShowAISuggestions}
            />
          </Col>
        )}
      </div>
      <Row>
        {similar_brand && (
          <PrimaryBrandView
            brand={similar_brand?.primary_brand || similar_brand?.verified_brand}
            loading={loading}
          />
        )}
      </Row>
      <Row className="d-flex justify-content-center">
        <SimilarBrandDetailsView
          similar_brand={similar_brand || {}}
          products={products}
          refetch={refetch}
          loading={loading}
        />
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  toggleSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    fontWeight: 'bold',
    marginTop: '3px',
    marginBottom: '1px',
  },
});

export default BrandQAV2;
