import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { grape } from 'lib/css/colors';
import ToggleSwitch from 'react-switch';

import BrandFilterGroup from 'components/codify_db/BrandFilterGroup';
import CollapsibleSection from 'components/upb_viewer/CollapsibleSection';
import { darkGrey2 } from 'lib/css/colors';
import { DEFAULT_ALL_CATEGORY_OPTION } from 'components/upb_viewer/UPBViewer';
import { getURL, TAXONOMY_UPB_VIEW_CATEGORY_OPTIONS } from 'lib/networking/endpoints';
import ManufacturerFilterGroup from 'components/codify_db/ManufacturerFilterGroup';
import NestedFiltersGroup from 'components/upb_viewer/NestedFiltersGroup';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

function CatalogSidePanel({
  selectedCategories,
  setSelectedCategories,
  selectedBrands,
  setSelectedBrands,
  selectedManufacturers,
  setSelectedManufacturers,
}) {
  const { data: { data: categories } = {}, loading } = useGet(
    getURL(TAXONOMY_UPB_VIEW_CATEGORY_OPTIONS, {}),
  );
  const categoryOptions = useMemo(() => {
    if (!categories?.length) {
      return [];
    }
    const catOptions = categories
      .map(category => ({
        label: category.category_name,
        value: category.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    catOptions.unshift(DEFAULT_ALL_CATEGORY_OPTION);
    return catOptions;
  }, [categories]);

  return (
    <div className={css(styles.container)}>
      <CollapsibleSection title="Category">
        {loading ? (
          <LoadingSpinner short />
        ) : (
          <div className={css(styles.filterGroupContainer)}>
            <NestedFiltersGroup
              options={categoryOptions}
              selectedOptions={selectedCategories}
              setSelectedOptions={setSelectedCategories}
            />
          </div>
        )}
      </CollapsibleSection>
      <CollapsibleSection title="Brand">
        <div className={css(styles.filterGroupContainer)}>
          <BrandFilterGroup selectedBrands={selectedBrands} setSelectedBrands={setSelectedBrands} />
        </div>
      </CollapsibleSection>
      <CollapsibleSection title="Manufacturer">
        <div className={css(styles.filterGroupContainer)}>
          <ManufacturerFilterGroup
            selectedManufacturers={selectedManufacturers}
            setSelectedManufacturers={setSelectedManufacturers}
          />
        </div>
      </CollapsibleSection>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 35,
    overflowY: 'auto',
    borderRight: '1px solid ' + darkGrey2,
  },
  filterGroupContainer: {
    maxHeight: '70vh',
    overflowY: 'auto',
  },
});

CatalogSidePanel.propTypes = {
  selectedCategories: PropTypes.arrayOf(PropTypes.object),
  setSelectedCategories: PropTypes.func,
  selectedBrands: PropTypes.arrayOf(PropTypes.object),
  setSelectedBrands: PropTypes.func,
  selectedManufacturers: PropTypes.arrayOf(PropTypes.object),
  setSelectedManufacturers: PropTypes.func,
};

export default CatalogSidePanel;
