import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorStyles } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';
import { sectionHeaderClass } from 'components/upb_viewer/css/sharedStyles';

function AttributeEditSectionHeader({ title, isExpanded, setIsExpanded }) {
  return (
    <div
      className={classNames(colorStyles.coolGrey, 'd-flex', pointerOnHover)}
      onClick={() => setIsExpanded(expanded => !expanded)}
    >
      <div className={sectionHeaderClass}>{title}</div>
      <div className="ml-auto align-self-center mr-2">
        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
      </div>
    </div>
  );
}

AttributeEditSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
};

export default AttributeEditSectionHeader;
