import React, { useState } from 'react';

import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import Select from 'react-select';
import Swal from 'sweetalert2';

import { filterContainerBoarder } from 'lib/css/colors';
import { areYouSure } from 'lib/utils/toast';
import {
  ALL_PRODUCT_LISTS,
  BRANDS_FOR_DROPDOWN,
  PRODUCT_LINKS_SUGGESTIONS_GET,
  PRODUCT_LINKS_SUGGESTIONS_HIDE_SUGGESTIONS,
  PRODUCT_LINKS_SUGGESTIONS_LINK_PRODUCTS,
} from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ProductLinksSuggestionsTableRow from 'components/global_products/global_products_view/ProductLinksSuggestionsTableRow';
import { ProductType } from 'lib/enums/ProductType';
import usePost from 'lib/hooks/usePost';
import useGet from 'lib/hooks/useGet';

function ProductsLinksSuggestionsView() {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedProductList, setSelectedProductList] = useState(null);
  const [selectedSuggestionsIds, setSelectedSuggestionsIds] = useState([]);

  const { data: { data } = [], brandsLoading } = useGet(BRANDS_FOR_DROPDOWN, {
    search: '',
    verified_only: true,
    product_list_id: selectedProductList,
  });

  const { data: { product_lists } = [], productListsLoading } = useGet(ALL_PRODUCT_LISTS, {});

  const {
    data: { suggestions, count, catalog_type } = {},
    loading: loading,
    refetch: refetch,
  } = useGet(PRODUCT_LINKS_SUGGESTIONS_GET, {
    brand_id: selectedBrand,
    product_list_id: selectedProductList,
    offset: currentPage - 1,
  });

  const { postData: linkProducts } = usePost(
    PRODUCT_LINKS_SUGGESTIONS_LINK_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'The selected products have been linked successfully',
        timer: 600,
      }).then(() => {
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred : ${error?.response?.data?.message}`,
      }),
  );

  const { postData: hideSuggestions } = usePost(
    PRODUCT_LINKS_SUGGESTIONS_HIDE_SUGGESTIONS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'The selected suggestions have been hidden successfully',
        timer: 600,
      }).then(() => {
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred : ${error?.response?.data?.message}`,
      }),
  );

  const brandOptions = [...(data?.map(brand => ({ label: brand.name, value: brand.id })) || [])];
  const productListsOptions = [
    ...(product_lists?.map(productList => ({ label: productList.name, value: productList.id })) ||
      []),
  ];

  const handleLinkProducts = () => {
    areYouSure(
      () =>
        linkProducts({
          suggestion_ids: selectedSuggestionsIds,
        }),
      'Are you sure you want to link the selected products?',
      'Link Products',
    );
  };

  const handleHideSuggestions = () => {
    areYouSure(
      () =>
        hideSuggestions({
          ids_to_hide: selectedSuggestionsIds,
        }),
      'Are you sure you want to hide the selected suggestions?',
      'Hide suggestions',
    );
  };
  return (
    <Container>
      <div className="mb-3 d-flex justify-content-left">
        <h3 className="font-weight-bold mr-2">Suggestive Product Links</h3>
      </div>
      <Row>
        <Form.Group as={Col} md={2}>
          <Form.Label>Product List</Form.Label>
          <Select
            value={productListsOptions?.find(option => option.value === selectedProductList)}
            options={productListsOptions}
            onChange={option => {
              setSelectedProductList(option ? option.value : null);
              setCurrentPage(1);
            }}
            isLoading={productListsLoading}
            isClearable
          />
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Form.Label>Brand</Form.Label>
          <Select
            value={brandOptions?.find(option => option.value === selectedBrand)}
            options={brandOptions}
            onChange={option => {
              setSelectedBrand(option ? option.value : null);
              setCurrentPage(1);
            }}
            isLoading={brandsLoading}
            isClearable
            isDisabled={!selectedProductList}
          />
        </Form.Group>
      </Row>
      {loading ? (
        <LoadingSpinner />
      ) : suggestions && suggestions.length > 0 ? (
        <>
          <Col className={classNames(css(styles.filterContainer), 'my-2 p-3')}>
            <Row>
              <Col>
                <Button
                  className={css(styles.button)}
                  variant="link"
                  size="lg"
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  &lt; Previous
                </Button>
                <Button
                  className={css(styles.button)}
                  variant="link"
                  size="lg"
                  disabled={currentPage === count}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next &gt;
                </Button>
                <span>{currentPage + '/' + count}</span>
              </Col>
              <Col className="d-flex justify-content-end align-content-center">
                <Button
                  className={css(styles.linkButton)}
                  variant="primary"
                  size="lg"
                  onClick={handleLinkProducts}
                >
                  Link products
                </Button>
                <Button
                  className={css(styles.hideSuggestionButton)}
                  variant="outline-primary"
                  size="lg"
                  onClick={handleHideSuggestions}
                >
                  Hide suggestion
                </Button>
              </Col>
            </Row>
            <Row>
              {suggestions && suggestions.length > 0 ? (
                catalog_type === ProductType.DISTRIBUTOR ? (
                  <Table className="ml-4 mr-4" striped bordered hover>
                    <thead>
                      <tr>
                        <th>Vendor SKU</th>
                        <th>Name</th>
                        <th>Brand</th>
                        <th>Manufacturer</th>
                        <th>Pack Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{suggestions[0].vendor_product.vendor_sku}</td>
                        <td>{suggestions[0].vendor_product.name}</td>
                        <td>{suggestions[0].vendor_product.brand.name}</td>
                        <td>{suggestions[0].vendor_product.manufacturer_name}</td>
                        <td>{suggestions[0].vendor_product.pack_size}</td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <Table className="ml-4 mr-4" striped bordered hover>
                    <thead>
                      <tr>
                        <th>Manufacturer SKU</th>
                        <th>Name</th>
                        <th>Brand</th>
                        <th>Manufacturer</th>
                        <th>Pack Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{suggestions[0].manufacturer_product.manufacturer_sku}</td>
                        <td>{suggestions[0].manufacturer_product.name}</td>
                        <td>{suggestions[0].manufacturer_product.brand.name}</td>
                        <td>{suggestions[0].manufacturer_product.global_manufacturer.name}</td>
                        <td>{suggestions[0].manufacturer_product.pack_size}</td>
                      </tr>
                    </tbody>
                  </Table>
                )
              ) : null}
            </Row>
          </Col>
          <Col className={classNames(css(styles.filterContainer), 'my-2 p-3')}>
            <h3 className="font-weight-bold" style={{ fontSize: '20px' }}>
              {catalog_type === 'distributor'
                ? 'Suggested Manufacturer Products'
                : 'Suggested Vendor products'}
            </h3>
            <Table striped bordered hover>
              {catalog_type == ProductType.DISTRIBUTOR ? (
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Thumbnail</th>
                    <th>Manufacturer</th>
                    <th>Manufacturer product code</th>
                    <th>Brand Name</th>
                    <th>Pack size</th>
                    <th>GTIN</th>
                    <th>Similarity score</th>
                  </tr>
                </thead>
              ) : (
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Thumbnail</th>
                    <th>Manufacturer</th>
                    <th>Manufacturer product code</th>
                    <th>Brand Name</th>
                    <th>Pack size</th>
                    <th>GTIN</th>
                    <th>Similarity score</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {suggestions &&
                  suggestions.map(suggestion => (
                    <ProductLinksSuggestionsTableRow
                      key={suggestion.id}
                      suggestion={suggestion}
                      selectedSuggestionsIds={selectedSuggestionsIds}
                      setSelectedSuggestionsIds={setSelectedSuggestionsIds}
                      catalog_type={catalog_type}
                    />
                  ))}
              </tbody>
            </Table>
          </Col>
        </>
      ) : (
        'No products to display'
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  filterContainer: {
    border: `3px solid ${filterContainerBoarder}`,
    marginLeft: '1px',
    marginRight: '1px',
    width: 'auto',
    minWidth: '100px',
    height: 'auto',
    minHeight: '70px',
    overflow: 'visible',
    position: 'relative',
  },
  button: {
    fontSize: '1.2em',
    padding: '0.5em',
    fontWeight: 'bold',
  },
  linkButton: {
    width: '100px',
    maxHeight: '30px',
    marginLeft: '4px',
  },
  hideSuggestionButton: {
    width: '150px',
    maxHeight: '30px',
    marginLeft: '4px',
  },
});

export default ProductsLinksSuggestionsView;
