import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Form, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { SUPPORT_TICKET } from 'lib/networking/endpoints';

import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SupportTicketsTableRow from 'components/settings/support_tickets/SupportTicketsTableRow';
import { SupportTicketStatus, SupportTicketStatusOptions } from 'lib/enums/SupportTicketEnums';
import useGet from 'lib/hooks/useGet';

function SupportTicketsView() {
  const [selectedStatus, setSelectedStatus] = useState(SupportTicketStatus.OPEN);
  const {
    data: { tickets } = {},
    refetch: refetch,
    loading: loading,
  } = useGet(SUPPORT_TICKET, { status: selectedStatus });
  return (
    <Container>
      <Row className="ml-2">
        <div className="mb-3 d-flex justify-content-between">
          <h3 className="font-weight-bold">Support Tickets</h3>
        </div>
      </Row>
      <Row className="ml-2">
        <Form.Group>
          <Form.Label>Status</Form.Label>
          <div className={css(styles.selectContainer)}>
            <Select
              isClearable
              value={
                SupportTicketStatusOptions.find(option => option.value === selectedStatus) || null
              }
              options={SupportTicketStatusOptions}
              onChange={option => {
                setSelectedStatus(option?.value || null);
              }}
            />
          </div>
        </Form.Group>
      </Row>
      <Row className="ml-2 mr-2">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Task</th>
                <th>Entity Type</th>
                <th>Entity Name</th>
                <th>Status</th>
                <th>Age</th>
              </tr>
            </thead>
            <tbody>
              {tickets?.map(ticket => (
                <SupportTicketsTableRow key={ticket.id} ticket={ticket} refetch={refetch} />
              ))}
            </tbody>
          </Table>
        )}
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  selectContainer: {
    width: '300px',
  },
});

export default SupportTicketsView;
