import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

import ActionDropDown from 'components/shared/ActionDropDown';
import { areYouSure } from 'lib/utils/toast';
import Container from 'components/shared/Container';
import {
  DATA_SOURCE_ASSIGN_SKUS,
  DATA_SOURCE_GET_BY_ID,
  DATA_SOURCE_LAST_RUN_DATA,
  DATA_SOURCE_RUN_DATA,
  DATA_SOURCE_PROCESS,
  getURL,
} from 'lib/networking/endpoints';
import DATA_SOURCE_CATEGORY from 'lib/enums/DataSourceCategory';
import { DATA_SOURCES_VIEW_TABS } from 'lib/constants';
import DataSourceSummaryTab from 'components/data_sources/single_data_source_view/DataSourceSummaryTab';
import DataSourceProductsTab from 'components/data_sources/single_data_source_view/DataSourceProductsTab';
import DataSourceAttributesTab from 'components/data_sources/single_data_source_view/DataSourceAttributesTab';
import DataSourceSkuMappingsTab from 'components/data_sources/single_data_source_view/DataSourceSkuMappingsTab';
import DataSourceEditTab from 'components/data_sources/single_data_source_view/DataSourceEditTab';
import GDSNDataSourceManufacturersTab from './GDSNDataSourceManufacturersTab';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import SingleDataSourceRunDataTable from 'components/data_sources/single_data_source_view/SingleDataSourceRunDataTable';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import { DATA_SOURCE_IMPORT_TYPES } from 'lib/enums/DataSourceEntity';

function SingleDataSourceView() {
  const { record_id } = useParams();
  const history = useHistory();

  const {
    data: dataSource = {},
    loading,
    error,
    refetch,
  } = useGet(getURL(DATA_SOURCE_GET_BY_ID, { data_source_id: record_id }));

  const {
    data: dataSourceRun = {},
    loading: loadingRun,
    refetch: refetchRun,
  } = useGet(
    DATA_SOURCE_LAST_RUN_DATA,
    {
      data_source_id: record_id,
    },
    !record_id,
  );

  const { data: dataSourceRuns = [], loading: loadingRunData } = useGet(
    DATA_SOURCE_RUN_DATA,
    {
      data_source_id: record_id,
    },
    !record_id,
  );

  useEffect(() => {
    if (dataSourceRun && dataSourceRun.run_metadata) {
      dataSourceRun.run_metadata.retries = dataSourceRun.retries || 0;
    }
    if (dataSourceRun && dataSourceRun.in_progress) {
      const interval = setInterval(() => {
        refetchRun();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [dataSourceRun, refetchRun]);

  const { postData: processDataSource, loading: processing } = usePost(DATA_SOURCE_PROCESS);
  const { postData: assignUPBSKUs, loading: assigningUPBSKUs } = usePost(
    DATA_SOURCE_ASSIGN_SKUS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Started assigning UPB SKUs',
        title: 'Success',
        timer: 500,
      }).then(() => {
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred while assigning UPB SKUs: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  const [activeTabKey, setActiveTabKey] = useState();

  useEffect(() => {
    if (dataSource?.import_type) {
      setActiveTabKey(
        dataSource?.import_type === DATA_SOURCE_IMPORT_TYPES.ATTRIBUTE_IMPORT
          ? DATA_SOURCES_VIEW_TABS.ATTRIBUTES
          : dataSource?.import_type === DATA_SOURCE_IMPORT_TYPES.SKU_MAPPING_IMPORT
          ? DATA_SOURCES_VIEW_TABS.SKU_MAPPINGS
          : DATA_SOURCES_VIEW_TABS.PRODUCTS,
      );
    }
  }, [dataSource]);

  const runInProgress = useMemo(
    () => loadingRun || (dataSourceRun && dataSourceRun.in_progress),
    [loadingRun, dataSourceRun],
  );

  const gdsnDataSourceWithoutManufacturer = useMemo(() => {
    return (
      dataSource.source_type === DATA_SOURCE_CATEGORY.GDSN &&
      !dataSource.global_manufacturer &&
      !dataSource.global_vendor
    );
  }, [dataSource]);

  const assigningSKUs = dataSource?.upb_sku_status;

  if (loading || loadingRunData) {
    return <LoadingSpinner />;
  }

  if (error) {
    return null;
  }

  if (!dataSource) {
    return <h3 className="my-5 text-center">Data Source not found</h3>;
  }

  function handleProcessNow(deltaFile = false) {
    processDataSource({ data_source_id: record_id, process_delta_file: deltaFile }).then(() => {
      setTimeout(() => {
        refetchRun();
      }, 1000);
    });
  }

  return (
    <Container>
      <Row className="mr-4">
        <Col>
          <h3 className="font-weight-bold">
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'mr-2')}
              icon={faArrowCircleLeft}
              onClick={() => history.goBack()}
            />
            {dataSource.source_name}
          </h3>
        </Col>
        <Col className="ml-auto col-auto">
          <Row className="d-flex flex-column">
            <Col className="text-right d-flex justify-content-end">
              {dataSource && dataSource.assign_upb_skus && (
                <Button
                  variant="outline-primary"
                  disabled={runInProgress || processing || assigningSKUs || assigningUPBSKUs}
                  onClick={() => assignUPBSKUs({ data_source_id: record_id })}
                >
                  {assigningSKUs ? 'assigning SKUs, please wait' : 'Assign UPB SKUs'}
                </Button>
              )}
              {dataSource.is_recurring ? (
                <ActionDropDown
                  className="ml-2"
                  submenuItems={[
                    {
                      title: 'Process the delta file',
                      action: () => handleProcessNow(true),
                    },
                    {
                      title: 'Process the entire file',
                      action: () => handleProcessNow(false),
                    },
                  ]}
                  label={runInProgress ? 'Run in progress, please wait' : 'Process Now'}
                />
              ) : (
                <Button
                  variant="outline-primary"
                  disabled={runInProgress || processing || assigningSKUs}
                  className="ml-2"
                  onClick={() => handleProcessNow(false)}
                >
                  {runInProgress ? 'Run in progress, please wait' : 'Process Now'}
                </Button>
              )}
              <OverlayTrigger overlay={<Tooltip>Forcefully resume/restart this process</Tooltip>}>
                <Button
                  disabled={processing || !runInProgress}
                  className="ml-2"
                  variant="danger"
                  onClick={() =>
                    areYouSure(
                      () =>
                        processDataSource({
                          data_source_id: record_id,
                          forcefully_resume: true,
                        }).then(() => {
                          setTimeout(() => {
                            refetchRun();
                          }, 1000);
                        }),
                      'This will forcefully restart/resume the process. ' +
                        "This is not recommended unless you know what you're doing.",
                    )
                  }
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </OverlayTrigger>
            </Col>
            {dataSourceRun?.run_metadata && (
              <Col className="text-right mt-2">
                <div className={classNames(css(styles.smallText), 'text-muted')}>
                  {JSON.stringify(dataSourceRun.run_metadata, null, 2)}
                  {loadingRun && (
                    <span className={classNames(css(styles.smallText), 'text-muted ml-2')}>
                      Loading...
                    </span>
                  )}
                </div>
              </Col>
            )}

            {dataSourceRun?.error_report_url && (
              <Col className="text-right mt-2">
                <div className={classNames(css(styles.smallText), 'text-muted')}>
                  <a
                    href={dataSourceRun.error_report_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Error Report
                  </a>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Tabs
        className="mt-5 border-top-0"
        defaultActiveKey={activeTabKey}
        onSelect={selectedKey => setActiveTabKey(selectedKey)}
        transition={false}
        mountOnEnter={true}
      >
        {dataSource.import_type === DATA_SOURCE_IMPORT_TYPES.ATTRIBUTE_IMPORT ? (
          <Tab
            eventKey={DATA_SOURCES_VIEW_TABS.ATTRIBUTES}
            title={DATA_SOURCES_VIEW_TABS.ATTRIBUTES}
          >
            <DataSourceAttributesTab dataSource={dataSource} refetch={refetch} />
          </Tab>
        ) : dataSource.import_type === DATA_SOURCE_IMPORT_TYPES.SKU_MAPPING_IMPORT ? (
          <Tab
            eventKey={DATA_SOURCES_VIEW_TABS.SKU_MAPPINGS}
            title={DATA_SOURCES_VIEW_TABS.SKU_MAPPINGS}
          >
            <DataSourceSkuMappingsTab dataSource={dataSource} refetch={refetch} />
          </Tab>
        ) : null}
        <Tab eventKey={DATA_SOURCES_VIEW_TABS.PRODUCTS} title={DATA_SOURCES_VIEW_TABS.PRODUCTS}>
          {gdsnDataSourceWithoutManufacturer ? (
            <GDSNDataSourceManufacturersTab dataSource={dataSource} />
          ) : (
            <DataSourceProductsTab dataSource={dataSource} />
          )}
        </Tab>
        <Tab eventKey={DATA_SOURCES_VIEW_TABS.SUMMARY} title={DATA_SOURCES_VIEW_TABS.SUMMARY}>
          <DataSourceSummaryTab dataSource={dataSource} dataSourceRun={dataSourceRun} />
        </Tab>
        <Tab eventKey={DATA_SOURCES_VIEW_TABS.SETTINGS} title={DATA_SOURCES_VIEW_TABS.SETTINGS}>
          <DataSourceEditTab dataSource={dataSource} />
        </Tab>
        <Tab
          eventKey={DATA_SOURCES_VIEW_TABS.PROCESS_LOGS}
          title={DATA_SOURCES_VIEW_TABS.PROCESS_LOGS}
        >
          <SingleDataSourceRunDataTable dataSourceRuns={dataSourceRuns} />
        </Tab>
      </Tabs>
    </Container>
  );
}

const styles = StyleSheet.create({
  smallText: {
    fontSize: '0.8rem',
  },
});

DataSourceSummaryTab.propTypes = {
  dataSource: PropTypes.object,
};

export default SingleDataSourceView;
