import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Badge, Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { constructUrlPathSegment } from 'components/upb_viewer/UPBViewer';
import { grey, white } from 'lib/css/colors';
import UPBCategoryCard from 'components/upb_viewer/UPBCategoryCard';

function UPBAllCategoriesView({ categories, refetch, categoryView = false }) {
  const transformOptions = options => {
    if (!options) return options;
    return options.map(option => {
      return {
        ...option,
        taxonomy_display_name: option.taxonomy_display_name || option.category_name,
      };
    });
  };

  const sortedCategories = useMemo(() => {
    if (!categories?.length) {
      return [];
    }
    const catOptions = transformOptions(categories).sort((a, b) =>
      a.taxonomy_display_name.localeCompare(b.taxonomy_display_name),
    );
    return catOptions;
    // eslint-disable-next-line
  }, [categories]);

  const categoryCardView = cat => {
    return {
      id: cat.id,
      category_name: cat.taxonomy_display_name || cat.category_name,
      icon_image_url: cat.taxonomy_image,
      taxonomy_level: cat.taxonomy_level,
    };
  };

  const categoryLink = (cat, subCat) => {
    return `${location.pathname}/${constructUrlPathSegment({
      value: cat.id,
      label: cat.taxonomy_display_name || cat.category_name,
    })}/${constructUrlPathSegment({
      value: subCat.id,
      label: subCat.taxonomy_display_name || subCat.category_name,
    })}`;
  };
  return (
    <div
      className={classNames(css(styles.container), 'd-flex flex-wrap justify-content-left ml-3')}
    >
      {sortedCategories?.map(cat => (
        <Row key={cat.id} className="mb-4 w-100">
          <Col>
            <Row>
              <Col>
                <div className="d-flex align-items-center">
                  <h5 className={classNames(css(styles.h5), 'mt-1 pt-1')}>
                    {cat.taxonomy_display_name || cat.category_name}
                  </h5>
                  <Badge
                    className={classNames(css(styles.categoryBadge), 'ml-2 px-2 mt-0')}
                    variant="secondary"
                  >
                    L{cat.taxonomy_level}
                  </Badge>
                </div>
              </Col>
            </Row>
            <Row>
              {cat.sub_options?.map(subCat => (
                <UPBCategoryCard
                  key={subCat.id}
                  category={categoryCardView(subCat)}
                  categoryLink={categoryView ? categoryLink(cat, subCat) : null}
                  refetch={refetch}
                  showEditIcon={true}
                />
              ))}
            </Row>
          </Col>
        </Row>
      ))}
    </div>
  );
}
const styles = StyleSheet.create({
  container: {
    maxHeight: 500,
    minHeight: 100,
  },
  h5: {
    fontWeight: '550',
  },
  categoryBadge: {
    borderRadius: 10,
    fontSize: '0.75rem',
    fontWeight: 550,
    backgroundColor: grey,
    color: white,
  },
});

UPBAllCategoriesView.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  categoryView: PropTypes.bool,
  refetch: PropTypes.func,
  showSubOptions: PropTypes.bool,
};

export default UPBAllCategoriesView;
