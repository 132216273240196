import React from 'react';
import PropTypes from 'prop-types';

import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { blue, grey, salmon, squidInk } from 'lib/css/colors';

function BrandContentQAIcon({
  brand,
  attribute,
  lockedAttributes = [],
  handleLockAttributes = () => {},
  fromContentQA = false,
}) {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip>
          {brand.locked_attributes.includes(attribute) || lockedAttributes.includes(attribute)
            ? 'Locked attribute'
            : 'Click to lock this attribute'}
        </Tooltip>
      }
    >
      <FontAwesomeIcon
        icon={faLock}
        size="lg"
        className="mr-3"
        color={
          !fromContentQA
            ? squidInk
            : brand.locked_attributes.includes(attribute)
            ? salmon
            : lockedAttributes.includes(attribute)
            ? blue
            : grey
        }
        onClick={
          brand.locked_attributes.includes(attribute)
            ? () => {}
            : () => handleLockAttributes(attribute)
        }
      />
    </OverlayTrigger>
  );
}

BrandContentQAIcon.propTypes = {
  brand: PropTypes.object,
  attribute: PropTypes.string,
  lockedAttributes: PropTypes.array,
  handleLockAttributes: PropTypes.func,
  fromContentQA: PropTypes.bool,
};

export default BrandContentQAIcon;
