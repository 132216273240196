import React, { useState } from 'react';
import PropTypes from 'prop-types';

import pointerOnHover from 'lib/css/pointerOnHover';
import SupportTicketsModal from 'components/settings/support_tickets/SupportTicketsModal';
import { SupportTicketStatusLabels } from 'lib/enums/SupportTicketEnums';

function calculateAge(dateString) {
  const createdDate = new Date(dateString);
  const now = new Date();
  const diffMs = now - createdDate;
  const diffMinutes = Math.floor(diffMs / (1000 * 60));

  if (diffMinutes < 60) {
    return `${diffMinutes}m`;
  }
  const diffHours = Math.floor(diffMinutes / 60);
  if (diffHours < 24) {
    return `${diffHours}h`;
  }
  const diffDays = Math.floor(diffHours / 24);
  if (diffDays < 7) {
    return `${diffDays}d`;
  }
  const diffWeeks = Math.floor(diffDays / 7);
  if (diffWeeks < 5) {
    return `${diffWeeks}w`;
  }
  const diffMonths = Math.floor(diffDays / 30);
  if (diffMonths < 12) {
    return `${diffMonths}mo`;
  }
  const diffYears = Math.floor(diffMonths / 12);
  return `${diffYears}year${diffYears > 1 ? 's' : ''}`;
}
function SupportTicketsTableRow({ ticket, refetch }) {
  const [showSupportTicketsModal, setShowSupportTicketsModal] = useState(false);
  return (
    <>
      <tr onClick={() => setShowSupportTicketsModal(true)} className={pointerOnHover}>
        <td>{<strong>FSA - {ticket.id} </strong>}</td>
        <td>{ticket.task}</td>
        <td>{ticket.global_vendor ? 'Distributor' : 'Manufacturer'}</td>
        <td>
          {ticket.global_vendor ? ticket.global_vendor.name : ticket.global_manufacturer.name}
        </td>
        <td>{SupportTicketStatusLabels[ticket.status]}</td>
        <td>{calculateAge(ticket.date_created)}</td>
      </tr>
      {showSupportTicketsModal && (
        <SupportTicketsModal
          ticket={ticket}
          onHide={() => setShowSupportTicketsModal(false)}
          refetch={refetch}
        />
      )}
    </>
  );
}

SupportTicketsTableRow.propTypes = {
  ticket: PropTypes.object,
  refetch: PropTypes.func,
};

export default SupportTicketsTableRow;
