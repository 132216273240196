import React from 'react';
import PropTypes from 'prop-types';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { convertToTitleCase } from 'lib/utils/stringUtils';
import { grape } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';
import ProductOutlierChangeTypes from 'lib/enums/ProductOutlierChangeTypeEnum';
import VerificationStatusIcon from 'components/global_products/detail_view/VerificationStatusIcon';

function ProductOutliersQATableRow({ product, rejectingChanges, setShowFeedBackModalSettings }) {
  const getRowClass = changeType => {
    switch (changeType) {
      case ProductOutlierChangeTypes.VERIFY_PRODUCTS:
        return 'text-success'; // Green for "VERIFY_PRODUCTS"
      case ProductOutlierChangeTypes.UNVERIFY_PRODUCTS:
        return 'text-success'; // Green for "VERIFY_PRODUCTS"
      case ProductOutlierChangeTypes.UNLINK_PRODUCTS:
        return 'text-warning'; // Yellow/Orange for "UNLINK_PRODUCTS"
      case ProductOutlierChangeTypes.CREATE_NEW_PRODUCT_GROUP:
        return 'text-warning'; // Yellow/Orange for "CREATE_NEW_PRODUCT_GROUP"
      case ProductOutlierChangeTypes.DELETE_PRODUCTS:
        return 'text-danger'; // Red for "DELETE_PRODUCTS"
      default:
        return 'text-dark'; // Black for other types
    }
  };

  const getDisplayChangeType = (changeType, qaRejected = false, qaApproved = false) => {
    let displayChangeType = convertToTitleCase(changeType.replace(/_/g, ' '));
    if (qaRejected) {
      displayChangeType = displayChangeType + ' (QA-Rejected)';
    } else if (qaApproved) {
      displayChangeType = displayChangeType + ' (QA-Approved)';
    }
    return displayChangeType;
  };

  return (
    <>
      <tr className={!product.qa_done && getRowClass(product.change_type)}>
        <td>
          {product.change_type
            ? getDisplayChangeType(product.change_type, product.qa_rejected, product.qa_approved)
            : '-'}
        </td>
        <td>{product.entity_type}</td>
        <td>{product.entity_name}</td>
        <td className={css(styles.lineBreakCell)}>{product.sku}</td>
        <td>
          <OverlayTrigger placement="top" overlay={<Tooltip>{product.name}</Tooltip>}>
            <span className={css(styles.nameCell)}>{product.name}</span>
          </OverlayTrigger>
        </td>
        <td>{product.core_11 || '-'}</td>
        <td>{product.pack_size || '-'}</td>
        <td>{product.brand_name || '-'}</td>
        <td>{product.change_done_by || '-'}</td>
        <td className={css(styles.lineBreakCell)}>{product.qa_done_by || '-'}</td>
        <td className="text-center">
          <VerificationStatusIcon
            verificationStatus={product.verification_status}
            aiSuggestedVerification={product.ai_suggested_verification}
          />
        </td>
        <td className="d-flex">
          {product.global_product_id && product.id && (
            <Button
              variant="link"
              className={classNames(css(styles.button), pointerOnHover)}
              onClick={() => {
                window.open(
                  `/global-product-detail/${product.global_product_id}/` +
                    `${product.id}/${product.entity_type.toLowerCase()}`,
                  '_blank',
                );
              }}
            >
              View
            </Button>
          )}
        </td>
        <td className="flex-column">
          <div>
            {!product.qa_done && product.change_type && (
              <Button
                variant="link"
                disabled={rejectingChanges}
                onClick={() => setShowFeedBackModalSettings({ show: true, product: product })}
              >
                Reject Changes
              </Button>
            )}
          </div>
        </td>
      </tr>
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    color: grape,
  },
  nameCell: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    maxWidth: '250px',
    display: 'block',
  },
  lineBreakCell: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    maxWidth: '120px',
    display: 'block',
  },
});

ProductOutliersQATableRow.propTypes = {
  product: PropTypes.object,
  rejectingChanges: PropTypes.bool,
  setShowFeedBackModalSettings: PropTypes.func,
};

export default ProductOutliersQATableRow;
