import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button, Spinner } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ANNUALIZED_GMV } from 'lib/networking/endpoints';
import BrandDetailsSection from 'components/upb_viewer/pdp_components/BrandDetailsSection';
import { PRODUCT_DETAIL_VIEW_TABS } from 'lib/constants';
import { ProductType } from 'lib/enums/ProductType';
import { squidInk } from 'lib/css/colors';
import useGet from 'lib/hooks/useGet';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

function ProductHeaderSection({ product, productType, showGMV = false }) {
  const hasUserPermissions = useUserPermissions();
  // eslint-disable-next-line max-len
  const productEditLink = `/global-product-detail/${product.global_product_id}/${product.id}/${productType}`;
  const editLinkWithTab = productEditLink + `?tab=${PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES}`;
  const canEditProduct = useMemo(
    () => hasUserPermissions([UserPermission.EDIT_PRODUCT]),
    [hasUserPermissions],
  );
  const viewGMV = useMemo(
    () => hasUserPermissions([UserPermission.UPB_VIEW_ADMIN]),
    [hasUserPermissions],
  );
  const SKU = product.manufacturer_sku || product.vendor_sku;

  const { data, loading: GMVLoading } = useGet(
    ANNUALIZED_GMV,
    {
      _id: product.id,
      category_level: productType === ProductType.GLOBAL ? 'global' : 'product',
    },
    !product.id || !showGMV || !viewGMV,
  );

  // Conditionally include SKU in subtitleText only if productType is not "global"
  const subtitleTextParts = [product.pack_size];
  if (productType !== ProductType.GLOBAL && SKU) {
    subtitleTextParts.push(`SKU# ${SKU}`);
  }

  const subtitleText = subtitleTextParts.filter(item => item).join(' | ');

  return (
    <>
      <div className={css(styles.productName)}>{product.name}</div>
      <BrandDetailsSection brand={product.brand} manufacturer={product.manufacturer} />
      <div className="text-muted  mb-2">{subtitleText}</div>
      {canEditProduct && !showGMV && (
        <div>
          <Button
            variant="outline-primary"
            target="_blank"
            className={css(styles.editButton)}
            href={editLinkWithTab}
          >
            <FontAwesomeIcon icon={faEdit} className={css(styles.editIcon)} />
            Edit
          </Button>
        </div>
      )}
      {showGMV && viewGMV && (
        <span>
          Annualized Contract GMV : $
          {GMVLoading ? (
            <Spinner animation="border" role="status" size="sm" className="ml-4" />
          ) : data?.data ? (
            Number(data.data.toFixed(2)).toLocaleString('en-US')
          ) : (
            'N/A'
          )}
        </span>
      )}
    </>
  );
}

ProductHeaderSection.propTypes = {
  product: PropTypes.object,
  productType: PropTypes.string,
  showGMV: PropTypes.bool,
};

const styles = StyleSheet.create({
  productName: {
    fontSize: 28,
    color: squidInk,
    fontWeight: 800,
  },
  editButton: {
    borderColor: squidInk,
    color: squidInk,
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 24,
    paddingLeft: 30,
    paddingRight: 30,
  },
  editIcon: {
    marginRight: 8,
  },
});

export default ProductHeaderSection;
