import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Swal from 'sweetalert2';

import { blackberry, white, squidInk } from 'lib/css/colors';
import { SUPPORT_TICKET_CREATE_FROM_FSA } from 'lib/networking/endpoints';
import DropZone from 'components/shared/Dropzone';
import { ProductType } from 'lib/enums/ProductType';
import usePost from 'lib/hooks/usePost';

const SUPPORT_TICKET_FOLDER = 'support_ticket';

function PrimaryBrandEditModal({ onHide, product, typeOfProduct }) {
  const [task, setTask] = useState('');
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState([]);
  const SKU = product.manufacturer_sku || product.vendor_sku;

  const subtitleTextParts = [product.pack_size];
  if (typeOfProduct !== ProductType.GLOBAL && SKU) {
    subtitleTextParts.push(`SKU# ${SKU}`);
  }

  const subtitleText = subtitleTextParts.filter(item => item).join(' | ');

  const { postData: createSupportTicket } = usePost(
    SUPPORT_TICKET_CREATE_FROM_FSA,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Successfully created the ticket',
      });
      onHide();
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          `An error occurred while creating the support ticket` +
          ` ${error?.response?.data?.message}`,
      }),
  );

  const handleUploadSuccess = newUrls => {
    setAttachments(prevAttachments => [...prevAttachments, ...newUrls]);
  };

  return (
    <Modal show={true} onHide={onHide} size="xl" className="lato" centered>
      <Modal.Header closeButton>
        <Modal.Title className={classNames(css(styles.heading))}>Create Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col sm={2}>
            <Image src={product.thumb_url} fluid thumbnail width={120} />
          </Col>
          <Col>
            <div className={css(styles.productName)}>{product.name}</div>
            <div className="text-muted  mb-2">{subtitleText}</div>
          </Col>
        </Row>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">Title</Form.Label>
            <Form.Control
              type="text"
              className="mb-3"
              value={task}
              onChange={e => setTask(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">Describe the problem further</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={description}
              placeholder="Describe the problem further"
              onChange={e => setDescription(e.target.value)}
              className="mb-3"
              style={{ overflowY: 'auto' }}
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Label className="font-weight-bold">Upload optional files</Form.Label>
          <DropZone
            text="Drag and drop the attachments or click to select attachments"
            onUploadSuccess={handleUploadSuccess}
            fileUrls={attachments}
            isPublic={true}
            filePath={SUPPORT_TICKET_FOLDER}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-start">
        <Button
          variant="primary"
          onClick={() =>
            createSupportTicket({
              origin: 'FSA',
              vendor_product_id: typeOfProduct === ProductType.DISTRIBUTOR ? product.id : null,
              manufacturer_product_id:
                typeOfProduct === ProductType.MANUFACTURER ? product.id : null,
              task: task,
              details: { description: description, attachments: attachments },
            })
          }
          disabled={!task}
        >
          Submit
        </Button>
        <div>
          <Button variant="outline-primary" onClick={onHide} className="ml-2">
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    fontFamily: 'Mont, sans-serif',
    fontSize: '1.5rem',
  },
  updateButton: {
    backgroundColor: blackberry,
    borderColor: blackberry,
    color: white,
    fontWeight: 'bold',
  },
  section: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '8px',
    marginBottom: '10px',
  },
  productName: {
    fontSize: 28,
    color: squidInk,
    fontWeight: 800,
  },
});

PrimaryBrandEditModal.propTypes = {
  onHide: PropTypes.func,
  product: PropTypes.object,
  typeOfProduct: PropTypes.string,
};

export default PrimaryBrandEditModal;
