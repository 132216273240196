import { StyleSheet } from 'aphrodite';

const alertSalmon = '#FFECE7';
const anchovy = '#999BA7';
const amber = '#FFBF00';
const auroMetalSaurus = '#667085';
const backgroundDarkGrey = '#EAECF0';
const backgroundGrey = '#F8F9FB';
const backgroundGrey2 = '#ECF0F4';
const backgroundGrey3 = '#F3F3F4';
const backgroundGrey4 = '#EFEEEE';
const backgroundGrey5 = '#F9FAFB';
const backgroundGrey6 = '#aeb2bd';
const backgroundLightBlue = '#c9dde1';
const black = '#000000';
const blackberry = '#3B2B4F';
const blue = '#507cf1';
const blueviolet = '#7969c1';
const bodyText = '#212529';
const categoryGrey = '#F3F3F4';
const chartreuse = '#DCFE07';
const coolGrey = '#475467';
const contentVerifiedBlue = '#008AD8';
const danger = '#ed5565';
const darkerSalmon = '#EB4D4D';
const darkGrey = '#676a6c';
const darkGrey2 = '#D0D5DD';
const deeplavender = '#9E8CFF';
const extraLightSalmon = '#FFF6F4';
const filterContainerBoarder = '#e5e5e5';
const fontGrey = '#292929';
const fontGrey2 = '#BFC3C7';
const grape = '#9e8cff';
const green = '#1ab394';
const grey = '#667085';
const greyBackground = '#F9FAFB';
const greyscale = '#474150';
const greyHover = '#EAECF0';
const greyStroke = '#D0D5DD';
const iconGrey = '#a7b1c2';
const iconGrey2 = '#667085';
const kiwiLinkHoverDark = '#6C8A16';
const lightBrown = '#9d8e43';
const lighterGrey = '#e7eaec';
const lightGreen = '#f2fde1';
const lightGreen2 = '#337e2a';
const lightGreen3 = '#90EE90';
const lightGreyNew = '#F1F2F5';
const lightPurple = '#7969c1';
const lightPurple1 = '#bcbace';
const lightPurple2 = '#f0ecfc';
const lightRed = '#ffcccc';
const lightSalmon = '#ffdfdf';
const lightSteel = '#677e88';
const lightYellow = '#fffbda';
const modernSalmon = '#F36E6C';
const modernSalmonBackground = '#FEF3F3';
const orange = '#FFA500';
const positiveGreen = '#24A477';
const positiveGreenBackground = '#EDF8EF';
const purple = '#3B2B4F';
const salmon = '#ff5454';
const squidInk = '#090216';
const steel = '#51636b';
const yellow = '#ffff00';
const white = '#ffffff';

// category colors
const categoryBlack = '#000000';
const categoryBlue = '#278acb';
const categoryGreen = '#2ab34b';
const categoryOrange = '#f06717';
const categoryPink = '#ed0479';

const colorStyles = StyleSheet.create({
  anchovy: { color: anchovy },
  blue: { color: blue },
  coolGrey: { color: coolGrey },
  danger: { color: danger },
  grape: { color: grape },
  green: { color: green },
  lighterGrey: { color: lighterGrey },
  lightPurple: { color: lightPurple },
  salmon: { color: salmon },
  steel: { color: steel },
  auroMetalSaurus: { color: auroMetalSaurus },
});

const backgroundColorStyles = StyleSheet.create({
  alertSalmon: { backgroundColor: alertSalmon },
  backgroundGrey: { backgroundColor: backgroundGrey },
  backgroundGrey2: { backgroundColor: backgroundGrey2 },
  blue: { backgroundColor: blue },
  chartreuse: { backgroundColor: chartreuse },
  fontGrey: { backgroundColor: fontGrey },
  greyStroke: { backgroundColor: greyStroke },
  iconGrey: { backgroundColor: iconGrey },
  lighterGrey: { backgroundColor: lighterGrey },
  lightSalmon: { backgroundColor: lightSalmon },
  salmon: { backgroundColor: salmon },
});

export {
  alertSalmon,
  anchovy,
  amber,
  auroMetalSaurus,
  backgroundColorStyles,
  backgroundDarkGrey,
  backgroundGrey,
  backgroundGrey2,
  backgroundGrey3,
  backgroundGrey4,
  backgroundGrey5,
  backgroundGrey6,
  backgroundLightBlue,
  black,
  blackberry,
  blue,
  blueviolet,
  bodyText,
  categoryBlack,
  categoryBlue,
  categoryGreen,
  categoryGrey,
  categoryOrange,
  categoryPink,
  chartreuse,
  colorStyles,
  coolGrey,
  contentVerifiedBlue,
  danger,
  darkerSalmon,
  darkGrey,
  darkGrey2,
  deeplavender,
  extraLightSalmon,
  filterContainerBoarder,
  fontGrey,
  fontGrey2,
  grape,
  green,
  grey,
  greyBackground,
  greyscale,
  greyHover,
  greyStroke,
  iconGrey,
  iconGrey2,
  kiwiLinkHoverDark,
  lightBrown,
  lighterGrey,
  lightGreen,
  lightGreen2,
  lightGreen3,
  lightGreyNew,
  lightPurple,
  lightPurple1,
  lightPurple2,
  lightRed,
  lightSalmon,
  lightSteel,
  lightYellow,
  modernSalmon,
  modernSalmonBackground,
  positiveGreen,
  positiveGreenBackground,
  orange,
  purple,
  salmon,
  squidInk,
  steel,
  yellow,
  white,
};
