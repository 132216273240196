import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import {
  positiveGreen,
  positiveGreenBackground,
  lightSalmon,
  salmon,
  filterContainerBoarder,
} from 'lib/css/colors';

import LoadingSpinner from 'components/shared/LoadingSpinner';
import { UPDATE_SIMILAR_BRAND } from 'lib/networking/endpoints';
import { SwalToast } from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function SimilarBrandDetailsView({ similar_brand = {}, products, refetch, loading }) {
  const { postData: updateSimilarBrand } = usePost(
    UPDATE_SIMILAR_BRAND,
    () => {
      refetch();
      SwalToast('success', `Marked`);
    },
    () => SwalToast('error', `Could Not Mark the Brand`),
  );
  return (
    <Col className={classNames(css(styles.filterContainer), 'p-3')}>
      {loading && (
        <div className={css(styles.spinnerOverlay)}>
          <LoadingSpinner />
        </div>
      )}
      <Row className="d-flex align-items-center">
        <Col className="d-flex align-items-center ml-2">
          <h3 className="font-weight-bold">{similar_brand.brand_name}</h3>
          {similar_brand.ai_suggestion && (
            <h3 className={css(styles.aiSuggestion)}>(AI-Suggestion)</h3>
          )}
        </Col>
        <Col className="d-flex justify-content-end align-items-center mr-2">
          <Button
            className={css(styles.sameButton)}
            variant="outline-primary"
            onClick={() =>
              updateSimilarBrand({
                similar_brand_id: similar_brand?.id,
                flag: 'yes',
                ai_suggestion: similar_brand.ai_suggestion,
              })
            }
          >
            Same
          </Button>
          <Button
            className={css(styles.differentButton)}
            variant="outline-primary"
            onClick={() =>
              updateSimilarBrand({
                similar_brand_id: similar_brand?.id,
                flag: 'no',
                ai_suggestion: similar_brand.ai_suggestion,
              })
            }
          >
            Different
          </Button>
          <Button
            className={css(styles.button)}
            variant="outline-primary"
            onClick={() =>
              updateSimilarBrand({
                similar_brand_id: similar_brand?.id,
                flag: 'not_sure',
                ai_suggestion: similar_brand.ai_suggestion,
              })
            }
          >
            Not Sure
          </Button>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center ml-2 mr-2">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Distributor</th>
              <th>Product Name</th>
              <th>GTIN</th>
              <th>L4 Category</th>
              <th>Distributor Manufacturer Name</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {products?.map(product => (
              <tr key={product.id}>
                <td>{product.global_vendor?.name || '-'}</td>
                <td>{product.name || '-'}</td>
                <td>{product.gtin || '-'}</td>
                <td>{product.global_product?.l4_category?.category_name || '-'}</td>
                <td>{product.distributor_mfr_name || '-'}</td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => {
                      window.open(
                        // eslint-disable-next-line
                        `/global-product-detail/${product.global_product_id}/${product.id}/distributor`,
                        '_blank',
                      );
                    }}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Col>
  );
}

const styles = StyleSheet.create({
  aiSuggestion: {
    color: 'black',
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  filterContainer: {
    border: `3px solid ${filterContainerBoarder}`,
    marginLeft: '10px',
    marginRight: '10px',
    position: 'relative',
  },
  spinnerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1,
  },
  button: {
    width: '100px',
    maxHeight: '30px',
    marginLeft: '4px',
  },
  sameButton: {
    width: '100px',
    maxHeight: '30px',
    marginLeft: '4px',
    borderColor: positiveGreen,
    color: positiveGreen,
    ':hover': {
      backgroundColor: positiveGreenBackground,
      borderColor: positiveGreenBackground,
    },
  },
  differentButton: {
    width: '100px',
    maxHeight: '30px',
    marginLeft: '4px',
    marginRight: '4px',
    borderColor: salmon,
    color: salmon,
    ':hover': {
      backgroundColor: lightSalmon,
      borderColor: lightSalmon,
    },
  },
});

SimilarBrandDetailsView.propTypes = {
  similar_brand: PropTypes.object,
  products: PropTypes.array,
  refetch: PropTypes.func,
  loading: PropTypes.bool,
};

export default SimilarBrandDetailsView;
