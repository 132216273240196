import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Collapse } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import AttributeEditSectionHeader from 'components/global_products/detail_view/attributes_view/AttributeEditSectionHeader';
import { backgroundColorStyles } from 'lib/css/colors';

function AttributeEditSection({ title, children }) {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <div className={css(styles.container)}>
        <div className={css(styles.header)}>
          <AttributeEditSectionHeader
            title={title}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </div>
        <Collapse in={isExpanded}>
          <div>{children}</div>
        </Collapse>
      </div>
      <hr className={classNames(css(backgroundColorStyles.greyStroke, styles.hLine))} />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 40,
  },
  header: {
    marginBottom: 24,
  },
  hLine: {
    height: 1,
    border: 0,
  },
});

AttributeEditSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AttributeEditSection;
