//update along with app/lib/enums/support_ticket_enums.py
const SupportTicketStatus = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

const SupportTicketStatusLabels = {
  OPEN: 'Open',
  IN_PROGRESS: 'In progress',
  CLOSED: 'Closed',
};

const SupportTicketStatusOptions = Object.entries(SupportTicketStatusLabels).map(
  ([value, label]) => ({
    label,
    value,
  }),
);

export { SupportTicketStatus, SupportTicketStatusLabels, SupportTicketStatusOptions };
