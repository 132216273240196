import React, { useState } from 'react';

import { Button, Col, Image, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import SplitPane from 'react-split-pane';
import { SwalToast } from 'lib/utils/toast';

import { anchovy, filterContainerBoarder } from 'lib/css/colors';
import CategoryFilter from 'components/shared/CategoryFilter';
import Container from 'components/shared/Container';
import {
  getURL,
  GLOBAL_PRODUCT_DEFAULT_IMAGE_UPDATE,
  GLOBAL_PRODUCTS_WITHOUT_DEFAULT_IMAGES,
} from 'lib/networking/endpoints';
import { l0AdministrativeCategoryId } from 'lib/enums/TaxonomyLevel';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';
import SuggestionsGoogleView from 'components/enrich/image_suggester/SuggestionsGoogleView';
import SuggestionsL4View from 'components/enrich/image_suggester/SuggestionsL4View';
import useGet from 'lib/hooks/useGet';
import usePut from 'lib/hooks/usePut';
import useTaxonomyOptions from 'lib/hooks/useTaxonomyOptions';

function ImageSuggesterView() {
  const [taxonomySearch, setTaxonomySearch] = useState({
    l3Search: '',
    l4Search: '',
  });
  const [selectedImageUrl, setSelectedImageUrl] = useState({
    imageUrl: null,
    isWebSuggestion: false,
  });

  const { options: { l1Options, l2Options, l3Options, l4Options } = {} } = useTaxonomyOptions(
    undefined,
    undefined,
    undefined,
    taxonomySearch.l3Search,
    taxonomySearch.l4Search,
    true,
    false,
    false,
    l0AdministrativeCategoryId,
  );

  const {
    data = [],
    refetch,
    loading,
  } = useGet(GLOBAL_PRODUCTS_WITHOUT_DEFAULT_IMAGES, {
    l3_category_id: taxonomySearch.l3Search?.id,
    l4_category_id: taxonomySearch.l4Search?.id,
    random: true,
  });

  const { putData: updateGlobalProductDefaultImage, loading: updatingImage } = usePut(
    getURL(GLOBAL_PRODUCT_DEFAULT_IMAGE_UPDATE, { productId: data[0]?.id }),
    () => {
      refetch();
      setSelectedImageUrl({
        imageUrl: null,
        isWebSuggestion: false,
      });
      SwalToast('success', 'Succesfully Updated');
    },
  );

  return (
    <Container className={classNames(css(styles.container), 'mx-5')}>
      <div className="mb-3 d-flex justify-content-between pl-4">
        <h3 className="font-weight-bold">Image Suggester</h3>
      </div>
      <Row className="pl-4">
        <Col sm="2" className="font-weight-bold">
          L3 Category
        </Col>
        <Col sm="2" className="font-weight-bold">
          L4 Category
        </Col>
        <Col sm="8">
          <Button
            className="font-weight-bold float-right mr-4 px-5"
            onClick={() => {
              setSelectedImageUrl({
                imageUrl: null,
                isWebSuggestion: false,
              });
              refetch();
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
      <Row className="pl-4">
        <Col sm="2">
          <CategoryFilter
            categoryOptions={l3Options}
            selectedCategoryName={taxonomySearch.l3Search?.label}
            onChange={(option, triggeredAction) =>
              setTaxonomySearch({ ...taxonomySearch, l3Search: option })
            }
            placeholder={'Select a L3 Category'}
          />
        </Col>
        <Col sm="2">
          <CategoryFilter
            categoryOptions={l4Options}
            selectedCategoryName={taxonomySearch.l4Search?.label}
            onChange={(option, triggeredAction) =>
              setTaxonomySearch({ ...taxonomySearch, l4Search: option })
            }
            placeholder={'Select a L4 Category'}
          />
        </Col>
        {updatingImage && <LoadingSpinner />}
      </Row>
      {loading ? (
        <LoadingSpinner />
      ) : (
        data[0] && (
          <Row className={classNames(css(styles.productDetails), 'mx-4')}>
            <Col>
              <Row>
                <Col md={1}>
                  <Image
                    className={classNames(css(styles.square), pointerOnHover, 'my-3')}
                    src={
                      data[0]?.propagated_thumbnail_image_url ||
                      selectedImageUrl.imageUrl ||
                      placeholderProductImage
                    }
                  />
                </Col>
                <Col md={9} className="mt-2 ml-4">
                  <div className="d-flex align-items-end mt-2">
                    <a
                      className={css(styles.productName)}
                      href={`/global-product-detail/${data[0].id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data[0]?.name}
                    </a>
                  </div>
                  <div className="d-flex align-items-end mt-2">{data[0]?.propagated_brand}</div>
                  <div className="d-flex align-items-end">{data[0]?.manufacturer_name}</div>
                  <div className="d-flex align-items-end">{data[0]?.gtin}</div>
                  <div className="d-flex align-items-end">{data[0]?.propagated_pack_size}</div>
                  <div className="d-flex align-items-end">
                    <Button
                      className="mb-2"
                      disabled={!selectedImageUrl.imageUrl}
                      onClick={() =>
                        updateGlobalProductDefaultImage({
                          default_image_url: selectedImageUrl.imageUrl,
                          is_web_image: selectedImageUrl.isWebSuggestion,
                        })
                      }
                    >
                      Save Image
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      )}
      {data[0] && (
        <SplitPane
          primary="first"
          split="vertical"
          defaultSize="50%"
          className={classNames(css(styles.splitPane), 'mx-4 mt-4')}
        >
          <SuggestionsL4View
            l4CategoryId={data[0].l4_category?.id}
            onImageClick={image =>
              setSelectedImageUrl({ imageUrl: image?.url, isWebSuggestion: false })
            }
            isImageSelected={imageUrl => selectedImageUrl.imageUrl === imageUrl}
          />
          <SuggestionsGoogleView
            product={data[0]}
            onImageClick={image => setSelectedImageUrl({ imageUrl: image, isWebSuggestion: true })}
            isImageSelected={imageUrl => selectedImageUrl.imageUrl === imageUrl}
          />
          <div />
        </SplitPane>
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: 'calc(100vh - 150px)',
  },
  productDetails: {
    maxHeight: '45vh',
    border: `3px solid ${filterContainerBoarder}`,
  },
  productName: {
    fontSize: 22,
    fontWeight: 800,
  },
  square: {
    width: '120px',
    height: '120px',
    objectFit: 'contain',
    border: '2px solid ' + anchovy,
    marginBottom: '1rem',
    padding: '0.5rem',
  },
  splitPane: {
    position: 'relative',
  },
});

export default ImageSuggesterView;
