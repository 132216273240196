import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import dayjs from 'dayjs';

import { DASHBOARD } from 'lib/networking/endpoints';
import DashboardWidget from 'components/dashboard/DashboardWidget';
import DashboardWidgetType from 'lib/enums/DashboardWidgetTypes';
import { DashboardWidgetTypeLabels } from 'lib/enums/DashboardWidgetTypes';
import useGet from 'lib/hooks/useGet';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

function DashboardWidgetTile({ widgetType, label, onClick }) {
  const startDate = dayjs().subtract(6, 'day').startOf('day').toDate();
  const endDate = dayjs().endOf('day').toDate();
  const differenceInMilliseconds = dayjs(endDate).diff(dayjs(startDate));
  const dateDifference = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

  const {
    data: { percentage_change = 0, other_counts = '', total_count = 0, specific_count } = {},
    loading,
  } = useGet(DASHBOARD, {
    widget_type: widgetType,
    start_date: dayjs(startDate).format(DATE_TIME_FORMAT),
    end_date: dayjs(endDate).format(DATE_TIME_FORMAT),
  });
  return (
    <div>
      <div className={classNames('mb-3', css(styles.widgetContainer))}>
        <DashboardWidget
          label={label}
          totalCount={total_count}
          percentageChange={percentage_change}
          loading={loading}
          dateDifference={dateDifference}
          onClick={onClick}
          widgetType={widgetType}
          specificCount={specific_count}
          otherCounts={other_counts}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  widgetContainer: {
    minWidth: 340,
    minHeight: 30,
    maxWidth: 300,
  },
});

DashboardWidgetTile.propTypes = {
  widgetType: PropTypes.oneOf(Object.values(DashboardWidgetType)),
  label: PropTypes.oneOf(Object.values(DashboardWidgetTypeLabels)),
  onClick: PropTypes.func,
};

export default DashboardWidgetTile;
