import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Tab, Tabs } from 'react-bootstrap';

import RecommendationsSection from 'components/l4_default_images/recommendations/RecommendationsSection';
import UploadImagesSection from 'components/l4_default_images/recommendations/UploadImagesSection';

const IMAGE_RECOMMENDATIONS_TAB = {
  RECOMMENDED_IMAGES: 'Recommended Images',
  UPLOAD_IMAGES: 'Upload Images',
};

function RecommendedImagesTabView({
  l4CategoryId,
  selectedThumbnailUrl,
  setSelectedThumbnailUrl,
  uploadedImages,
  setUploadedImages,
  l3Category = false,
}) {
  const [activeTabKey, setActiveTabKey] = useState(IMAGE_RECOMMENDATIONS_TAB.RECOMMENDED_IMAGES);
  return (
    <>
      {l3Category ? (
        <div className="my-3">
          <UploadImagesSection
            imageUrls={uploadedImages}
            setImageUrls={setUploadedImages}
            setSelectedThumbnailUrl={setSelectedThumbnailUrl}
          />
        </div>
      ) : (
        <div>
          <Tabs activeKey={activeTabKey} onSelect={selectedKey => setActiveTabKey(selectedKey)}>
            <Tab
              eventKey={IMAGE_RECOMMENDATIONS_TAB.RECOMMENDED_IMAGES}
              title={IMAGE_RECOMMENDATIONS_TAB.RECOMMENDED_IMAGES}
            >
              <div className={classNames('my-3')}>
                <RecommendationsSection
                  l4CategoryId={l4CategoryId}
                  selectedThumbnailUrl={selectedThumbnailUrl}
                  setSelectedThumbnailUrl={setSelectedThumbnailUrl}
                />
              </div>
            </Tab>
            <Tab
              eventKey={IMAGE_RECOMMENDATIONS_TAB.UPLOAD_IMAGES}
              title={IMAGE_RECOMMENDATIONS_TAB.UPLOAD_IMAGES}
            >
              <div className="my-3">
                <UploadImagesSection
                  imageUrls={uploadedImages}
                  setImageUrls={setUploadedImages}
                  setSelectedThumbnailUrl={setSelectedThumbnailUrl}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  );
}

RecommendedImagesTabView.propTypes = {
  l4CategoryId: PropTypes.number,
  uploadedImages: PropTypes.array,
  setUploadedImages: PropTypes.func,
  selectedThumbnailUrl: PropTypes.string,
  setSelectedThumbnailUrl: PropTypes.func,
  l3Category: PropTypes.bool,
};

export default RecommendedImagesTabView;
