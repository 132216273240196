import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'react-bootstrap';
import { groupBy, isEmpty } from 'lodash';
import ToggleSwitch from 'react-switch';

import { ATTRIBUTE_SECTIONS } from 'lib/constants';
import {
  ATTRIBUTES_FOR_PRODUCT,
  ATTRIBUTES_FOR_PRODUCT_UPDATE_MULTI,
} from 'lib/networking/endpoints';
import BaseSectionAttributeEditView from 'components/global_products/detail_view/attributes_view/BaseSectionAttributeEditView';
import CHANGE_LOG_USER_TYPE from 'lib/enums/ChangeLogUserType';
import ClaimsSectionAttributeEditView from 'components/global_products/detail_view/attributes_view/ClaimsSectionAttributeEditView';
import { CUT_DRY_CLIENT_ID } from 'lib/constants';
import { GlobalProductsDetailContext } from 'components/global_products/detail_view/GlobalProductsDetailContainer';
import { grape } from 'lib/css/colors';

import ImageSectionAttributeEditView from 'components/global_products/detail_view/attributes_view/ImageSectionAttributeEditView';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import LogisticsSectionAttributeEditView from 'components/global_products/detail_view/attributes_view/LogisticsSectionAttributeEditView';
import MarketingSectionAttributeEditView from 'components/global_products/detail_view/attributes_view/MarketingSectionAttributeEditView';
import NutritionSectionAttributeEditView from 'components/global_products/detail_view/attributes_view/NutritionSectionAttributeEditView';
import { ProductType } from 'lib/enums/ProductType';
import SearchBar from 'components/shared/SearchBar';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function ProductsAttributesView({ productId, productType, searchInput, setSearchInput, refetch }) {
  const [hideEmptyAttributes, setHideEmptyAttributes] = useState(true);
  const [showPropagateData, setShowPropagateData] = useState(true);
  const { setOtherImages, setThumbnailImage, setLoading } = useContext(GlobalProductsDetailContext);
  const [unsavedChanges, setUnsavedChanges] = useState([]);

  const {
    data: { data: attributes, images: imageDetails } = {},
    loading: attributesLoading,
    refetch: refetchAttributes,
  } = useGet(
    ATTRIBUTES_FOR_PRODUCT,
    {
      product_id: productId,
      product_type: productType,
      propagate_data: showPropagateData,
      get_image_data: true,
      with_owner_details: true,
      client_id: CUT_DRY_CLIENT_ID,
    },
    !(productId && productType),
  );

  const { postData: updateAttributes } = usePost(
    ATTRIBUTES_FOR_PRODUCT_UPDATE_MULTI,
    () => {
      refetchAttributes();
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        'An error occurred: ' + error?.response?.data?.message,
        'Error',
        'center',
      );
    },
  );

  useEffect(() => {
    if (imageDetails) {
      setOtherImages(imageDetails.other_images);
      setThumbnailImage(imageDetails.thumbnail);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageDetails]);

  const filteredAttributes = useMemo(() => {
    if (!attributes?.length) {
      return [];
    }
    let attrResults = attributes;
    if (hideEmptyAttributes) {
      attrResults = attrResults.filter(attribute => !!attribute.attribute_value);
    }
    if (searchInput) {
      attrResults = attributes.filter(attribute => {
        const searchableText =
          attribute.attribute_name +
          attribute.attribute_section +
          attribute.attribute_sub_section +
          attribute.attribute_value;
        return searchableText.toLowerCase().includes(searchInput.toLowerCase());
      });
    }
    return attrResults.filter(
      attribute => attribute.attribute_section !== ATTRIBUTE_SECTIONS.IMAGES,
    );
  }, [attributes, hideEmptyAttributes, searchInput]);

  const attributesGroupedBySections = useMemo(() => {
    // eslint-disable-next-line
    return groupBy(filteredAttributes, function (n) {
      return n.attribute_section;
    });
  }, [filteredAttributes]);

  function setAttributeValue(attribute, value) {
    setUnsavedChanges({ ...unsavedChanges, [attribute.attribute_name]: value });
  }

  function saveData() {
    const updatedData = Object.entries(unsavedChanges).map(([key, value]) => {
      return {
        attribute_name: key,
        value,
      };
    });
    updateAttributes({
      product_id: productId,
      product_type: productType,
      user_type: CHANGE_LOG_USER_TYPE.FSA,
      override_with_null_value: true,
      attribute_data: updatedData,
    }).then(() => refetch());
  }

  const haveUnsavedChanges = !isEmpty(unsavedChanges);
  if (attributesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="my-4 px-4">
      <Row className="d-flex">
        <Col>
          <SearchBar
            value={searchInput}
            setValue={setSearchInput}
            placeholder="Search by attribute header, value, etc"
          />
        </Col>
        <Col className="d-flex align-items-center">
          <div className="ml-3 d-flex align-items-center">
            Hide Empty
            <ToggleSwitch
              className="ml-2"
              onColor={grape}
              checked={hideEmptyAttributes}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={e => setHideEmptyAttributes(e)}
            />
          </div>
          <div className="ml-4 d-flex align-items-center">
            Show Propagated Data
            <ToggleSwitch
              className="ml-2"
              onColor={grape}
              checked={showPropagateData}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={e => setShowPropagateData(e)}
            />
          </div>
          <Button
            className="ml-3"
            variant={haveUnsavedChanges ? 'outline-primary' : 'primary'}
            onClick={() => saveData()}
          >
            {haveUnsavedChanges ? '! Unsaved Changes' : 'Save Changes'}
          </Button>
        </Col>
      </Row>
      <div className="mt-3">
        <BaseSectionAttributeEditView
          attributes={attributesGroupedBySections.Base || []}
          setAttributeValue={setAttributeValue}
          isGlobalProduct={productType === ProductType.GLOBAL}
        />
        <ClaimsSectionAttributeEditView
          attributes={attributesGroupedBySections.Claims || []}
          setAttributeValue={setAttributeValue}
          isGlobalProduct={productType === ProductType.GLOBAL}
        />
        <ImageSectionAttributeEditView
          attributes={attributesGroupedBySections.Image || []}
          setAttributeValue={setAttributeValue}
          isGlobalProduct={productType === ProductType.GLOBAL}
        />
        <LogisticsSectionAttributeEditView
          attributes={attributesGroupedBySections.Logistics || []}
          setAttributeValue={setAttributeValue}
          isGlobalProduct={productType === ProductType.GLOBAL}
        />
        <MarketingSectionAttributeEditView
          attributes={attributesGroupedBySections.Marketing || []}
          setAttributeValue={setAttributeValue}
          isGlobalProduct={productType === ProductType.GLOBAL}
        />
        <NutritionSectionAttributeEditView
          attributes={attributesGroupedBySections.Nutrition || []}
          setAttributeValue={setAttributeValue}
          isGlobalProduct={productType === ProductType.GLOBAL}
        />
      </div>
    </div>
  );
}

ProductsAttributesView.propTypes = {
  productId: PropTypes.number,
  productType: PropTypes.string,
  refetch: PropTypes.func,
  searchInput: PropTypes.string,
  setSearchInput: PropTypes.func,
};

export default ProductsAttributesView;
