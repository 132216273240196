import React from 'react';
import PropTypes from 'prop-types';

import AttributeEditSection from 'components/global_products/detail_view/attributes_view/AttributeEditSection';
import AttributeValueInputField from 'components/global_products/detail_view/attributes_view/AttributeValueInputField';

function BaseSectionAttributeEditView({ attributes, setAttributeValue, isGlobalProduct = false }) {
  if (!attributes.length) {
    return null;
  }
  return (
    <AttributeEditSection title="Base">
      {attributes &&
        attributes.map((attr, index) => (
          <AttributeValueInputField
            key={attr.attribute_name}
            attribute={attr}
            setAttributeValue={setAttributeValue}
            isGlobalProductAttribute={isGlobalProduct}
          />
        ))}
    </AttributeEditSection>
  );
}

BaseSectionAttributeEditView.propTypes = {
  attributes: PropTypes.array.isRequired,
  setAttributeValue: PropTypes.func.isRequired,
  isGlobalProduct: PropTypes.bool,
};

export default BaseSectionAttributeEditView;
