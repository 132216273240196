import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Col, Row, Table } from 'react-bootstrap';
import Select from 'react-select';

import ChangelogRow from 'components/global_products/detail_view/ChangelogRow';
import { CHANGE_LOGS } from 'lib/networking/endpoints';
import { CHANGE_LOG_TYPE_MAP, CHANGE_LOG_TYPE } from 'lib/enums/ChangeLogEnums';
import { DEFAULT_PAGE_SIZE, SORT_DIRECTIONS } from 'lib/constants';
import { GLOBAL_PRODUCT_TYPE } from 'lib/enums/ProductType';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import { ProductType } from 'lib/enums/ProductType';
import useGet from 'lib/hooks/useGet';

function GlobalProductsChangelogView({ selectedProductOption }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);

  const selectedVendorProductId =
    selectedProductOption?.productType === ProductType.DISTRIBUTOR
      ? selectedProductOption?.productId
      : null;
  const selectedManufacturerProductId =
    selectedProductOption?.productType === ProductType.MANUFACTURER
      ? selectedProductOption?.productId
      : null;

  const { data: { data: changeLogs, loading } = {} } = useGet(
    CHANGE_LOGS,
    {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
      ...(selectedVendorProductId && {
        vendor_product_id: selectedVendorProductId,
      }),
      ...(selectedManufacturerProductId && {
        manufacturer_product_id: selectedManufacturerProductId,
      }),
      sort_direction: SORT_DIRECTIONS.DESC,
      change_log_type: CHANGE_LOG_TYPE.PRODUCT_UPDATE,
    },
    selectedProductOption?.productType === GLOBAL_PRODUCT_TYPE,
  );

  const { data: { count } = {} } = useGet(
    CHANGE_LOGS,
    {
      ...(selectedVendorProductId && {
        vendor_product_id: selectedVendorProductId,
      }),
      ...(selectedManufacturerProductId && {
        manufacturer_product_id: selectedManufacturerProductId,
      }),
      change_log_type: CHANGE_LOG_TYPE.PRODUCT_UPDATE,
      count_only: true,
    },
    selectedProductOption?.productType === GLOBAL_PRODUCT_TYPE,
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="my-4">
      <div className="my-2 d-flex justify-content-between">
        <p>{count} change logs found</p>
        <PaginationComponent
          totalItems={count}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          defaultSize
        />
      </div>
      <Row>
        <Col sm="3">
          <Select
            className="ml-2"
            options={CHANGE_LOG_TYPE_MAP}
            value={CHANGE_LOG_TYPE_MAP.find(
              option => option.value === CHANGE_LOG_TYPE.PRODUCT_UPDATE,
            )}
            isDisabled
          />
        </Col>
      </Row>
      {changeLogs?.length ? (
        <Table hover responsive className={classNames(css(styles.table), 'mt-3')}>
          <thead>
            <tr>
              <th width="40">Date</th>
              <th width="30">Type</th>
              <th width="200">User email</th>
              <th>Old Value</th>
              <th>New Value</th>
            </tr>
          </thead>
          <tbody>
            {changeLogs.map(changeLog => (
              <ChangelogRow key={changeLog.id} changeLog={changeLog} />
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="m-3">This product has had no changes yet</div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  table: {
    fontSize: '12px',
  },
});

GlobalProductsChangelogView.propTypes = {
  selectedProductOption: PropTypes.object.isRequired,
};

export default GlobalProductsChangelogView;
