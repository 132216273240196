import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { anchovy, filterContainerBoarder, squidInk } from 'lib/css/colors';

function PrimaryBrandSection({ label, value, brandData }) {
  function getSectionComponent(label) {
    switch (label) {
      case 'Name':
        return <div className={css(styles.brandName)}>{brandData.brandName}</div>;
      case 'Manufacturer':
        return (
          <div className="text-muted">
            <span>by {brandData?.manufacturerName}</span>
          </div>
        );
      case 'Keywords':
        return (
          <div className={css(styles.keywordsContainer)}>
            {brandData?.keywords.map((word, index) => (
              <span key={index} className={css(styles.keyword)}>
                {word}
              </span>
            ))}
          </div>
        );
      case 'Brand URL':
        return (
          <div className={css(styles.urlContainer)}>
            <span className="text-muted mr-1">URL:</span>
            <a
              href={brandData?.brandURL}
              className={css(styles.brandURL)}
              onClick={e => {
                e.preventDefault();
                window.open(brandData.brandURL, '_blank');
              }}
            >
              {brandData?.brandURL}
            </a>
          </div>
        );
      case 'Description':
        return <div>{brandData?.description}</div>;
      default:
        return null;
    }
  }
  return (
    <div className="d-flex align-items-end mt-2">
      {label !== 'Name' && (
        <FontAwesomeIcon className={classNames('mr-2 mb-1')} icon={faDotCircle} size="sm" />
      )}
      {(Array.isArray(value) && value.length > 0) ||
      (typeof value === 'string' && value.trim() !== '') ? (
        getSectionComponent(label)
      ) : (
        <div className="text-muted">Add a {label}</div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  brandName: {
    fontSize: 40,
    color: squidInk,
    fontWeight: 800,
  },
  keywordsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    marginTop: '5px',
  },
  keyword: {
    border: `1px solid ${filterContainerBoarder}`,
    color: squidInk,
    padding: '4px 8px',
    borderRadius: '6px',
    fontSize: '12px',
  },
  urlContainer: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  brandURL: {
    color: anchovy,
    textDecoration: 'none',
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '300px',
  },
  description: {
    marginTop: '10px',
    fontSize: '14px',
    lineHeight: '1.5',
    color: squidInk,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

PrimaryBrandSection.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClick: PropTypes.func,
  brandData: PropTypes.object,
};

export default PrimaryBrandSection;
