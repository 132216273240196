import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import ProductImagesSection from 'components/upb_viewer/pdp_components/images_section/ProductImagesSection';

function ImagesDisplayModal({ images, onHide }) {
  return (
    <Modal show={true} onHide={onHide} size="xl" className="lato" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Attachments</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductImagesSection images={images} />
      </Modal.Body>
    </Modal>
  );
}

ImagesDisplayModal.propTypes = {
  images: PropTypes.array,
  onHide: PropTypes.func,
};

export default ImagesDisplayModal;
