import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { useHistory } from 'react-router-dom';

import { darkGrey2 } from 'lib/css/colors';
import { getURL, TAXONOMY_UPB_VIEW_CATEGORY_OPTIONS } from 'lib/networking/endpoints';
import NestedFiltersGroup from 'components/upb_viewer/NestedFiltersGroup';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';

function CatalogSidePanel({
  selectedCategories,
  setSelectedCategories,
  gmvData,
  showSubOptions = true,
}) {
  const history = useHistory();
  const { data: { data: categories } = {}, loading } = useGet(
    getURL(TAXONOMY_UPB_VIEW_CATEGORY_OPTIONS, {}),
    { with_sub_options: true, with_taxonomy_display_name: true, with_icon_image_url: true },
  );

  const l1GMVData = gmvData?.filter(gmv => gmv.L_CAT === 'L1');
  const l2GMVData = gmvData?.filter(gmv => gmv.L_CAT === 'L2');

  // L2 options
  const transformSubOptions = options => {
    // ex options: [{ "category_name": "Bev-Alc", "id": 2, sub_options: [] }]
    if (!options) return options;
    return options.map(option => {
      const transformed = {
        label: option.label || option.taxonomy_display_name || option.category_name,
        value: option.value || option.id,
      };
      transformed.iconImageUrl = option.icon_image_url;
      transformed.gmv = l2GMVData?.find(gmv => gmv.CAT_VALUE == transformed.value)?.TOTAL;
      transformed.taxonomyLevel = option.taxonomy_level;
      // ex transformed: { "label": "Bev-Alc", "value": 2, subOptions: [], "gmv": 1000}
      return transformed;
    });
  };

  // L1 options
  const transformOptions = options => {
    // ex options: [{ "category_name": "Bev-Alc", "id": 2, sub_options: [] }]
    if (!options) return options;
    return options.map(option => {
      const transformed = {
        label: option.label || option.taxonomy_display_name || option.category_name,
        value: option.value || option.id,
      };

      if (option.sub_options) {
        transformed.subOptions = transformSubOptions(option.sub_options);
      }
      transformed.iconImageUrl = option.icon_image_url;
      transformed.gmv = l1GMVData?.find(gmv => gmv.CAT_VALUE == transformed.value)?.TOTAL;
      transformed.taxonomyLevel = option.taxonomy_level;
      // ex transformed: { "label": "Bev-Alc", "value": 2, subOptions: [], "gmv": 1000}
      return transformed;
    });
  };

  const categoryOptions = useMemo(() => {
    if (!categories?.length) {
      return [];
    }
    const catOptions = transformOptions(categories).sort((a, b) => a.label.localeCompare(b.label));
    return catOptions;
    // eslint-disable-next-line
  }, [categories, gmvData]);

  return (
    <div className={classNames(css(styles.container), pointerOnHover)}>
      <span
        className={classNames(css(styles.header), 'font-weight-bold pl-2 pb-2')}
        onClick={() => history.push(`/upb-viewer`)}
      >
        All Categories
      </span>
      {!categories ? (
        <LoadingSpinner short />
      ) : (
        <div className={css(styles.filterGroupContainer)}>
          <NestedFiltersGroup
            options={categoryOptions}
            selectedOptions={selectedCategories}
            setSelectedOptions={setSelectedCategories}
            showSubOptions={showSubOptions}
            showCategoryBadges={true}
          />
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 35,
    overflowY: 'auto',
  },
  filterGroupContainer: {
    maxHeight: '70vh',
    overflowY: 'auto',
    paddingLeft: 0,
  },
  header: {
    fontSize: 18,
    fontWeight: 600,
    borderBottom: '1px solid ' + darkGrey2,
    display: 'block',
  },
});

CatalogSidePanel.propTypes = {
  selectedCategories: PropTypes.arrayOf(PropTypes.object),
  setSelectedCategories: PropTypes.func,
  gmvData: PropTypes.arrayOf(PropTypes.object),
  showSubOptions: PropTypes.bool,
};

export default CatalogSidePanel;
