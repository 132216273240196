import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { areYouSure } from 'lib/utils/toast';
import { Button, Col, Image, Modal, Row, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import { faArrowCircleLeft, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

import Container from 'components/shared/Container';
import {
  DATA_SOURCE_GET_BY_ID,
  DATA_SOURCE_CONNECTED_PRODUCTS,
  DATA_SOURCE_UPDATE_CONNECTED_PRODUCTS,
  GLOBAL_PRODUCT_MANUFACTURER_UPDATE,
  getURL,
} from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import { ProductType } from 'lib/enums/ProductType';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import UPBProductDetailsPage from 'components/upb_viewer/UPBProductDetailsPage';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

const verificationStatusOptions = [
  { label: 'All Good', value: 'VERIFIED' },
  { label: 'Issues on Data', value: 'UNVERIFIED' },
];

function ProductsToValidateTable() {
  const { data_source_id } = useParams();
  const hasUserPermissions = useUserPermissions();
  const history = useHistory();
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const {
    data: dataSource,
    refetch: refetchDataSource,
    loading: dataSourceLoading,
  } = useGet(getURL(DATA_SOURCE_GET_BY_ID, { data_source_id: data_source_id }));

  const {
    data: { data: products = [] } = {},
    loading: productsLoading,
    refetch: refetchProducts,
  } = useGet(DATA_SOURCE_CONNECTED_PRODUCTS, {
    data_source_id: data_source_id,
    with_product_url: true,
    limit: 500, // We only consider to validate 5% of the products to validate
    offset: 0,
    load_only_random_percentage: 5,
  });
  const { postData: updateProduct, loading: updating } = usePost(
    GLOBAL_PRODUCT_MANUFACTURER_UPDATE,
    () => {
      refetchDataSource();
      refetchProducts();
    },
  );

  const isVerificationEnabled = products?.some(
    product => product.content_verification_status === 'UNVERIFIED',
  );

  const { postData: verifyContentOfDataSource, loading: verifyingContentOfDataSource } = usePost(
    DATA_SOURCE_UPDATE_CONNECTED_PRODUCTS,
    () => {
      SwalDialog(
        'success',
        `Successfully marked data source products as content verified`,
        'Success',
        'center',
        () => {
          refetchProducts();
        },
      );
    },
    () => {
      SwalDialog('error', 'An Error occurred while Updating Products', 'Error', 'center');
    },
  );

  if (dataSourceLoading || productsLoading) {
    return <LoadingSpinner />;
  }
  const handleSKUClick = product => {
    const prod_type = product.vendor_sku ? 'distributor' : 'manufacturer';
    const url = `/global-product-detail/${product.global_product.id}/${product.id}/${prod_type}`;
    window.open(url, '_blank');
  };

  if (!products.length) {
    return (
      <Container>
        <CommonHeaderRow dataSource={dataSource} history={history} />
        <Row>
          <Col>
            <h5 className="my-5 text-center">
              No Products, have you run Process on the Data Source?
            </h5>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <CommonHeaderRow dataSource={dataSource} history={history} />
        <Row className="my-3">
          <Col className="d-flex justify-content-between">
            {dataSource.content_verified_product_count}/
            {dataSource.content_verification_sample_size} Content Verified Products
            <Button
              variant="primary"
              onClick={() =>
                areYouSure(
                  () =>
                    verifyContentOfDataSource({
                      data_source_id: dataSource.id,
                    }),
                  'Are you sure you want to verify content of all remaining products of the data source?',
                )
              }
              disabled={
                verifyingContentOfDataSource ||
                isVerificationEnabled ||
                !hasUserPermissions([UserPermission.DATA_SOURCE_CONTENT_VERIFICATION])
              }
            >
              Mark Data Source Products As Content Verified
            </Button>
          </Col>
        </Row>
        <Table striped>
          <thead>
            <tr>
              <th>SKU</th>
              <th>Thumbnail</th>
              <th>Name</th>
              <th>Compare</th>
              <th>Content Verified By</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product.id}>
                <th>
                  <Button variant="link" onClick={() => handleSKUClick(product)}>
                    {product.manufacturer_sku || product.vendor_sku}
                  </Button>
                </th>
                <td>
                  {product.propagated_thumbnail_image_url && (
                    <Image
                      src={product.propagated_thumbnail_image_url}
                      className="d-block"
                      height={40}
                    />
                  )}
                </td>
                <td>{product.name}</td>
                <td className="d-flex">
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      if (!product.product_url) {
                        Swal.fire({
                          title: 'Missing Source URL',
                          text: 'No product source URL available for this product',
                        });
                      } else {
                        window.open(product.product_url, '_blank');
                      }
                    }}
                    variant="link"
                    className={product.product_url ? '' : 'text-muted'}
                  >
                    Source
                  </Button>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      setSelectedProductId(product.id);
                      setShowCompareModal(true);
                    }}
                    variant="link"
                    className="ml-2"
                  >
                    UPB
                  </Button>
                </td>
                <td>{product.content_verified_user?.name || '-'}</td>
                <td>
                  <Select
                    isDisabled={updating}
                    value={
                      product.content_verification_status &&
                      verificationStatusOptions.find(
                        option => option.value === product.content_verification_status,
                      )
                    }
                    options={verificationStatusOptions}
                    onChange={option => {
                      updateProduct({
                        global_mfr_product_id: product.id,
                        content_verification_status: option.value,
                        data_source_id: dataSource.id,
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <Modal
        dialogClassName={css(styles.fullModal)}
        show={showCompareModal}
        onHide={() => setShowCompareModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Data Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <UPBProductDetailsPage
                  propProductId={selectedProductId}
                  productType={ProductType.MANUFACTURER}
                  hideHeader
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCompareModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function CommonHeaderRow({ dataSource, history }) {
  return (
    <Row className="mr-4 mb-4">
      <Col>
        <h3 className="font-weight-bold">
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'mr-4')}
            icon={faArrowCircleLeft}
            onClick={() => history.push('/product-content-validator')}
          />
          Validate Products : {dataSource?.source_name}
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'ml-4')}
            icon={faLink}
            onClick={() => history.push(`/data-sources/${dataSource.id}`)}
          />
        </h3>
      </Col>
    </Row>
  );
}

CommonHeaderRow.propTypes = {
  dataSource: PropTypes.object,
  history: PropTypes.object,
};

const styles = StyleSheet.create({
  fullModal: {
    width: '90%',
    maxWidth: '90%',
  },
});
export default ProductsToValidateTable;
