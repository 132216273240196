import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Image, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { anchovy, lightPurple } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import { ProductType } from 'lib/enums/ProductType';
import useGet from 'lib/hooks/useGet';
import { WEB_IMAGE_RECOMMENDATIONS } from 'lib/networking/endpoints';

function SuggestionsGoogleView({ product, onImageClick, isImageSelected }) {
  const { data: { data: imageUrls = [] } = {}, loading } = useGet(WEB_IMAGE_RECOMMENDATIONS, {
    product_id: product.id,
    product_type: ProductType.GLOBAL,
  });

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <Row className="ml-4 font-weight-bold">Google Suggestions</Row>
      {!loading && (
        <Row noGutters className="ml-4">
          {imageUrls.map((image, index) => (
            <Col md={2} key={index} className="px-1 my-1">
              <div
                className={classNames(
                  'h-100 mt-1',
                  pointerOnHover,
                  css(
                    styles.imageDiv,
                    isImageSelected(image) ? styles.selectedImageDiv : styles.unselectedImageDiv,
                  ),
                )}
                onClick={() => onImageClick(image)}
              >
                <Image fluid src={image} className="h-100" />
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  imageDiv: {
    ':hover': {
      opacity: 0.6,
    },
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    height: 120,
    display: 'flex', // Add this
    alignItems: 'center', // Add this
    justifyContent: 'center',
  },
  unselectedImageDiv: {
    border: `1px solid ${anchovy}`,
    ':hover': {
      border: `3px solid ${anchovy}`,
    },
  },
  selectedImageDiv: {
    border: `4px solid ${lightPurple}`,
  },
});

SuggestionsGoogleView.propTypes = {
  product: PropTypes.object,
  onImageClick: PropTypes.func,
  isImageSelected: PropTypes.func,
};

export default SuggestionsGoogleView;
