import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from 'components/providers/AuthContextProvider';
import { CURRENT_USER_PING } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';
import { USER_ROLES_NOT_TO_TRACK_ACTIVITY } from 'lib/constants';

const MINUTE_IN_MS = 60 * 1000;
const PING_INTERVAL = 1 * MINUTE_IN_MS; // 1 minutes

const useInactivityLogout = () => {
  const {
    logout,
    authUser: { role },
  } = useContext(AuthContext);
  const lastActivityRef = useRef(new Date().getTime());
  const pingIntervalRef = useRef(null);

  const shouldTrackActivity = !USER_ROLES_NOT_TO_TRACK_ACTIVITY.includes(role?.name);

  const { postData: ping } = usePost(CURRENT_USER_PING, res => {
    if (!res.data.message) {
      logout();
    }
  });

  const resetTimer = useCallback(() => {
    if (!shouldTrackActivity) return;
    lastActivityRef.current = new Date().getTime();
  }, [shouldTrackActivity]);

  useEffect(() => {
    if (!shouldTrackActivity) return;

    pingIntervalRef.current = setInterval(() => {
      ping({ last_active_time: lastActivityRef?.current });
    }, PING_INTERVAL);

    const trackActivity = () => {
      resetTimer();
    };

    const events = ['mousedown', 'keydown', 'scroll', 'touchstart', 'mousemove'];

    events.forEach(event => {
      window.addEventListener(event, trackActivity);
    });

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, trackActivity);
      });

      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
    };
    // eslint-disable-next-line
  }, [lastActivityRef, shouldTrackActivity]);

  return { resetTimer };
};

// Wrapper component
const InactivityLogoutWrapper = ({ children }) => {
  const { resetTimer } = useInactivityLogout();

  return (
    <div onClick={resetTimer} onMouseMove={resetTimer}>
      {children}
    </div>
  );
};

InactivityLogoutWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export { useInactivityLogout, InactivityLogoutWrapper };
