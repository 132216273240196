// update along with app/lib/enums/export_type.py
const ExportType = {
  PRODUCT_EXPORT: 0,
  TAXONOMY_EXPORT: 1,
  ATTRIBUTE_EXPORT: 2,
  ATTRIBUTE_IMPORT_ERRORS: 3,
  PRIORITY_EXPORT: 4,
  FSA_SKU_MAPPINGS_EXPORT: 5,
  ALL_ATTRIBUTE_EXPORT: 6,
  BRAND_WITH_UPB_SCORES_EXPORT: 7,
  BRAND_DETAILS_EXPORT: 8,
  MANUFACTURER_DETAILS_EXPORT: 9,
  UPB_MATCH_PERCENTAGE_EXPORT: 10,
  SCHEMA_ATTRIBUTES_EXPORT: 11,
  PRODUCT_OUTLIER_CHANGES_EXPORT: 12,
  DATA_SOURCE_PREPROCESSED_EXPORT: 13,
  ONE_TIME_EXPORT: 14,
  BRAND_ACTIVITY_REPORT_EXPORT: 15,
  CD_THUMBNAIL_EXPORT: 16,
  VENDOR_DETAILS_EXPORT: 17,
  CONVERTING_MFR_PRODUCTS_TO_DISTRIBUTOR_PRODUCTS: 18,
};

const EXPORT_TYPE_LABELS = {
  [ExportType.PRODUCT_EXPORT]: 'Product Export',
  [ExportType.TAXONOMY_EXPORT]: 'Taxonomy Export',
  [ExportType.ATTRIBUTE_EXPORT]: 'Attribute Export',
  [ExportType.ATTRIBUTE_IMPORT_ERRORS]: 'Attribute Import Errors',
  [ExportType.PRIORITY_EXPORT]: 'Priority Export',
  [ExportType.FSA_SKU_MAPPINGS_EXPORT]: 'FSA SKU Mapping Export',
  [ExportType.ALL_ATTRIBUTE_EXPORT]: 'All Attribute Export',
  [ExportType.BRAND_WITH_UPB_SCORES_EXPORT]: 'Brand with UPB Scores Export',
  [ExportType.BRAND_DETAILS_EXPORT]: 'Brand Details Export',
  [ExportType.MANUFACTURER_DETAILS_EXPORT]: 'Manufacturer Details Export',
  [ExportType.UPB_MATCH_PERCENTAGE_EXPORT]: 'UPB Match Percentage Export',
  [ExportType.SCHEMA_ATTRIBUTES_EXPORT]: 'Schema Attribute Export',
  [ExportType.PRODUCT_OUTLIER_CHANGES_EXPORT]: 'Product Outlier Changes Export',
  [ExportType.DATA_SOURCE_PREPROCESSED_EXPORT]: 'Data Source Preprocessed Export',
  [ExportType.ONE_TIME_EXPORT]: 'One Time Export',
  [ExportType.BRAND_ACTIVITY_REPORT_EXPORT]: 'Brand Activity Report Export',
  [ExportType.CD_THUMBNAIL_EXPORT]: 'CnD Thumbnail Export',
  [ExportType.VENDOR_DETAILS_EXPORT]: 'Vendor Details Export',
  [ExportType.CONVERTING_MFR_PRODUCTS_TO_DISTRIBUTOR_PRODUCTS]:
    'Converting mfr products to distributor products',
};

export default ExportType;
export { EXPORT_TYPE_LABELS };
