export const fsaClipboardCheck = {
  prefix: 'fsa',
  iconName: 'fsaClipboardCheck',
  icon: [
    256,
    256,
    [],
    null,
    'M164.57291,256H91.42718c-20.69884,0-32.10295,0-42.36867-5.23665  c-9.07374-4.607' +
      '19-16.32603-11.86618-20.9667-20.94661c-5.23665-10.28581-5.23665-21.69662-5.23665' +
      '-42.38876V82.28643  c0-11.4376,0-17.73899,1.62724-23.83949c4.43307-16.51353,17.4' +
      '3765-29.52481,33.9378-33.95119  c3.18083-0.85045,6.40854-1.25894,10.37955-1.4531' +
      '4c0.29465-3.36164,0.97099-6.3014,2.50449-9.31481  c2.43752-4.7612,6.22773-8.5514' +
      '1,10.97554-10.98224C87.67045,0,92.79996,0,100.56789,0h54.85762  c7.76793,0,12.89' +
      '743,0,18.2814,2.74556c4.7813,2.43752,8.5849,6.25452,10.99564,11.02911  c1.52679,' +
      '2.98663,2.19646,5.913,2.49109,9.26794c3.96432,0.1942,7.19203,0.60268,10.36617,1.' +
      '45314  c16.52693,4.42638,29.53151,17.43765,33.93781,33.95119c1.64732,6.08041,1.6' +
      '4732,12.3885,1.64732,23.83949v105.14155  c0,20.69214,0,32.10295-5.22325,42.37537' +
      'c-4.67415,9.10722-11.91975,16.35281-21.00018,20.97339  C196.66248,256,185.26505,' +
      '256,164.57291,256z M68.83993,50.5183c-1.39957,0.10044-2.49779,0.25446-3.32816,0.' +
      '47545  c-7.05809,1.89511-12.62957,7.46658-14.53137,14.55146c-0.69643,2.60493-0.6' +
      '9643,7.92864-0.69643,16.74122v105.14155  c0,15.91084,0,25.51361,2.24332,29.91989' +
      'c1.95538,3.8438,5.1496,7.03133,8.97999,8.9733  c4.40629,2.25002,14.00905,2.25002' +
      ',29.9199,2.25002h73.14573c15.91086,0,25.50023,0,29.90651-2.25002  c3.89735-1.968' +
      '77,7.00453-5.07593,9.00008-8.98668c2.23663-4.3929,2.23663-13.99567,2.23663-29.90' +
      '651V82.28643  c0-8.80588,0-14.1162-0.70982-16.71443c-1.88841-7.11167-7.4599-12.6' +
      '8314-14.54477-14.58495  c-0.83037-0.22099-1.91521-0.36831-3.30807-0.47545c-0.321' +
      '43,3.19423-1.00447,6.01345-2.47769,8.90633  c-2.38396,4.72771-6.18756,8.54472-10' +
      '.98224,10.98893c-5.37059,2.73887-10.50009,2.73887-18.26802,2.73887h-54.85762  c-' +
      '7.76793,0-12.89744,0-18.27471-2.73887c-4.76121-2.43752-8.55141-6.22773-10.98224-' +
      '10.97554  C69.83771,56.53174,69.15466,53.71252,68.83993,50.5183z M96.16829,48.08' +
      '747h0.13393H96.16829z M95.99419,36.57621v4.56701  c0,1.9085-0.04688,3.4353-0.020' +
      '09,4.68754c1.01786-0.06697,2.59154-0.11384,4.59379-0.11384h54.85762  c1.88841,0,' +
      '3.34824,0.05357,4.67415,0.02009c-0.05357-1.03126-0.10715-2.60493-0.10715-4.59379' +
      'v-9.14071  c0-1.9085,0.04018-3.4353,0.0134-4.68754c-1.01787,0.06697-2.58484,0.11' +
      '384-4.5804,0.11384h-54.85762  c-1.9018,0-3.4286-0.06697-4.68755-0.02009c0.06697,' +
      '1.01787,0.11385,2.59154,0.11385,4.59379V36.57621z M116.5658,198.86557  L116.5658' +
      ',198.86557c-3.6362,0-7.12507-1.44644-9.69652-4.01788l-22.85511-22.86182c-5.35719' +
      '-5.35718-5.35719-14.03583,0-19.39302  c5.35719-5.35719,14.03584-5.35719,19.39303' +
      ',0l13.1586,13.1653l41.7392-41.73921c5.35719-5.35719,14.03584-5.35719,19.39304,0 ' +
      ' c5.35718,5.35719,5.35718,14.03584,0,19.39972l-51.43572,51.42903C123.69086,197.4' +
      '1913,120.20199,198.86557,116.5658,198.86557z',
  ],
};
