import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, FormControl, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import {
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  GLOBAL_PRODUCT_MANUFACTURER_CREATE,
} from 'lib/networking/endpoints';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import usePost from 'lib/hooks/usePost';

function CreateManufacturerProductsModal({ globalProductId, onHide, refetch }) {
  const [name, setName] = useState('');
  const [manufacturer, setManufacturer] = useState({});
  const [sku, setSku] = useState('');

  const { postData: createProducts, loading: creatingProducts } = usePost(
    GLOBAL_PRODUCT_MANUFACTURER_CREATE,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully created manufacturer product',
        title: 'Success',
      });
      onHide();
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const handleSubmit = () => {
    createProducts({
      name: name,
      manufacturer_id: manufacturer?.value,
      manufacturer_sku: sku,
      global_product_id: globalProductId,
    });
  };
  return (
    <Modal animation={false} centered show={true} onHide={onHide} size="xl">
      <Modal.Header className="py-2 my-2" closeButton>
        <h5 className="font-weight-bold">Create Manufacturer Products</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <div className="col-4">
            <Form.Group>
              <Form.Label className="font-weight-bold">Product Name</Form.Label>
              <FormControl type="text" value={name} onChange={e => setName(e.target.value)} />
            </Form.Group>
          </div>
          <div className="col-4">
            <Form.Group>
              <Form.Label className="font-weight-bold">Manufacturer</Form.Label>
              <SearchableScrollableSelect
                autoFocus={false}
                disabled={creatingProducts}
                onChange={option => setManufacturer(option)}
                fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
                optionMapper={manufacturer => ({
                  label: manufacturer.name,
                  value: manufacturer.id,
                })}
              />
            </Form.Group>
          </div>
          <div className="col-4">
            <Form.Group>
              <Form.Label>Manufacturer SKU</Form.Label>
              <FormControl type="text" value={sku} onChange={e => setSku(e.target.value)} />
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit} disabled={creatingProducts}>
          Create Manufacturer Products
        </Button>
        <Button variant="primary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CreateManufacturerProductsModal.propTypes = {
  globalProductId: PropTypes.number,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default CreateManufacturerProductsModal;
