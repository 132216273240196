import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

function L3CategoryFilter({
  selectedL3CategoryId,
  setSelectedL3CategoryId,
  setSelectedL3CategoryName,
  parentLoading,
  l3Options,
}) {
  return (
    <Form.Group>
      <Form.Label>Select L3 Category</Form.Label>
      <Select
        key={selectedL3CategoryId}
        isClearable
        isDisabled={parentLoading}
        options={l3Options}
        value={l3Options?.find(option => option?.id === selectedL3CategoryId)}
        onChange={option => {
          setSelectedL3CategoryId(option?.id);
          setSelectedL3CategoryName(option?.label);
        }}
        placeholder={parentLoading ? 'Loading...' : 'Select L3 Category'}
      />
    </Form.Group>
  );
}

L3CategoryFilter.propTypes = {
  selectedL3CategoryId: PropTypes.number,
  setSelectedL3CategoryId: PropTypes.func,
  setSelectedL3CategoryName: PropTypes.func,
  parentLoading: PropTypes.bool,
  l3Options: PropTypes.array,
};

export default L3CategoryFilter;
