import React, { useEffect, useMemo, useState } from 'react';

import { Badge, Breadcrumb, Col, Row, Spinner, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { toSafeInteger } from 'lodash';
import { useDebounce } from 'use-debounce';
import { useHistory, useParams } from 'react-router-dom';

import { backgroundDarkGrey, backgroundGrey5, backgroundGrey6, grey, white } from 'lib/css/colors';
import CatalogSidePanel from 'components/upb_viewer/CatalogSidePanel';
import Container from 'components/shared/Container';
import { DEFAULT_UPB_PAGE_SIZE } from 'lib/constants';
import {
  ANNUALIZED_GMV,
  ANNUALIZED_GMV_L1_AND_L2,
  getURL,
  GLOBAL_PRODUCTS_BY_SEARCH,
  TAXONOMY_CATEGORY_LEVEL_OPTIONS_WITH_SUB_OPTIONS,
  TOTAL_ANNUALIZED_GMV,
} from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationBar from 'components/shared/PaginationBar';
import SearchBar from 'components/shared/SearchBar';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';
import UPBAllCategoriesView from 'components/upb_viewer/UPBAllCategoriesView';
import UPBCatalogView from 'components/upb_viewer/UPBCatalogView';
import UPBCategoryView from 'components/upb_viewer/UPBCategoryView';
import useGet from 'lib/hooks/useGet';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

const DEFAULT_ALL_CATEGORY_OPTION = { label: 'All', value: null };

function getCategoryIDFromUrl(category) {
  return decodeURIComponent(category)?.split('__')[1];
}

function getCategoryNameFromUrl(category) {
  return decodeURIComponent(category)?.split('__')[0];
}

function constructUrlPathSegment(category) {
  if (!category?.value || !category?.label) return null;
  return encodeURIComponent(`${category.label}__${category.value}`);
}

function getCategoryLevel(l1_category, l2_category, l3_category, l4_category) {
  if (l4_category) return TaxonomyLevel.L4;
  else if (l3_category) return TaxonomyLevel.L3;
  else if (l2_category) return TaxonomyLevel.L2;
  else if (l1_category) return TaxonomyLevel.L1;
}

function UPBViewer() {
  const history = useHistory();
  const hasUserPermissions = useUserPermissions();

  const { l1_category, l2_category, l3_category, l4_category } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [debouncedQuery] = useDebounce(searchQuery, 600);
  const [productsToShow, setProductsToShow] = useState([]);
  const viewGMV = useMemo(
    () => hasUserPermissions([UserPermission.UPB_VIEW_ADMIN]),
    [hasUserPermissions],
  );

  const page = useMemo(() => Math.max(toSafeInteger(currentPage) - 1, 0), [currentPage]);
  const offset = useMemo(() => {
    return page * DEFAULT_UPB_PAGE_SIZE || 0;
  }, [page]);

  useEffect(() => {
    if (!selectedCategories?.length) return;
    // [0] => L1, [1] => L2, [2] => L3, [3] => L4
    const validCategories = selectedCategories
      .slice(0, 4) // Limit to first 4 categories (L1-L4)
      .map(constructUrlPathSegment)
      .filter(Boolean); // Remove null/undefined values

    if (validCategories.length > 0) {
      const newPath = `/upb-viewer/${validCategories.join('/')}`;
      history.push(newPath);
    }
    // eslint-disable-next-line
  }, [selectedCategories]);

  const onSearchQueryChange = query => {
    setSearchQuery(query);
    setProductsToShow([]);
  };

  const {
    data: { data: products = [], count = 0 } = {},
    loading,
    error,
  } = useGet(getURL(GLOBAL_PRODUCTS_BY_SEARCH, {}), {
    offset,
    limit: DEFAULT_UPB_PAGE_SIZE,
    search_query: debouncedQuery,
    l4_category_id: debouncedQuery ? null : getCategoryIDFromUrl(l4_category),
    l3_category_id: debouncedQuery ? null : getCategoryIDFromUrl(l3_category),
    l2_category_id: debouncedQuery ? null : getCategoryIDFromUrl(l2_category),
    l1_category_id: debouncedQuery ? null : getCategoryIDFromUrl(l1_category),
    count_only: l4_category || debouncedQuery ? false : true,
  });

  const { data = {}, loading: GMVLoading } = useGet(
    ANNUALIZED_GMV,
    {
      _id:
        getCategoryIDFromUrl(l4_category) ||
        getCategoryIDFromUrl(l3_category) ||
        getCategoryIDFromUrl(l2_category) ||
        getCategoryIDFromUrl(l1_category),
      category_level: getCategoryLevel(l1_category, l2_category, l3_category, l4_category),
    },
    !viewGMV || (!l3_category && !l2_category && !l1_category),
  );

  const {
    data: { data: categories } = {},
    loading: categoryLoading,
    refetch,
  } = useGet(getURL(TAXONOMY_CATEGORY_LEVEL_OPTIONS_WITH_SUB_OPTIONS, {}), {
    l3_category_id: getCategoryIDFromUrl(l3_category) || null,
    l2_category_id: getCategoryIDFromUrl(l2_category) || null,
    l1_category_id: getCategoryIDFromUrl(l1_category) || null,
    is_upb: true,
  });

  const { data: totalGMVs = {}, loading: TotalGMVsLoading } = useGet(
    TOTAL_ANNUALIZED_GMV,
    {},
    !viewGMV,
  );
  const { data: L1AndL2GMV } = useGet(ANNUALIZED_GMV_L1_AND_L2, {}, !viewGMV);

  useEffect(() => {
    if (products.length) {
      setProductsToShow(products);
      if (count < DEFAULT_UPB_PAGE_SIZE) {
        setCurrentPage(1);
      }
    }
  }, [count, debouncedQuery, products]);

  useEffect(() => {
    setProductsToShow([]);
    setSearchQuery('');
  }, [l1_category, l2_category, l3_category, l4_category]);

  const totalPages = useMemo(() => Math.ceil(count / DEFAULT_UPB_PAGE_SIZE), [count]);

  if (error) {
    return null;
  }

  const renderContent = () => {
    if (categoryLoading || (l4_category || debouncedQuery ? loading : false)) {
      return <LoadingSpinner />;
    }

    if (!l3_category && !debouncedQuery) {
      return (
        <UPBAllCategoriesView
          categories={categories}
          categoryView={l3_category ? false : true}
          refetch={refetch}
        />
      );
    }

    return (
      <div className="d-flex justify-content-center flex-column align-items-center">
        {l4_category || debouncedQuery ? (
          <>
            <UPBCatalogView products={productsToShow} productCount={count} />
            <div className="my-4">
              <PaginationBar
                totalPages={totalPages}
                currentPageNumber={page}
                onPageChange={newPage => setCurrentPage(newPage + 1)}
              />
            </div>
          </>
        ) : (
          <UPBCategoryView
            categories={categories}
            categoryCount={categories?.length || 0}
            refetch={refetch}
          />
        )}
      </div>
    );
  };

  const GMVDataLoading = l1_category ? GMVLoading : TotalGMVsLoading;
  const contractGMVValue = l1_category ? data.total_gmv : totalGMVs.total_gmv;
  const orderingGMVValue = l1_category ? data.order_gmv : totalGMVs.total_order_gmv;
  const invoiceGMVValue = l1_category ? data.invoice_gmv : totalGMVs.total_invoice_gmv;

  return (
    <Container>
      <Row className={classNames('pl-0', css(styles.titleContainer))}>
        <Col sm="1">
          <div className="d-flex align-items-center pl-2">
            <div>
              <h4 className="font-weight-bold">UPB View</h4>
            </div>
          </div>
        </Col>
        <Col sm="7" className={css(styles.headerGMV)}>
          <Row className="text-center">
            <Col sm="4">Contract GMV</Col>
            <Col sm="3">Ordering GMV</Col>
            <Col sm="3">Invoice GMV</Col>
            <Col sm="2">SKU Counts</Col>
          </Row>
          <Row className="text-center">
            <Col sm="4">
              <div className={css(styles.contractGMV)}>
                {GMVDataLoading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : contractGMVValue ? (
                  <span>{`$${Number(contractGMVValue.toFixed(2)).toLocaleString('en-US')}`}</span>
                ) : (
                  '-'
                )}
              </div>
            </Col>
            <Col sm="3">
              <div className={css(styles.contractGMVLight)}>
                {GMVDataLoading ? (
                  <Spinner animation="border" role="status" size="sm" className="ml-4" />
                ) : orderingGMVValue ? (
                  <span>{`$${Number(orderingGMVValue.toFixed(2)).toLocaleString('en-US')}`}</span>
                ) : (
                  '-'
                )}
              </div>
            </Col>
            <Col sm="3">
              <div className={css(styles.contractGMVLight)}>
                {GMVDataLoading ? (
                  <Spinner animation="border" role="status" size="sm" className="ml-4" />
                ) : invoiceGMVValue ? (
                  <span>{`$${Number(invoiceGMVValue.toFixed(2)).toLocaleString('en-US')}`}</span>
                ) : (
                  '-'
                )}
              </div>
            </Col>
            <Col sm="2">
              <div className={css(styles.contractGMVLight)}>
                {loading ? (
                  <Spinner animation="border" role="status" size="sm" className="ml-4" />
                ) : count ? (
                  <span>{Number(count.toFixed()).toLocaleString('en-US')}</span>
                ) : (
                  '-'
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm="4">
          <div className="d-flex justify-content-end flex-row align-items-center">
            <SearchBar
              value={searchQuery}
              setValue={onSearchQueryChange}
              placeholder="Search by name, gtin"
              disabled={false}
            />
          </div>
        </Col>
      </Row>
      <Row className={classNames(css(styles.catalogContainer), 'pt-2')}>
        <Col md={3} className={classNames(css(styles.sidePanel), 'px-0')}>
          <CatalogSidePanel
            selectedCategories={selectedCategories}
            setSelectedCategories={e => setSelectedCategories(e)}
            gmvData={L1AndL2GMV?.data}
            showSubOptions={true}
          />
        </Col>
        <Col md={9}>
          <Row className={css(styles.productSectionTop)}>
            <Col md={viewGMV ? 8 : 12}>
              <Breadcrumb listProps={{ className: 'bg-transparent cd-breadcrumb' }}>
                <Breadcrumb.Item href={`/upb-viewer`}>All Categories</Breadcrumb.Item>
                {!searchQuery && (
                  <>
                    {l1_category && (
                      <Breadcrumb.Item href={`/upb-viewer/${l1_category}`}>
                        {getCategoryNameFromUrl(l1_category)}
                        <Badge
                          className={classNames(css(styles.categoryBadge), 'ml-2 px-2')}
                          variant="secondary"
                        >
                          L1
                        </Badge>
                      </Breadcrumb.Item>
                    )}
                    {l2_category && (
                      <Breadcrumb.Item href={`/upb-viewer/${l1_category}/${l2_category}`}>
                        {getCategoryNameFromUrl(l2_category)}
                        <Badge
                          className={classNames(css(styles.categoryBadge), 'ml-2 px-2')}
                          variant="secondary"
                        >
                          L2
                        </Badge>
                      </Breadcrumb.Item>
                    )}
                    {l3_category && (
                      <Breadcrumb.Item
                        href={`/upb-viewer/${l1_category}/${l2_category}/${l3_category}`}
                      >
                        {getCategoryNameFromUrl(l3_category)}
                        <Badge
                          className={classNames(css(styles.categoryBadge), 'ml-2 px-2')}
                          variant="secondary"
                        >
                          L3
                        </Badge>
                      </Breadcrumb.Item>
                    )}
                    {l4_category && (
                      <Breadcrumb.Item href="#">
                        {getCategoryNameFromUrl(l4_category)}
                        <Badge
                          className={classNames(css(styles.categoryBadge), 'ml-2 px-2')}
                          variant="secondary"
                        >
                          L4
                        </Badge>
                      </Breadcrumb.Item>
                    )}
                  </>
                )}
              </Breadcrumb>
            </Col>
            {(l4_category || searchQuery) && (
              <Col md={4} className="mt-3 float-right">
                <span className="float-right justify-content-end text-muted mr-5">
                  {`${offset}-${offset + DEFAULT_UPB_PAGE_SIZE} of ${count}`} Items
                </span>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={12} className={css(styles.productSection)}>
              {renderContent()}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    top: 50,
    backgroundColor: backgroundDarkGrey,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 40px 0px 20px',
    minHeight: '110px',
  },
  catalogContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 150px)',
    background: backgroundGrey5,
  },
  sidePanel: {
    position: 'sticky',
    height: 'calc(100vh - 150px)',
    top: 0,
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 150px)',
    background: 'white',
  },
  productSection: {
    background: backgroundGrey5,
    position: 'sticky',
    top: 50,
    display: 'flex',
  },
  productSectionTop: {
    background: backgroundGrey5,
  },
  contractGMV: {
    background: grey,
    color: white,
    borderRadius: 2,
  },
  contractGMVLight: {
    background: backgroundGrey6,
    color: white,
    borderRadius: 2,
  },
  categoryBadge: {
    borderRadius: 10,
    fontSize: '0.75rem',
    fontWeight: 550,
    backgroundColor: grey,
    color: white,
  },
  headerGMV: {
    fontWeight: 800,
    fontSize: '0.9rem',
  },
});

export default UPBViewer;
export { constructUrlPathSegment, DEFAULT_ALL_CATEGORY_OPTION, getCategoryIDFromUrl };
