import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Image, Media } from 'react-bootstrap';
import ToggleSwitch from 'react-switch';

import { anchovy, grape } from 'lib/css/colors';
import BrandContentQAIcon from 'components/manage_brands/BrandContentQAIcon';
import { BrandVerificationStatus } from 'lib/enums/BrandVerificationStatus';
import EditableProperty from 'components/manage_brands/brand_details/EditableProperty';
import { faSquareCheck } from 'images/icons/fsa-monotone-svg-icons';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';

function BrandLogoSplitView({ brand, updateBrand, setShowBrandLogoModal }) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedBrand, setUpdatedBrand] = useState({ id: brand.id });
  return (
    <div className={classNames(css(styles.container), 'mt-5')}>
      <Media
        className={!brand.locked_attributes.includes('logo_url') ? pointerOnHover : null}
        onClick={() => {
          setShowBrandLogoModal(true);
        }}
      >
        <Image
          className={css(styles.square)}
          src={brand?.logo_url || placeholderProductImage}
          alt="Brand Logo"
        />
        {brand.locked_attributes.includes('logo_url') && (
          <div>
            <BrandContentQAIcon brand={brand} attribute="logo_url" />
          </div>
        )}
      </Media>
      {!brand.locked_attributes.includes('logo_url') && (
        <p className="small text-muted">* Click on image to change logo</p>
      )}
      <h3 className="font-weight-bold py-4 ">{brand.name}</h3>
      <div>
        <p className={css(styles.label)}>ID</p>
        <p className={css(styles.value)}>{brand.id}</p>
      </div>
      <div>
        <p className={css(styles.label)}>Cut and Dry ID</p>
        {isEditing ? (
          <Form onBlur={() => setIsEditing(false)} className={css(styles.row)}>
            <FontAwesomeIcon
              className={classNames(pointerOnHover, css(styles.editIcon))}
              icon={faSquareCheck}
              onMouseDown={() => {
                updateBrand(updatedBrand);
                setIsEditing(false);
              }}
              size="lg"
            />
            <EditableProperty
              label={'cnd_id'}
              brand={brand}
              setIsEditing={setIsEditing}
              setUpdatedBrand={setUpdatedBrand}
            />
          </Form>
        ) : (
          <div className={css(styles.row)}>
            <FontAwesomeIcon
              className={classNames(pointerOnHover, css(styles.editIcon))}
              icon={faPen}
              onClick={() => {
                setIsEditing(true);
              }}
            />
            <p className={css(styles.value)}>{brand.cnd_id}</p>
          </div>
        )}
      </div>
      <div className={css(styles.toggleSwitchContainer)}>
        <p className={css(styles.label)}>Verification Status</p>
        <ToggleSwitch
          onColor={grape}
          className={css(styles.value)}
          checked={brand.verification_status === BrandVerificationStatus.VERIFIED}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={() =>
            updateBrand({
              id: brand.id,
              verification_status:
                brand.verification_status === BrandVerificationStatus.VERIFIED
                  ? BrandVerificationStatus.UNVERIFIED
                  : BrandVerificationStatus.VERIFIED,
            })
          }
        />
      </div>
      {brand.verification_status === BrandVerificationStatus.VERIFIED && (
        <div className={css(styles.toggleSwitchContainer)}>
          <p className={css(styles.label)}>Verified by</p>
          <p className={css(styles.value)}>{brand.verified_by?.name || '-'}</p>
        </div>
      )}
      <div>
        <p className={css(styles.label)}>Linked Products</p>
        <p className={css(styles.value)}>{brand.product_count}</p>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: '1.9rem',
  },
  label: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  editIcon: {
    marginRight: '0.75rem',
  },
  row: {
    display: 'flex',
    marginLeft: '-1.65rem',
  },
  square: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
    border: '2px solid ' + anchovy,
    marginBottom: '1rem',
    marginRight: '0.2rem',
    padding: '0.5rem',
  },
  value: {
    display: 'block',
  },
  toggleSwitchContainer: {
    marginBottom: '1rem',
  },
});

BrandLogoSplitView.propTypes = {
  brand: PropTypes.object,
  updateBrand: PropTypes.func,
  setShowBrandLogoModal: PropTypes.func,
};

export default BrandLogoSplitView;
