import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from 'components/shared/Container';
import { DATA_SOURCE_CONNECTED_MANUFACTURERS } from 'lib/networking/endpoints';
import DataSourceProductsTab from 'components/data_sources/single_data_source_view/DataSourceProductsTab';
import { Image } from 'react-bootstrap';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';

function GDSNDataSourceManufacturersTab({ dataSource }) {
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const { data: { data: manufacturers = [], count = 0 } = {}, loading } = useGet(
    DATA_SOURCE_CONNECTED_MANUFACTURERS,
    {
      data_source_id: dataSource.id,
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
    },
  );

  if (loading && !manufacturers.length) {
    return <LoadingSpinner />;
  }

  if (!manufacturers.length) {
    return <Container>No Manufacturers found, have you run executed the Data Source?</Container>;
  }

  if (selectedManufacturer) {
    return (
      <Container>
        <div>
          <h4 className="font-weight-bold">
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'mr-2')}
              icon={faArrowCircleLeft}
              onClick={() => setSelectedManufacturer(null)}
            />
            Back to Manufacturers
          </h4>
        </div>
        <DataSourceProductsTab dataSource={dataSource} manufacturerId={selectedManufacturer.id} />
      </Container>
    );
  }

  return (
    <Container>
      <PaginationComponent
        totalItems={count}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Logo</th>
            <th>Manufacturer Name</th>
            <th>GLN</th>
            <th>Product Count</th>
            <th>Last Modified</th>
          </tr>
        </thead>
        <tbody>
          {manufacturers.map(manufacturer => (
            <tr
              key={manufacturer.id}
              onClick={() => setSelectedManufacturer(manufacturer)}
              className={pointerOnHover}
            >
              <td>
                <Image
                  src={manufacturer.logo_url || placeholderProductImage}
                  className="d-block"
                  height={40}
                />
              </td>
              <td>{manufacturer.name}</td>
              <td>{manufacturer.manufacturer_gln}</td>
              <td>{manufacturer.product_count}</td>
              <td>{manufacturer.date_modified}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

GDSNDataSourceManufacturersTab.propTypes = {
  dataSource: PropTypes.object,
};

export default GDSNDataSourceManufacturersTab;
