import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faLock, faRecycle, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Swal from 'sweetalert2';
import Tooltip from 'react-bootstrap/Tooltip';
import { useHistory } from 'react-router-dom';

import { areYouSure } from 'lib/utils/toast';
import Container from 'components/shared/Container';
import CreateProductListModal from 'components/product_lists/CreateProductListModal';
import {
  getURL,
  PRODUCT_LIST_ID,
  PRODUCT_LIST_PRODUCTS_COVERAGE_METRICS,
} from 'lib/networking/endpoints';
import { grape, salmon } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import useDelete from 'lib/hooks/useDelete';
import usePost from 'lib/hooks/usePost';
import { httpPut } from 'lib/networking/http';

function ProductListsView({ productLists, refetch }) {
  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const history = useHistory();

  const { postData: reCalculate, loading: calculating } = usePost(
    PRODUCT_LIST_PRODUCTS_COVERAGE_METRICS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Please check after few minutes',
        title: 'Success',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred while re calculating: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  const { deleteData: deleteProductList } = useDelete(
    PRODUCT_LIST_ID,
    false,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Deleted successfully',
        title: 'Success',
        position: 'center',
      });
      refetch();
    },
    () => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred',
      });
    },
  );

  const updateProductList = useCallback(
    (product_list_id, is_user_favorite) => {
      const updatedUrl = getURL(PRODUCT_LIST_ID, { product_list_id });
      httpPut(updatedUrl, { is_user_favorite }).then(() => {
        refetch && refetch();
      });
    },
    [refetch],
  );

  const reCalculateToolTip = props => (
    <Tooltip {...props}>Get Real Time Metrics For This Product List</Tooltip>
  );

  if (calculating) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>No. of Products</th>
            <th>UPB Match %</th>
            <th>Classification %</th>
            <th>Images %</th>
            <th>Created By</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {productLists?.length ? (
            productLists.map(prodList => (
              <tr
                key={prodList.id}
                className={pointerOnHover}
                onClick={() => {
                  history.push('/product-lists/' + prodList.id);
                }}
              >
                <td>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={reCalculateToolTip}
                  >
                    <FontAwesomeIcon
                      icon={faRecycle}
                      className="mx-2"
                      onClick={e => {
                        e.stopPropagation();
                        reCalculate({ product_list_id: prodList.id, re_calculate: true });
                      }}
                    />
                  </OverlayTrigger>
                  {prodList.name}
                  {prodList.is_private ? <FontAwesomeIcon icon={faLock} className="ml-2" /> : null}
                </td>
                <td>{prodList.products_count.toLocaleString()}</td>
                <td>
                  {prodList.products_count !== 0
                    ? (prodList.upb_matched_count / prodList.products_count).toLocaleString(
                        undefined,
                        { style: 'percent' },
                      )
                    : 'N/A'}
                </td>
                <td>
                  {prodList.products_count !== 0
                    ? (prodList.classified_count / prodList.products_count).toLocaleString(
                        undefined,
                        { style: 'percent' },
                      )
                    : 'N/A'}
                </td>
                <td>
                  {prodList.products_count !== 0
                    ? (
                        prodList.has_propagated_image_count / prodList.products_count
                      ).toLocaleString(undefined, { style: 'percent' })
                    : 'N/A'}
                </td>
                <td>{prodList.created_by.name}</td>
                <td>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {prodList.is_user_favorite ? 'Remove from favorites' : 'Add to favorites'}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={prodList.is_user_favorite ? faStar : faStarRegular}
                      className={classNames(
                        'mr-3',
                        prodList.is_user_favorite ? css(styles.favoriteStar) : '',
                      )}
                      onClick={e => {
                        e.stopPropagation();
                        updateProductList(prodList.id, !prodList.is_user_favorite);
                      }}
                    />
                  </OverlayTrigger>
                  <Button
                    variant="link"
                    key={prodList.id}
                    className={classNames(css(styles.button), pointerOnHover)}
                    onClick={e => {
                      e.stopPropagation();
                      history.push('/product-lists/qa-product-list/' + prodList.id);
                    }}
                  >
                    QA Catalog
                  </Button>
                  <Button
                    variant="link"
                    className={classNames(css(styles.button), pointerOnHover)}
                    onClick={e => {
                      e.stopPropagation();
                      history.push('/image-tagger/' + prodList.id);
                    }}
                  >
                    Tag Images
                  </Button>
                  <OverlayTrigger overlay={<Tooltip>Delete product list</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className={classNames('ml-3', css(styles.deleteButton))}
                      onClick={e => {
                        e.stopPropagation();
                        areYouSure(
                          () => deleteProductList({ product_list_id: prodList.id }),
                          'Are you sure you want to delete this product list?',
                        );
                      }}
                    />
                  </OverlayTrigger>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No product lists found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <CreateProductListModal
        show={showCreateListModal}
        onHide={() => setShowCreateListModal(false)}
        refetch={refetch}
      />
    </Container>
  );
}

const styles = StyleSheet.create({
  button: {
    color: grape,
  },
  favoriteStar: {
    color: 'gold',
  },
  deleteButton: {
    color: salmon,
  },
});

ProductListsView.propTypes = {
  productLists: PropTypes.array,
  refetch: PropTypes.func,
};

export default ProductListsView;
