import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';
import { useHistory, useParams } from 'react-router-dom';

import ActionDropDown from 'components/shared/ActionDropDown';
import { areYouSure } from 'lib/utils/toast';
import AssignThumbnailsModal from 'components/product_lists/product_list/assign_thumbnails/AssignThumbnailsModal';
import {
  AUTO_CLASSIFICATION_PRODUCT_SEND_DATA,
  ATTRIBUTION_COMPLIANCE_CSV_EXPORT,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS,
  EXPORT_DATA_CUT_DRY,
  getURL,
  PRODUCT_IMAGE_TAGGING_PREDICTIONS,
  PRODUCT_LIST_ID,
  PRODUCT_LIST_REFRESH_CACHE,
  PRODUCT_LIST_STATUS_UPDATE_ON_PRODUCTS,
  PRODUCT_LIST_UPB_MATCH_CSV_EXPORT,
  PRODUCT_LIST_ATTRIBUTION_EXPORT,
  PRODUCT_OUTLIERS_SIMILARITY_SCORES,
  PRODUCT_LIST_CALCULATE_CD_PRODUCT_SCORE,
  PRODUCT_LINKS_SUGGESTIONS_SEND_DATA_TO_ML_SERVER,
} from 'lib/networking/endpoints';
import ClearableInput from 'components/shared/ClearableInput';
import CDExportType from 'lib/enums/CDExportType';
import ImageCleanModal from 'components/product_lists/product_list/image_cleanup/ImageCleanModal';
import ImageSourceModal from 'components/product_lists/product_list/ImageSourceModal';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { grape } from 'lib/css/colors';
import PaginationComponent from 'components/shared/PaginationComponent';
import ProductListCatalogView from 'components/product_lists/product_list/ProductListCatalogView';
import ProductListTableView from 'components/product_lists/product_list/ProductListTableView';
import ProductOutlierThresholdModal from 'components/product_lists/product_list/product_outlier_suggestions/ProductOutlierThresholdModal';
import { ProductActiveStatus } from 'lib/enums/ProductActiveStatus';
import P0SyncModal from 'components/product_lists/product_list/P0SyncModal';
import { salmon } from 'lib/css/colors';
import useDelete from 'lib/hooks/useDelete';
import usePost from 'lib/hooks/usePost';

function ProductListProductsTable({
  products,
  productCount,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  showFilters,
  setShowFilters,
  showCatalogView,
  setShowCatalogView,
  skuQuery,
  setSkuQuery,
  loading,
  loadingCount,
  type,
}) {
  const history = useHistory();
  const { productListId } = useParams();
  const [showProductOutliersModal, setShowProductOutliersModal] = useState(false);
  const [showImageCleanModal, setShowImageCleanModal] = useState(false);
  const [showP0SyncModal, setShowP0SyncModal] = useState(false);
  const [showThumbnailAssignModal, setShowThumbnailAssignModal] = useState(false);
  const [showImageSourceModal, setShowImageSourceModal] = useState(false);

  const { postData: exportComplianceCSV } = usePost(
    ATTRIBUTION_COMPLIANCE_CSV_EXPORT,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to export the file',
      }),
  );

  const { deleteData: deleteProductList } = useDelete(
    PRODUCT_LIST_ID,
    false,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Deleted successfully',
        title: 'Success',
        position: 'center',
      });
      history.push('/product-lists');
    },
    () => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred',
      });
    },
  );

  const { postData: exportCategorizedToCutDry, loading: categorizedExporting } = usePost(
    EXPORT_DATA_CUT_DRY,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Categorized products export to Cut+Dry started',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while exporting: ${error?.response?.data?.message}`,
      }),
  );

  const { postData: statusUpdate } = usePost(
    PRODUCT_LIST_STATUS_UPDATE_ON_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Status Update Started',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while updating: ${error?.response?.data?.message}`,
      }),
  );

  const { postData: refreshCache } = usePost(
    getURL(PRODUCT_LIST_REFRESH_CACHE, { product_list_id: productListId }),
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Status Update Started',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while updating: ${error?.response?.data?.message}`,
      }),
  );

  const { postData: calculateCnDScore } = usePost(
    getURL(PRODUCT_LIST_CALCULATE_CD_PRODUCT_SCORE, { product_list_id: productListId }),
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'CnD product score calculation started',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while calculating: ${error?.response?.data?.message}`,
      }),
  );

  const { postData: exportUPBMatchCSV } = usePost(
    PRODUCT_LIST_UPB_MATCH_CSV_EXPORT,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to export the file',
      }),
  );

  const { postData: exportAttributesCsv } = usePost(
    getURL(PRODUCT_LIST_ATTRIBUTION_EXPORT, { product_list_id: productListId }),
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while.
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to export the file',
      }),
  );

  const { postData: imageTaggingPredictions } = usePost(
    PRODUCT_IMAGE_TAGGING_PREDICTIONS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Started sending data to find image tagging predictions.',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while sending data: ${error?.response?.data?.message}`,
      }),
  );

  const { postData: duplicateGlobalProductSuggetions } = usePost(
    DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Started sending data to find duplicate global product suggestions.',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while sending data: ${error?.response?.data?.message}`,
      }),
  );

  const { postData: productCategorySuggetions } = usePost(
    AUTO_CLASSIFICATION_PRODUCT_SEND_DATA,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Started sending data to find product category suggestions.',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while sending data: ${error?.response?.data?.message}`,
      }),
  );

  const { postData: findProductSimilarityScores } = usePost(
    PRODUCT_OUTLIERS_SIMILARITY_SCORES,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Started sending data to find product similarity scores.',
      });
    },
  );

  const { postData: getProductLinkSuggestions } = usePost(
    PRODUCT_LINKS_SUGGESTIONS_SEND_DATA_TO_ML_SERVER,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Started sending data to get product link suggestions.',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while sending product data: ${error?.response?.data?.message}`,
      }),
  );

  const showFiltersTooltip = props => (
    <Tooltip {...props}>{showFilters ? 'Hide' : 'Show'} Filters</Tooltip>
  );

  return (
    <div>
      <div className="my-3">
        <div>
          <Row className="mb-3">
            <Col xs={3}>
              <ClearableInput
                type="text"
                placeholder="Search by SKU"
                value={skuQuery}
                onChange={e => setSkuQuery(e.target.value)}
                onClear={() => setSkuQuery('')}
                autoFocus
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                variant="link"
                className={classNames(css(styles.deleteButton), 'mb-2 mr-4')}
                onClick={() => {
                  areYouSure(
                    () => deleteProductList({ product_list_id: productListId }),
                    'Are you sure you want to delete this product list?',
                  );
                }}
              >
                Delete
              </Button>
              <div className="mr-4">
                <ActionDropDown
                  submenuItems={[
                    {
                      title: 'Clean Images',
                      action: () => setShowImageCleanModal(true),
                    },
                    {
                      title: 'Assign Thumbnails to products',
                      action: () => setShowThumbnailAssignModal(true),
                    },
                    {
                      title: 'Export Compliance Report',
                      action: () => exportComplianceCSV({ product_list_id: productListId }),
                    },
                    {
                      title: 'Sync P0 With C+D',
                      action: () => setShowP0SyncModal(true),
                    },
                    {
                      title: 'Export UPB Match Report',
                      action: () => exportUPBMatchCSV({ product_list_id: productListId }),
                    },
                    {
                      title: 'Export Product Classification to C+D',
                      action: () =>
                        areYouSure(
                          () =>
                            exportCategorizedToCutDry({
                              export_type: CDExportType.CATEGORIES,
                              full_export: true,
                              product_list_id: productListId,
                            }),
                          'Are you sure you want to sync product classification to Cut+Dry?',
                        ),
                    },
                    {
                      title: 'Export Selected Attributes For Product List',
                      action: () =>
                        history.push(`/product-lists/${productListId}/product-list-export`),
                    },
                    {
                      title: 'Export Image Source Data For Product List',
                      action: () => setShowImageSourceModal(true),
                    },
                    {
                      title: 'Snowflake Export For Product List',
                      action: () => exportAttributesCsv({ product_list_id: productListId }),
                    },
                    {
                      title: 'Mark products as Archived',
                      action: () =>
                        statusUpdate({
                          product_list_id: productListId,
                          status: ProductActiveStatus.Archived,
                        }),
                    },
                    {
                      title: 'Reset the cache',
                      action: () => refreshCache(),
                    },
                    {
                      title: 'Calculate CnD score for products',
                      action: () => calculateCnDScore({ product_list_id: productListId }),
                    },
                  ]}
                />
              </div>
              <ActionDropDown
                submenuItems={[
                  {
                    title: 'Send data to ML server to find Outliers',
                    action: () => setShowProductOutliersModal(true),
                  },
                  {
                    title: 'Send data to ML server to find image tagging predictions',
                    action: () => imageTaggingPredictions({ product_list_id: productListId }),
                  },
                  {
                    title: 'Send data to ML server to find similar products to link',
                    action: () =>
                      duplicateGlobalProductSuggetions({ product_list_id: productListId }),
                  },
                  {
                    title: 'Send data to ML server to find product categories',
                    action: () => productCategorySuggetions({ product_list_id: productListId }),
                  },
                  {
                    title: 'Send data to ML server to find product similarity scores',
                    action: () => findProductSimilarityScores({ product_list_id: productListId }),
                  },
                  {
                    title: 'Send data to ML server to get product link suggestions',
                    action: () =>
                      getProductLinkSuggestions({
                        product_list_id: productListId,
                        catalog_type: type,
                      }),
                  },
                ]}
                label="ML Actions"
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex align-items-center justify-content-start">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={showFiltersTooltip}
              >
                <Button variant="link" onClick={() => setShowFilters(!showFilters)} className="p-0">
                  <FontAwesomeIcon color={grape} size="lg" icon={faFilter} className="mx-2" />
                </Button>
              </OverlayTrigger>
              <div className="d-flex align-items-center mx-4">
                <span className="text-secondary">Catalog View</span>
                <ToggleSwitch
                  className="ml-2"
                  onColor={grape}
                  checked={showCatalogView}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={e => setShowCatalogView(e)}
                />
              </div>
            </Col>
            <Col xs={5} className="d-flex align-items-center justify-content-end">
              <div>
                {loadingCount ? (
                  <Spinner animation="border" role="status" size="sm" className="mr-4" />
                ) : (
                  `${productCount} results found`
                )}
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="d-flex align-items-center">
                {loadingCount ? (
                  <Spinner animation="border" role="status" size="sm" className="mr-4" />
                ) : (
                  <PaginationComponent
                    totalItems={productCount}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    defaultSize
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {loading || categorizedExporting ? (
        <LoadingSpinner />
      ) : showCatalogView ? (
        <ProductListCatalogView products={products} />
      ) : (
        <ProductListTableView products={products} />
      )}
      {showProductOutliersModal ? (
        <ProductOutlierThresholdModal
          productListId={productListId}
          onHide={() => setShowProductOutliersModal(false)}
        />
      ) : null}
      {showImageCleanModal ? (
        <ImageCleanModal
          productListId={productListId}
          onHide={() => setShowImageCleanModal(false)}
        />
      ) : null}
      {showImageSourceModal ? (
        <ImageSourceModal
          productListId={productListId}
          onHide={() => setShowImageSourceModal(false)}
        />
      ) : null}
      {showP0SyncModal ? <P0SyncModal onHide={() => setShowP0SyncModal(false)} /> : null}
      {showThumbnailAssignModal ? (
        <AssignThumbnailsModal
          productListId={productListId}
          onHide={() => setShowThumbnailAssignModal(false)}
        />
      ) : null}
    </div>
  );
}

const styles = StyleSheet.create({
  deleteButton: {
    color: salmon,
  },
});

ProductListProductsTable.propTypes = {
  products: PropTypes.array,
  productCount: PropTypes.number,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func,
  showCatalogView: PropTypes.bool,
  setShowCatalogView: PropTypes.func,
  skuQuery: PropTypes.string,
  setSkuQuery: PropTypes.func,
  loading: PropTypes.bool,
  loadingCount: PropTypes.bool,
  type: PropTypes.string,
};

export default ProductListProductsTable;
