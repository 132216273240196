import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Route, Switch, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import Cookies from 'lib/enums/Cookies';
import { CURRENT_USER, LOGIN, LOGOUT, CURRENT_USER_PING } from 'lib/networking/endpoints';
import { GOOGLE_REDIRECT_URL } from 'lib/constants';
import { HomeUrls } from 'lib/enums/HomeUrls';
import { httpGet, httpPost } from 'lib/networking/http';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import Login from 'components/login/Login';
import RedirectToLogin from 'components/login/RedirectToLogin';
import useQueryString from 'lib/hooks/useQueryString';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import usePost from 'lib/hooks/usePost';
import { USER_ROLES_NOT_TO_TRACK_ACTIVITY } from 'lib/constants';

const AuthContext = React.createContext({});

function AuthContextProvider({ children }) {
  const [homeUrl, setHomeUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [authUser, setAuthUser] = useLocalStorage(Cookies.AUTH_USER, null);

  const history = useHistory();

  const redirectLink = useQueryString().get('redirect_on_login');

  const { postData: ping } = usePost(CURRENT_USER_PING, res => {
    if (!res.data.message) {
      logout();
    }
  });

  useEffect(() => {
    setLoading(true);
    httpGet(CURRENT_USER)
      .then(res => {
        const user = res?.data?.user;
        if (user) {
          const roleOption = HomeUrls.find(op => op.value === user.role.name);
          setAuthUser(user);
          setHomeUrl(roleOption?.label);
        }
      })
      .finally(() => {
        setLoading(false);
        setUserLoaded(true);
      });
    // send the ping request when the user loads the page for the initial time
    if (!USER_ROLES_NOT_TO_TRACK_ACTIVITY.includes(authUser?.role?.name)) {
      ping({ last_active_time: new Date().getTime() });
    }
    // eslint-disable-next-line
  }, []);

  const login = credentialResponse => {
    const authCode = credentialResponse?.credential;
    if (!authCode) {
      return;
    }
    httpPost(LOGIN, { authCode, redirectUrl: GOOGLE_REDIRECT_URL })
      .then(res => {
        const roleOption = HomeUrls.find(op => op.value === res?.data?.user.role.name);
        setAuthUser(res?.data?.user);
        setHomeUrl(roleOption?.label);
        history.push(redirectLink || roleOption?.label || '/');
      })
      .catch(() =>
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: 'Failed to login.Please contact the administrator.',
        }).then(() => (window.location.href = GOOGLE_REDIRECT_URL)),
      );
  };

  const logout = () => {
    httpGet(LOGOUT)
      .then(res => {
        if (res) {
          setAuthUser(null);
          history.push('/login');
        }
      })
      .catch(err => {
        if (err?.response?.data) {
          Swal.fire('Failed to logout', err.response.data.message, 'error');
        } else {
          Swal.fire('Something went wrong. Please try again.', err.message, 'error');
        }
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!userLoaded) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ authUser, homeUrl, login, logout }}>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        {!authUser ? <RedirectToLogin /> : null}
        {children}
      </Switch>
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default AuthContextProvider;
export { AuthContext };
