import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import AttributeSection from 'components/upb_viewer/pdp_components/AttributeSection';
import AttributesGroupedTagViewWithIcon from 'components/upb_viewer/pdp_components/AttributesGroupedTagViewWithIcon';
import AttributesTagView from 'components/upb_viewer/pdp_components/AttributesTagView';
import AttributeSubsection from 'components/upb_viewer/pdp_components/AttributeSubsection';
import ChildNutritionSection from 'components/upb_viewer/pdp_components/ChildNutritionSection';

import ProductNutritionFactsView from 'components/upb_viewer/pdp_components/ProductNutritionFactsView';
import { sectionTextClass } from 'components/upb_viewer/css/sharedStyles';

const NutritionSection = ({
  ingredients,
  allergens,
  claims,
  nutritionFacts,
  childNutritionFacts,
}) => {
  if (
    !ingredients &&
    (!allergens || !allergens.length) &&
    (!claims || !claims.length) &&
    (!nutritionFacts || !nutritionFacts.length) &&
    (!childNutritionFacts || !childNutritionFacts.length)
  ) {
    return null;
  }

  return (
    <AttributeSection title="Nutrition">
      {ingredients && (
        <AttributeSubsection title="Ingredients">
          <div className={classNames(sectionTextClass, 'text-capitalize')}>
            {ingredients.toLowerCase()}
          </div>
        </AttributeSubsection>
      )}
      {allergens && allergens.length > 0 && (
        <AttributeSubsection title="Allergens">
          <div className={sectionTextClass}>
            <AttributesGroupedTagViewWithIcon attributes={allergens} />
          </div>
        </AttributeSubsection>
      )}
      {claims && claims.length > 0 && (
        <AttributeSubsection title="Claims">
          <div className={sectionTextClass}>
            <AttributesTagView tags={claims} />
          </div>
        </AttributeSubsection>
      )}
      {nutritionFacts && nutritionFacts.length > 0 && (
        <AttributeSubsection title="Nutrition Facts">
          <div className={css(styles.nutritionFacts)}>
            <ProductNutritionFactsView nutritionAttributes={nutritionFacts} />
          </div>
        </AttributeSubsection>
      )}
      {childNutritionFacts && childNutritionFacts.length > 0 && (
        <AttributeSubsection title="Child Nutrition Facts">
          <ChildNutritionSection childNutritions={childNutritionFacts} />
        </AttributeSubsection>
      )}
    </AttributeSection>
  );
};

NutritionSection.propTypes = {
  ingredients: PropTypes.string,
  allergens: PropTypes.array,
  claims: PropTypes.arrayOf(PropTypes.string),
  nutritionFacts: PropTypes.array,
  childNutritionFacts: PropTypes.array,
};

const styles = StyleSheet.create({
  nutritionFacts: {
    maxWidth: 400,
  },
});

export default NutritionSection;
