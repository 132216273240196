import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as allergenIcons from '@awesome.me/kit-ff5c0e126f/icons/modules/kit/custom';
import { displayJsonSafeText } from 'lib/utils/displayJsonSafeText';
import {
  sectionTextBlackClass,
  sectionTextClass,
  tagContainerClass,
} from 'components/upb_viewer/css/sharedStyles';

function AttributesGroupedTagViewWithIcon({ attributes }) {
  if (!attributes.length) {
    return null;
  }

  const sortedAttributes = attributes.map(attribute => ({
    ...attribute,
    values: [...attribute.values].sort((a, b) => a.length - b.length),
  }));

  return (
    <div>
      {sortedAttributes.map((attribute, idx) => (
        <Row key={idx} className="my-2">
          <Col sm={3} className={classNames(sectionTextClass, 'd-flex justify-content-start pl-3')}>
            {attribute.displayName}
          </Col>
          <Col className="d-flex flex-wrap">
            {attribute.values.map((tag, tagIdx) => (
              <div
                key={tagIdx}
                className={classNames(
                  sectionTextBlackClass,
                  tagContainerClass,
                  'd-flex py-1 px-2 mx-1 mb-1',
                )}
              >
                <Row className={classNames(css(styles.tagRow), 'd-flex flex-nowrap mx-0')}>
                  {tag.icon && allergenIcons[tag.icon] && (
                    <div>
                      <FontAwesomeIcon icon={allergenIcons[tag.icon]} height={14} width={14} />
                    </div>
                  )}
                  <div>{displayJsonSafeText(tag.value)}</div>
                </Row>
              </div>
            ))}
          </Col>
        </Row>
      ))}
    </div>
  );
}

AttributesGroupedTagViewWithIcon.propTypes = {
  attributes: PropTypes.array,
};

const styles = StyleSheet.create({
  tagsContainer: {
    gap: 10,
  },
  tagRow: {
    gap: 8,
  },
});

export default AttributesGroupedTagViewWithIcon;
