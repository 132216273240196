import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import BrandContentQAModal from 'components/manage_brands/BrandContentQAModal';

function BrandContentQAViewTableRow({ brand, refetch }) {
  const [showContentQAModal, setShowContentQAModal] = useState(false);
  return (
    <>
      <tr onClick={() => setShowContentQAModal(true)}>
        <td>{brand?.name}</td>
        <td>{brand?.global_manufacturer?.name || '-'}</td>
        <td>{brand?.brand_url}</td>
        <td>{brand?.product_count}</td>
        <td>
          <Button
            variant="link"
            key={brand?.id}
            onClick={() => window.open(`/enrich-products/brand-details/${brand.id}`, '_blank')}
          >
            View
          </Button>
        </td>
      </tr>
      {showContentQAModal ? (
        <BrandContentQAModal
          brand={brand}
          onHide={() => {
            setShowContentQAModal(false);
          }}
          refetch={refetch}
        />
      ) : null}
    </>
  );
}

BrandContentQAViewTableRow.propTypes = {
  brand: PropTypes.object,
  refetch: PropTypes.func,
};

export default BrandContentQAViewTableRow;
