import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import AppContainer from 'components/layout/AppContainer';
import AuthContextProvider from 'components/providers/AuthContextProvider';
import ConfigContextProvider from 'components/providers/ConfigContextProvider';
import { InactivityLogoutWrapper } from 'lib/hooks/InactivityLogoutWrapper.js';
import { isLocalhost, isSandboxServer, isStagingServer } from 'lib/networking/endpoints';
import { MixpanelProvider } from 'lib/hooks/useMixpanel';

const CLIENT_ID =
  isLocalhost || isStagingServer || isSandboxServer
    ? '229063425575-0qp6r8grtc9cbvthgn9tdepv7em9qgrl.apps.googleusercontent.com'
    : '273785638871-m659fe5noa4qomuubt7i5aheg4fl5nc7.apps.googleusercontent.com';

function App() {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Router>
        <Switch>
          <Route>
            <AuthContextProvider>
              <InactivityLogoutWrapper>
                <ConfigContextProvider>
                  <MixpanelProvider>
                    <AppContainer />
                  </MixpanelProvider>
                </ConfigContextProvider>
              </InactivityLogoutWrapper>
            </AuthContextProvider>
          </Route>
        </Switch>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
